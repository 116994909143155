
import React, { memo } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";

import buttonClose from "../../../assets/icons/fechar.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import Warning from "../../../assets/kinologyIcons/svg/warning.svg";

import { 
	Close, 
	Container, 
	Phrase, 
	TextArea, 
	Title,
	Content,
	WarningIcon
} from "./styles";
import { TypesLanguage } from "../../../utils/types";

const date = {
	pt: '10 de outubro',
	en: 'October 10th',
	es: '10 de octubre',
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAvisoAnamnese: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const is_open_warning_anamnese = useSelector(
		(state: any) => state.app.is_open_warning_anamnese
	);
	
	const language = useSelector((state: any) => state.configs.language);

	const ModalAvisoAnamneseClose = () => {
		dispatch(AppActions.set_modal_warning_anamnese(false))
	};
	
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_warning_anamnese}
				onClose={ModalAvisoAnamneseClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_warning_anamnese}>
					<Container>
						<Close
							src={buttonClose}
							onClick={()=>  
								dispatch(AppActions.set_modal_warning_anamnese(false))
							}
							alt="Close"
						/>
						<Content>
						<WarningIcon
							src={Warning}
							alt="warning"
						/>
							<Title>
								{current_language?.anamnese_aviso}
							</Title>

							<TextArea>
								<Phrase>
									{current_language?.anamnese_aviso_explicacao_pt1} 
									<strong style={{ color: 'black' }}>{language === TypesLanguage.PT && date.pt || language === TypesLanguage.EN && date.en || date.es}</strong> 
									{current_language?.anamnese_aviso_explicacao_pt2}
								</Phrase>
								<br/>
								<Phrase>
								{current_language?.anamnese_aviso_explicacao_pt3}
								</Phrase>
							</TextArea>
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalAvisoAnamnese);
