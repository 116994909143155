import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { AppActions } from "../../../store/ducks/app";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { TypesLanguage, TypesProLivre } from "../../../utils/types";
import { Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import Lupa from "../../../assets/icons/lupa.svg";
import Limpar from "../../../assets/icons/limpar.svg";
import LupaFilterProtocol from "../../../assets/icons/lupaFilterProtocol.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { loadStorage } from "../../../utils/base_async_storage";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { exercicesWithNoTutorial } from "../../../database/exercicicesNoTutorial"
import Loading from "../../Dashboard/Loading";

const ExercicioSup: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const [Evaluatedpatients, setEvaluatedPatients] = useState<number[]>([])

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const patients_most_accessed = useSelector(
		(state: any) => state.dashboard.pacientes_mais_visitados.LISTA
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const pacientes_loading = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);

	const [current_patient, set_current_patient]: any = useState(null);

	async function getEvaluatedPatients() {
		let evaluatedPatients: number[] | null = []

		evaluatedPatients = await loadStorage('evaluated_patients')

		if(evaluatedPatients != undefined || evaluatedPatients != null ){
			setEvaluatedPatients(evaluatedPatients)
		};
	};

	useEffect(()=> {
		getEvaluatedPatients()
	},[patient])

	function verifyCountEvaluatedPatients (exer: Exercicios2Props){
		
		if( 
			Evaluatedpatients.length < config_servicos?.SERVICO_PACIENTES_AVALIADOS
			||
			Evaluatedpatients.includes(patient.ID_USUARIO)
		){
			handleProtocoloList(exer)
		}else{
			dispatch(AppActions.set_modal_upgrade(true, true));
		}
	};

	function GetPatientInOrigin() {
		const patient_is_on_the_main_list = pacientes.filter(
			(item: PacientesProps) => item?.ID_USUARIO == patient?.ID_USUARIO
		);
		let patient_is_on_the_most_accessed_list = undefined;
		if(patients_most_accessed){
			patient_is_on_the_most_accessed_list = patients_most_accessed.filter(
				(item: PacientesProps) => item?.ID_USUARIO == patient?.ID_USUARIO
			);
		}
		return patient_is_on_the_main_list[0] != undefined
			? patient_is_on_the_main_list[0]
			: patient_is_on_the_most_accessed_list[0];
	}

	useEffect(() => {
		set_current_patient(GetPatientInOrigin);
	}, [pacientes_loading, pacientes, patients_most_accessed, current_patient]);

	const handleProtocoloList = (exercicio: Exercicios2Props) => {
		dispatch(ExerciciosActions.set_id_proto(exercicio.ID_EXERCICIO));
		dispatch(AppActions.set_selected_type_pro_livre(TypesProLivre.LIVRE));
		dispatch(AppActions.set_exercicio(exercicio));
	
		if(exercicio.EXERCICIO_TITULO_PT === 'Preensão Palmar'){
			if(!current_patient.USUARIO_LADO_DOMINANTE) dispatch(AppActions.set_modal_select_dominant_side(true));
			if(!!current_patient.USUARIO_LADO_DOMINANTE) {
				if (exercicio.EXERCICIO_TIPO === 1) {
					dispatch(AppActions.set_lado_exercicio("Sem Definicao"));
					dispatch(AppActions.set_modal_select_avaliacao(true));
				} else {
					dispatch(AppActions.set_modal_modo_exercicio(true));
				}
			}
		}
		if(exercicio.EXERCICIO_TITULO_PT !== 'Preensão Palmar') {
			if (exercicio.EXERCICIO_TIPO === 1) {
				dispatch(AppActions.set_lado_exercicio("Sem Definicao"));
				dispatch(AppActions.set_modal_select_avaliacao(true));
			} else {
				dispatch(AppActions.set_modal_modo_exercicio(true));
			}
		}
		AmplitudeLog("escolha_exercicio", {
			body: exercicio,
		});
	};

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);

	const exerciciosCurrent: Exercicios2Props[] = [];

	const exerciciosElastic = exercicios.filter((e: any) => e.ID_USUARIO == 0);
	exerciciosCurrent.push(...exerciciosElastic);

	const exerciciosCriados = exercicios.filter((e: any) => e.ID_USUARIO != 0);
	exerciciosCurrent.push(...exerciciosCriados.reverse());
	
	const tutorial = (e: any) => {
		dispatch(AppActions.set_exercicio_tutorial(e));
		dispatch(AppActions.set_modal_tutorial(true));
	};

	const [inputValueExercise, setInputValueExercise] = useState("");
	const [exercicios_pesquisa, setExerciciosPesquisa] = useState<
		Exercicios2Props[]
	>([]);
	const [inputValueProtocol, setInputValueProtocol] = useState("");

	const resetInput = (type: string) => {
		type == "protocol" ? setInputValueProtocol("") : setInputValueExercise("");
	};

	const handleInputChangeExercise = (event: any) => {
		setInputValueExercise(event.target.value);
		if (inputValueExercise != "") {
			const matchedExercises = exerciciosCurrent.filter(
				(exercicio: Exercicios2Props) => {
					return (
						exercicio.EXERCICIO_TITULO_PT.toLowerCase().indexOf(
							event.target.value.toLowerCase()
						) !== -1
					);
				}
			);
			setExerciciosPesquisa(matchedExercises);
		}
	};

	
	function _renderExerciseList(exers: Exercicios2Props[]) {
		return exers.map((item) => (
			<div className="ficha" key={item.ID_EXERCICIO}>
				<div className="a">
					<p className="p">
						{language === TypesLanguage.PT
							? item.EXERCICIO_TITULO_PT
							: language === TypesLanguage.EN
							? item.EXERCICIO_TITULO_EN
							: item.EXERCICIO_TITULO_ES}
					</p>
				</div>
				<div className="b">
					{item.ID_USUARIO === 0 && !exercicesWithNoTutorial.includes(item.EXERCICIO_TITULO_PT) &&(
						<button
							className="botao"
							onClick={() => tutorial(item.EXERCICIO_TITULO_PT)}
						>
							<p style={{ marginRight: 12 }}>{current_language?.tutorial}</p>
							<svg
								width="28"
								height="23"
								viewBox="0 0 28 23"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M24.9087 0H2.49087C1.10844 0 0 1.13722 0 2.55556V17.8889C0 19.2944 1.10844 20.4444 2.49087 20.4444H8.71805V23H18.6815V20.4444H24.9087C26.2787 20.4444 27.3871 19.2944 27.3871 17.8889L27.3996 2.55556C27.3996 1.13722 26.2787 0 24.9087 0ZM24.9087 17.8889H2.49087V2.55556H24.9087V17.8889Z"
									fill="#001574"
								/>
								<path
									d="M8.11841 14.3737C8.11841 14.3737 8.7948 7.49924 10.824 5L14.2059 5.61106L13.5295 7.49924H12.1767V11.8744H12.8531C14.2059 9.99848 17.0062 9.31409 18.6971 9.99848C20.9292 10.9334 20.7263 13.7504 18.6971 14.997C17.0738 15.9991 12.1767 16.8729 8.11841 14.3737Z"
									fill="#001574"
								/>
							</svg>
						</button>
					)}
					<button
						className="botao"
						id={item.EXERCICIO_TITULO_PT}
						onClick={() => verifyCountEvaluatedPatients(item)}
					>
						<svg
							width="25"
							height="26"
							viewBox="0 0 25 26"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M23.5864 11.2932L2.9464 0.973216C2.66796 0.84421 2.36167 0.786895 2.05542 0.806489C1.74917 0.826083 1.45268 0.921963 1.19294 1.0854C0.93321 1.24884 0.718478 1.47465 0.568296 1.74226C0.418114 2.00988 0.337252 2.31081 0.33307 2.61766V23.2488C0.33047 23.5638 0.409168 23.8741 0.561558 24.1498C0.713948 24.4254 0.934877 24.6571 1.20299 24.8225C1.4711 24.9878 1.77733 25.0812 2.09208 25.0936C2.40683 25.106 2.71946 25.037 2.99974 24.8932L23.5864 14.5732C23.8914 14.4201 24.1477 14.1853 24.3269 13.8949C24.5061 13.6045 24.6009 13.27 24.6009 12.9288C24.6009 12.5876 24.5061 12.2531 24.3269 11.9627C24.1477 11.6723 23.8914 11.4374 23.5864 11.2843V11.2932Z"
								fill="#001574"
							/>
						</svg>
					</button>
				</div>
			</div>
		));
	}

	return (
		<Content>
			{pacientes_loading && <Loading />}
			{!pacientes_loading && (
				<>
					<div className="boxSearch">
						<div className="input">
							<button type="button" className={"reset"}>
								<img className="lupa" src={LupaFilterProtocol} alt="" />
							</button>
							<input
								// onKeyDown={Submit}
								value={inputValueExercise}
								onChange={handleInputChangeExercise}
								className="inputPesquisa"
								type="text"
								placeholder="Pesquisar exercícios"
							/>
							{inputValueExercise != "" && (
								<button type="reset" className="reset">
									<img
										className="x"
										src={Limpar}
										alt=""
										onClick={() => resetInput("exercise")}
									/>
								</button>
							)}
						</div>
					</div>
					{_renderExerciseList(
						inputValueExercise != "" ? exercicios_pesquisa : exerciciosCurrent
					)}
				</>
			)}
		</Content>
	);
};

export default ExercicioSup;
