
import React, { memo } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";

import buttonClose from "../../../assets/icons/fechar.svg";
import { AppActions } from "../../../store/ducks/app";
import WarningNewApp from "../../../assets/aviso-novo-app-web.png";

import { 
	Close, 
	Container, 
	Content,
	WarningIcon
} from "./styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAvisoNovoApp: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const is_open_warning_new_app = useSelector(
		(state: any) => state.app.is_open_warning_new_app
	);
	const ModalAvisoNovoAppClose = () => {
		dispatch(AppActions.set_modal_warning_new_app(false))
	};
	
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_warning_new_app}
				onClose={ModalAvisoNovoAppClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_warning_new_app}>
					<Container>
						<Close
							src={buttonClose}
							onClick={()=>  
								dispatch(AppActions.set_modal_warning_new_app(false))
							}
							alt="Close"
						/>
						<Content>
						<WarningIcon
							src={WarningNewApp}
							alt="warning"
						/>
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalAvisoNovoApp);
