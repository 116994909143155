export const texts = [
			  {
			  	pt: {
			  		protocolo_elastic: "Protocolo Kinology",
			  	},
			  	en: {
			  		protocolo_elastic: "Kinology protocol",
			  	},
			  	es: {
			  		protocolo_elastic: "Protocolo Kinology",
			  	}
			  },
			  {
				pt: {
					protocolos_disponiveis_para: "Protocolo(s) escolhido(s)",
				},
				en: {
					protocolos_disponiveis_para: "Protocol(s) chosen",
				},
				es: {
					protocolos_disponiveis_para: "Protocolo(s) elegido(s)",
				}
			 },
			  {
				pt: {
					protocolos_tipo_categorias: "Protocolos por articulação",
				},
				en: {
					protocolos_tipo_categorias: "Joint protocols",
				},
				es: {
					protocolos_tipo_categorias: "Protocolos conjuntos",
				}
			  },
			  {
				pt: {
					protocolos_tipo_globais: "Protocolos Globais",
				},
				en: {
					protocolos_tipo_globais: "Global Protocols",
				},
				es: {
					protocolos_tipo_globais: "Protocolos globales",
				}
			},
			{
				pt: {
					nenhum_protocolo_selecionado_no_momento: "Nenhum protocolo selecionado no momento",
				},
				en: {
					nenhum_protocolo_selecionado_no_momento: "No protocols currently selected",
				},
				es: {
					nenhum_protocolo_selecionado_no_momento: "No hay protocolos seleccionados actualmente",
				}
			},
		
			  {
			  	pt: {
			  		protocolo_personalizado: "Protocolo personalizado",
			  	},
			  	en: {
			  		protocolo_personalizado: "Custom protocol",
			  	},
			  	es: {
			  		protocolo_personalizado: "Protocolo personalizado",
			  	}
			  },
			  {
				pt: {
					o_que_deseja_avaliar: "O que deseja avaliar para o paciente",
				},
				en: {
					o_que_deseja_avaliar: "What do you want to assess for the patient",
				},
				es: {
					o_que_deseja_avaliar: "Qué desea evaluar para el paciente",
				}
			  },
		
			  {
			  	pt: {
			  		sem_conexao_exec_salva_offline: "Sem conexão | Execução foi salva offline.",
			  	},
			  	en: {
			  		sem_conexao_exec_salva_offline: "No connection | Execution was saved offline.",
			  	},
			  	es: {
			  		sem_conexao_exec_salva_offline: "Sin conexión | La ejecución se guardó sin conexión.",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_salvar_execucao: "Erro ao salvar execução",
			  	},
			  	en: {
			  		erro_salvar_execucao: "Error saving run",
			  	},
			  	es: {
			  		erro_salvar_execucao: "Error al guardar la ejecución",
			  	}
			  },
		
			  {
			  	pt: {
			  		aviso: "Aviso",
			  	},
			  	en: {
			  		aviso: "Notice",
			  	},
			  	es: {
			  		aviso: "Aviso",
			  	}
			  },
		
			  {
			  	pt: {
			  		avaliacao_fisica_fisioter: "Avaliação física fisioterapêutico",
			  	},
			  	en: {
			  		avaliacao_fisica_fisioter: "Physiotherapeutic physical assessment",
			  	},
			  	es: {
			  		avaliacao_fisica_fisioter: "Evaluación física fisioterapéutica",
			  	}
			  },
		
			  {
			  	pt: {
			  		avaliacao_fisica_multi: "Avaliação física Multifatorial",
			  	},
			  	en: {
			  		avaliacao_fisica_multi: "Multifactorial physical assessment",
			  	},
			  	es: {
			  		avaliacao_fisica_multi: "Evaluación física multifactorial",
			  	}
			  },
		
			  {
			  	pt: {
			  		execucao_sem_dados: "Execução finalizada sem dados",
			  	},
			  	en: {
			  		execucao_sem_dados: "Execution terminated without data",
			  	},
			  	es: {
			  		execucao_sem_dados: "Ejecución terminada sin datos",
			  	}
			  },
		
			  {
			  	pt: {
			  		sem_divisao_eva: "sem divisão",
			  	},
			  	en: {
			  		sem_divisao_eva: "without division",
			  	},
			  	es: {
			  		sem_divisao_eva: "sin división",
			  	}
			  },
		
			  {
			  	pt: {
			  		direito_eva: "direito",
			  	},
			  	en: {
			  		direito_eva: "right",
			  	},
			  	es: {
			  		direito_eva: "bien",
			  	}
			  },
		
			  {
			  	pt: {
			  		esquerdo_eva: "esquerdo",
			  	},
			  	en: {
			  		esquerdo_eva: "left",
			  	},
			  	es: {
			  		esquerdo_eva: "izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		punho: "punho",
			  	},
			  	en: {
			  		punho: "fish",
			  	},
			  	es: {
			  		punho: "pez",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_calibre_dino: "Calibrar dinamômetro",
			  	},
			  	en: {
			  		menu_lateral_calibre_dino: "Calibrate dynamometer",
			  	},
			  	es: {
			  		menu_lateral_calibre_dino: "Calibrar dinamómetro",
			  	}
			  },
		
			  {
			  	pt: {
			  		inadin_faturas: "Caso não saiba qual é, clique aqui para acessar a área do financeiro do seu aplicativo, acessar o boleto da mensalidade em atraso e nos envie o comprovante de pagamento através desse link de whatsapp",
			  	},
			  	en: {
			  		inadin_faturas: "If you dont know what it is, click here to access the financial area of ​​your application, access the overdue monthly payment slip and send us proof of payment through this whatsapp link",
			  	},
			  	es: {
			  		inadin_faturas: "Si no sabes cuál es, haz clic aquí para acceder al área financiera de tu solicitud, accede al comprobante de pago de la mensualidad vencida y envíanos el comprobante de pago a través de este enlace de whatsapp",
			  	}
			  },
		
			  {
			  	pt: {
			  		inadin_title: "Infelizmente, um de seus pagamentos não foi localizado e, portanto, seu aplicativo Kinology foi bloqueado.",
			  	},
			  	en: {
			  		inadin_title: "Unfortunately, one of your payments has not been located and therefore your Kinology application has been blocked",
			  	},
			  	es: {
			  		inadin_title: "Desafortunadamente, uno de sus pagos no ha sido localizado y, por lo tanto, su aplicación Kinology ha sido bloqueada.",
			  	}
			  },
		
			  {
			  	pt: {
			  		inadin_whats: "Caso saiba qual é o pagamento em atraso e possua o boleto de pagamento, nos envie o comprovante de pagamento através desse link de WhatsApp.",
			  	},
			  	en: {
			  		inadin_whats: "If you know what the overdue payment is and you have the payment slip, send us proof of payment through this WhatsApp link.",
			  	},
			  	es: {
			  		inadin_whats: "Si sabe cuál es el pago atrasado y tiene el comprobante de pago, envíenos el comprobante de pago a través de este enlace de WhatsApp.",
			  	}
			  },
		
			  {
			  	pt: {
			  		contracao: "Contração",
			  	},
			  	en: {
			  		contracao: "Contraction",
			  	},
			  	es: {
			  		contracao: "Contracción",
			  	}
			  },
		
			  {
			  	pt: {
			  		new_update_app: "O que há de novo!",
			  	},
			  	en: {
			  		new_update_app: "What´s new!",
			  	},
			  	es: {
			  		new_update_app: "¡Que hay de nuevo!",
			  	}
			  },
		
			  {
			  	pt: {
			  		updates: "Atualizações",
			  	},
			  	en: {
			  		updates: "Updates",
			  	},
			  	es: {
			  		updates: "Actualizaciones",
			  	}
			  },
		
			  {
			  	pt: {
			  		atualizacoes_app: "Atualizações do aplicativo",
			  	},
			  	en: {
			  		atualizacoes_app: "app updates",
			  	},
			  	es: {
			  		atualizacoes_app: "actualizaciones de aplicaciones",
			  	}
			  },
		
			  {
			  	pt: {
			  		falha_atualizar_termos: "Não foi possível atualizar os termos.",
			  	},
			  	en: {
			  		falha_atualizar_termos: "Unable to update terms.",
			  	},
			  	es: {
			  		falha_atualizar_termos: "No se pueden actualizar los términos.",
			  	}
			  },
		
			  {
			  	pt: {
			  		falha_assinar_termo: "Falha ao assinar o termo.",
			  	},
			  	en: {
			  		falha_assinar_termo: "Failed to sign the term.",
			  	},
			  	es: {
			  		falha_assinar_termo: "No se pudo firmar el término.",
			  	}
			  },
		
			  {
			  	pt: {
			  		ler_termos: "Eu li e aceito os Termos de uso e Política de Privacidade Kinology .",
			  	},
			  	en: {
			  		ler_termos: "I have read and accept the Kinology Terms of Use and Privacy Policy.",
			  	},
			  	es: {
			  		ler_termos: "He leído y acepto los Términos de Uso y Política de Privacidad de Kinology.",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao_foi_possivel_verificar_liberacao: "Não foi possível verificar a liberação do dinamômetro",
			  	},
			  	en: {
			  		nao_foi_possivel_verificar_liberacao: "Unable to verify dynamometer release",
			  	},
			  	es: {
			  		nao_foi_possivel_verificar_liberacao: "No se puede verificar la liberación del dinamómetro",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao_foi_possivel_liberar_400: "400 - Não foi possível verificar a liberação do dinamômetro",
			  	},
			  	en: {
			  		nao_foi_possivel_liberar_400: "400 - Unable to verify dynamometer release",
			  	},
			  	es: {
			  		nao_foi_possivel_liberar_400: "400 - No se puede verificar la liberación del dinamómetro",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao_foi_possivel_liberar_401: "401 - Não foi possível verificar a liberação do dinamômetro",
			  	},
			  	en: {
			  		nao_foi_possivel_liberar_401: "401 - Unable to verify dynamometer release",
			  	},
			  	es: {
			  		nao_foi_possivel_liberar_401: "401 - No se puede verificar la liberación del dinamómetro",
			  	}
			  },
		
			  {
			  	pt: {
			  		dinamo_bloqueado: "Dinamô bloqueado por inadimplência.",
			  	},
			  	en: {
			  		dinamo_bloqueado: "Dynamô blocked due to default.",
			  	},
			  	es: {
			  		dinamo_bloqueado: "Dynamô bloqueado por defecto.",
			  	}
			  },
		
			  {
			  	pt: {
			  		deseja_excluir: "Deseja excluir ?",
			  	},
			  	en: {
			  		deseja_excluir: "Do you want to delete ?",
			  	},
			  	es: {
			  		deseja_excluir: "Desea eliminar ?",
			  	}
			  },
		
			  {
			  	pt: {
			  		notifi_paci_excluido: "Notificação de um paciente excluido",
			  	},
			  	en: {
			  		notifi_paci_excluido: "Notification of an excluded patient",
			  	},
			  	es: {
			  		notifi_paci_excluido: "Notificación de un paciente excluido",
			  	}
			  },
		
			  {
			  	pt: {
			  		especialidade: "Especialidade",
			  	},
			  	en: {
			  		especialidade: "Specialty",
			  	},
			  	es: {
			  		especialidade: "Especialidad",
			  	}
			  },
		
			  {
			  	pt: {
			  		avaliadores: "Avaliadores",
			  	},
			  	en: {
			  		avaliadores: "Appraisers",
			  	},
			  	es: {
			  		avaliadores: "Tasadores",
			  	}
			  },
		
			  {
			  	pt: {
			  		update_app: "Atualizando ...",
			  	},
			  	en: {
			  		update_app: "Updating ...",
			  	},
			  	es: {
			  		update_app: "Actualizando ...",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_paciente_exportar_dados: "Escolha um paciente para exportar os dados",
			  	},
			  	en: {
			  		escolha_paciente_exportar_dados: "Choose a patient to export the data",
			  	},
			  	es: {
			  		escolha_paciente_exportar_dados: "Elija un paciente para exportar los datos",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhuma_info_iq: "Nenhuma informação de I/Q",
			  	},
			  	en: {
			  		nenhuma_info_iq: "No I/Q information",
			  	},
			  	es: {
			  		nenhuma_info_iq: "Sin información I/Q",
			  	}
			  },
		
			  {
					pt: {
						baixa_chance_de_lesao: 'Abaixo de 50%: Desequilíbrio por quadríceps mais fortes que isquiotibiais',
					},
					en: {
						baixa_chance_de_lesao: 'Below 50%: Imbalance due to quadriceps being stronger than hamstrings',
					},
					es: {
						baixa_chance_de_lesao: 'Por debajo del 50 %: desequilibrio debido a que los cuádriceps son más fuertes que los isquiotibiales.',
					},
				},
			
				{
					pt: {
						media_chance_de_lesao: 'Entre 50% e 70%: Normalidade',
					},
					en: {
						media_chance_de_lesao: 'Between 50% and 70%: Normal',
					},
					es: {
						media_chance_de_lesao: 'Entre 50% y 70%: Normal',
					},
				},
			
				{
					pt: {
						alta_chance_de_lesao: 'Acima de 70%: Desequilíbrio por isquiotibiais mais fortes que quadríceps',
					},
					en: {
						alta_chance_de_lesao: 'Above 70%: Imbalance due to hamstrings stronger than quadriceps',
					},
					es: {
						alta_chance_de_lesao: 'Por encima del 70%: desequilibrio debido a que los isquiotibiales son más fuertes que los cuádriceps',
					},
				},
		
			  {
			  	pt: {
			  		digite_tag_maior: "Digite uma tag maior que 3 caracteres",
			  	},
			  	en: {
			  		digite_tag_maior: "Enter a tag longer than 3 characters",
			  	},
			  	es: {
			  		digite_tag_maior: "Ingrese una etiqueta de más de 3 caracteres",
			  	}
			  },
		
			  {
			  	pt: {
			  		msg_modal_alert_m: "Após migrar a repetição, todos os dados serão transferidos para o paciente selecionado.",
			  	},
			  	en: {
			  		msg_modal_alert_m: "After migrating the repeat, all data will be transferred to the selected patient.",
			  	},
			  	es: {
			  		msg_modal_alert_m: "Después de migrar la repetición, todos los datos se transferirán al paciente seleccionado.",
			  	}
			  },
		
			  {
			  	pt: {
			  		msg_modal_alert_e: "Após excluir a repetição, todos os dados serão perdidos.",
			  	},
			  	en: {
			  		msg_modal_alert_e: "After deleting the repeat, all data will be lost.",
			  	},
			  	es: {
			  		msg_modal_alert_e: "Después de eliminar la repetición, se perderán todos los datos.",
			  	}
			  },
		
			  {
			  	pt: {
			  		deseja_migrar: "Deseja migrar?",
			  	},
			  	en: {
			  		deseja_migrar: "Do you want to migrate?",
			  	},
			  	es: {
			  		deseja_migrar: "¿Quieres migrar?",
			  	}
			  },
		
			  {
			  	pt: {
			  		delete_execucao: "Descartar execução",
			  	},
			  	en: {
			  		delete_execucao: "Discard execution",
			  	},
			  	es: {
			  		delete_execucao: "Descartar ejecución",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhuma_notificacao: "Nenhuma notificação",
			  	},
			  	en: {
			  		nenhuma_notificacao: "no notification",
			  	},
			  	es: {
			  		nenhuma_notificacao: "sin notificación",
			  	}
			  },
		
			  {
			  	pt: {
			  		link_enviado_com_sucesso: "Link enviado com sucesso para o e-mail informado",
			  	},
			  	en: {
			  		link_enviado_com_sucesso: "Link successfully sent to the email provided",
			  	},
			  	es: {
			  		link_enviado_com_sucesso: "Enlace enviado con éxito al correo electrónico proporcionado",
			  	}
			  },
		
			  {
			  	pt: {
			  		link_copiado: "Link copiado com sucesso!",
			  	},
			  	en: {
			  		link_copiado: "Link copied successfully!",
			  	},
			  	es: {
			  		link_copiado: "¡Enlace copiado con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		copiar_link: "Copiar Link",
			  	},
			  	en: {
			  		copiar_link: "copy link",
			  	},
			  	es: {
			  		copiar_link: "Copiar enlace",
			  	}
			  },
		
			  {
			  	pt: {
			  		zero_repeticoes: "Execução não salva por estar com zero repetições",
			  	},
			  	en: {
			  		zero_repeticoes: "Execution not saved due to zero repetitions",
			  	},
			  	es: {
			  		zero_repeticoes: "Ejecución no guardada por cero repeticiones",
			  	}
			  },
		
			  {
			  	pt: {
			  		enviar_a_anamnese_para_ser_respondida: "Enviar a Anamnese para ser respondida diretamente pelo paciente de forma assíncrona.",
			  	},
			  	en: {
			  		enviar_a_anamnese_para_ser_respondida: "Send the Anamnesis to be answered directly by the patient asynchronously.",
			  	},
			  	es: {
			  		enviar_a_anamnese_para_ser_respondida: "Envía la Anamnesis para ser contestada directamente por el paciente de forma asíncrona.",
			  	}
			  },
		
			  {
			  	pt: {
			  		emissoes_gratis: "emissões gratuitas dos relatórios Multifatorial e Fisioterapêutico",
			  	},
			  	en: {
			  		emissoes_gratis: " free issuance of Multifactorial and Physiotherapy reports",
			  	},
			  	es: {
			  		emissoes_gratis: "emisión gratuita de Informes Multifactoriales y de Fisioterapia",
			  	}
			  },
		
			  {
			  	pt: {
			  		message2_sair_perfil_paciente: "Se voltar, você poderá continuar de onde parou mais tarde.",
			  	},
			  	en: {
			  		message2_sair_perfil_paciente: "If it comes back, you can pick up where you left off later.",
			  	},
			  	es: {
			  		message2_sair_perfil_paciente: "Si regresa, puede continuar donde lo dejó más tarde.",
			  	}
			  },
		
			  {
			  	pt: {
			  		continuar_in_modal: "Continuar",
			  	},
			  	en: {
			  		continuar_in_modal: "Continue",
			  	},
			  	es: {
			  		continuar_in_modal: "Continuar",
			  	}
			  },
		
			  {
			  	pt: {
			  		sair_modal: "Sair",
			  	},
			  	en: {
			  		sair_modal: "Go out",
			  	},
			  	es: {
			  		sair_modal: "Salir",
			  	}
			  },
		
			  {
			  	pt: {
			  		message_sair_perfil_paciente: "Deseja realmente sair para o perfil do paciente?",
			  	},
			  	en: {
			  		message_sair_perfil_paciente: "Do you really want to go out to the patient profile?",
			  	},
			  	es: {
			  		message_sair_perfil_paciente: "¿De verdad quieres salir al perfil del paciente?",
			  	}
			  },
		
			  {
			  	pt: {
			  		anamnese_nao_finalizada: "Anamnese não finalizada",
			  	},
			  	en: {
			  		anamnese_nao_finalizada: "Anamnesis not completed",
			  	},
			  	es: {
			  		anamnese_nao_finalizada: "Anamnesis no completa",
			  	}
			  },
		
			  {
			  	pt: {
			  		question_description_async: "Caso ainda não tenha respondido, solicite um novo link ao profissional.",
			  	},
			  	en: {
			  		question_description_async: "If you still haven t answered, ask the professional for a new link.",
			  	},
			  	es: {
			  		question_description_async: "Si aún no has contestado, pide al profesional un nuevo enlace.",
			  	}
			  },
		
			  {
			  	pt: {
			  		question_ja_respon: "Questionário já respondido !",
			  	},
			  	en: {
			  		question_ja_respon: "Questionnaire already answered!",
			  	},
			  	es: {
			  		question_ja_respon: "¡Cuestionario ya respondido!",
			  	}
			  },
		
			  {
			  	pt: {
			  		testeAmilcar: "testeAlcimar",
			  	},
			  	en: {
			  		testeAmilcar: "testAlcimar",
			  	},
			  	es: {
			  		testeAmilcar: "tessfmarlnm",
			  	}
			  },
		
			  {
			  	pt: {
			  		coccix: "Cóccix",
			  	},
			  	en: {
			  		coccix: "Coccyx",
			  	},
			  	es: {
			  		coccix: "Cóccix",
			  	}
			  },
		
			  {
			  	pt: {
			  		lombar: "Lombar",
			  	},
			  	en: {
			  		lombar: "Low back",
			  	},
			  	es: {
			  		lombar: "Espalda baja",
			  	}
			  },
		
			  {
			  	pt: {
			  		toracica: "Torácica",
			  	},
			  	en: {
			  		toracica: "Thoracic",
			  	},
			  	es: {
			  		toracica: "Torácico",
			  	}
			  },
		
			  {
			  	pt: {
			  		cervical: "Cervical",
			  	},
			  	en: {
			  		cervical: "Cervical",
			  	},
			  	es: {
			  		cervical: "Cervical",
			  	}
			  },
		
			  {
			  	pt: {
			  		tornozelo: "Tornozelo",
			  	},
			  	en: {
			  		tornozelo: "Ankle",
			  	},
			  	es: {
			  		tornozelo: "Tobillo",
			  	}
			  },
		
			  {
			  	pt: {
			  		joelho: "Joelho",
			  	},
			  	en: {
			  		joelho: "Knee",
			  	},
			  	es: {
			  		joelho: "Rodilla",
			  	}
			  },
		
			  {
			  	pt: {
			  		quadril: "Quadril",
			  	},
			  	en: {
			  		quadril: "Hip",
			  	},
			  	es: {
			  		quadril: "Cadera",
			  	}
			  },
		
			  {
			  	pt: {
			  		pulso: "Pulso",
			  	},
			  	en: {
			  		pulso: "Pulse",
			  	},
			  	es: {
			  		pulso: "Legumbres",
			  	}
			  },
		
			  {
			  	pt: {
			  		cotovelo: "Cotovelo",
			  	},
			  	en: {
			  		cotovelo: "Elbow",
			  	},
			  	es: {
			  		cotovelo: "Codo",
			  	}
			  },
		
			  {
			  	pt: {
			  		ombro: "Ombro",
			  	},
			  	en: {
			  		ombro: "Shoulder",
			  	},
			  	es: {
			  		ombro: "Hombro",
			  	}
			  },
		
			  {
			  	pt: {
			  		paciente_obter_detalhes: "paciente para obter todos detalhes",
			  	},
			  	en: {
			  		paciente_obter_detalhes: "patient for all details",
			  	},
			  	es: {
			  		paciente_obter_detalhes: "paciente para todos los detalles",
			  	}
			  },
		
			  {
			  	pt: {
			  		descri_opcao_anamnese: "Você pode realizar uma avaliação antes de iniciar o questionário.",
			  	},
			  	en: {
			  		descri_opcao_anamnese: "You can perform an assessment before starting the questionnaire.",
			  	},
			  	es: {
			  		descri_opcao_anamnese: "Puede realizar una evaluación antes de iniciar la encuesta.",
			  	}
			  },
		
			  {
			  	pt: {
			  		message3_shere_question: "Boa avaliação!",
			  	},
			  	en: {
			  		message3_shere_question: "Good review!",
			  	},
			  	es: {
			  		message3_shere_question: "¡Buena reseña!",
			  	}
			  },
		
			  {
			  	pt: {
			  		message2_shere_question: "As respostas serão enviadas automaticamente ao término do processo.",
			  	},
			  	en: {
			  		message2_shere_question: "The answers will be sent automatically at the end of the process.",
			  	},
			  	es: {
			  		message2_shere_question: "Las respuestas se enviarán automáticamente al final del proceso.",
			  	}
			  },
		
			  {
			  	pt: {
			  		message1_shere_question: "O profissional que irá te avaliar enviou o link para realizar a pré-consulta, por favor, reserve 15 minutos para respondê-la.",
			  	},
			  	en: {
			  		message1_shere_question: "The professional who will evaluate you sent the link to carry out the pre-appointment, please take 15 minutes to answer it.",
			  	},
			  	es: {
			  		message1_shere_question: "El profesional que te evaluará envió el link para realizar la cita previa, por favor tómate 15 minutos para contestarlo.",
			  	}
			  },
		
			  {
			  	pt: {
			  		assinar_pacote: "Assinar pacote",
			  	},
			  	en: {
			  		assinar_pacote: "Sign package",
			  	},
			  	es: {
			  		assinar_pacote: "Firmar paquete",
			  	}
			  },
		
			  {
			  	pt: {
			  		assinatura_nao_encontrada: "Assinatura não encontrada",
			  	},
			  	en: {
			  		assinatura_nao_encontrada: "Signature not found",
			  	},
			  	es: {
			  		assinatura_nao_encontrada: "Firma no encontrada",
			  	}
			  },
		
			  {
			  	pt: {
			  		encaminhar_cunsultor: "Identificamos que você ainda não é nosso cliente, estamos te encaminhando para falar com nossos consultores!",
			  	},
			  	en: {
			  		encaminhar_cunsultor: "We have identified that you are not yet our client, we are referring you to speak with our consultants!",
			  	},
			  	es: {
			  		encaminhar_cunsultor: " Hemos identificado que aún no es nuestro cliente, lo estamos refiriendo a hablar con nuestros consultores.",
			  	}
			  },
		
			  {
			  	pt: {
			  		pacotes: "Pacotes",
			  	},
			  	en: {
			  		pacotes: "Packages",
			  	},
			  	es: {
			  		pacotes: "Paquetes",
			  	}
			  },
		
			  {
			  	pt: {
			  		pacote: "Pacote",
			  	},
			  	en: {
			  		pacote: "Package",
			  	},
			  	es: {
			  		pacote: "Paquete",
			  	}
			  },
		
			  {
			  	pt: {
			  		exerc_assimetria: "Exercícios com assimetria",
			  	},
			  	en: {
			  		exerc_assimetria: "Exercises with asymmetry",
			  	},
			  	es: {
			  		exerc_assimetria: "Ejercicios con asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		quais_visualizacoes: "Quais as visualizações que você deseja?",
			  	},
			  	en: {
			  		quais_visualizacoes: "What views do you want?",
			  	},
			  	es: {
			  		quais_visualizacoes: "¿Qué vistas quieres?",
			  	}
			  },
		
			  {
			  	pt: {
			  		migracao_com_falha: "Migração falhou",
			  	},
			  	en: {
			  		migracao_com_falha: " Migration failed",
			  	},
			  	es: {
			  		migracao_com_falha: " Migración fallida",
			  	}
			  },
		
			  {
			  	pt: {
			  		migracao_com_sucesso: "Migração concluída",
			  	},
			  	en: {
			  		migracao_com_sucesso: "Migration completed",
			  	},
			  	es: {
			  		migracao_com_sucesso: "Migración completada",
			  	}
			  },
		
			  {
			  	pt: {
			  		qualidade_de_vida_resp: "Qualidade de vida já respondida!",
			  	},
			  	en: {
			  		qualidade_de_vida_resp: "Quality of life already answered!",
			  	},
			  	es: {
			  		qualidade_de_vida_resp: "Calidad de vida ya respondida!",
			  	}
			  },
		
			  {
			  	pt: {
			  		anamnese_resp_sair_pag: "Os dados foram enviados para o profissional e você já pode sair dessa página.",
			  	},
			  	en: {
			  		anamnese_resp_sair_pag: "The data has been sent to the professional and you can now leave this page.",
			  	},
			  	es: {
			  		anamnese_resp_sair_pag: "Los datos han sido enviados al profesional y ya puedes salir de esta página.",
			  	}
			  },
		
			  {
			  	pt: {
			  		anamnese_resp_novo_link: "Sua anamnese já está respondida, caso precise responder novamente, solicite ao profissional um novo link.",
			  	},
			  	en: {
			  		anamnese_resp_novo_link: "Your anamnesis is already answered, if you need to answer again, ask the professional for a new link.",
			  	},
			  	es: {
			  		anamnese_resp_novo_link: "Tu anamnesis ya está contestada, si necesitas volver a contestar pide al profesional un nuevo enlace.",
			  	}
			  },
		
			  {
			  	pt: {
			  		anamnese_ja_respondida: "Anamnese já respondida !",
			  	},
			  	en: {
			  		anamnese_ja_respondida: "Anamnesis already answered!",
			  	},
			  	es: {
			  		anamnese_ja_respondida: "Anamnesis ya respondida!",
			  	}
			  },
		
			  {
			  	pt: {
			  		link_nao_encontrado: "Link não encontrado!",
			  	},
			  	en: {
			  		link_nao_encontrado: "Link not found!",
			  	},
			  	es: {
			  		link_nao_encontrado: "¡Enlace no encontrado!",
			  	}
			  },
		
			  {
			  	pt: {
			  		assinar_servico: "Assinar serviço",
			  	},
			  	en: {
			  		assinar_servico: "Subscribe to service",
			  	},
			  	es: {
			  		assinar_servico: "Suscribirse al servicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		adquira_novo_pacote_relatorio: "Adquiria um novo pacote de relatórios para voltar a utilizá-los",
			  	},
			  	en: {
			  		adquira_novo_pacote_relatorio: "I would buy a new report pack to use them again",
			  	},
			  	es: {
			  		adquira_novo_pacote_relatorio: "Compraría un nuevo paquete de informes para usarlos nuevamente",
			  	}
			  },
		
			  {
			  	pt: {
			  		relatorios_acabaram: "Seus relatórios acabaram",
			  	},
			  	en: {
			  		relatorios_acabaram: "Your reports are over",
			  	},
			  	es: {
			  		relatorios_acabaram: "Tus informes han terminado.",
			  	}
			  },
		
			  {
			  	pt: {
			  		ainda_restam: "Ainda restam",
			  	},
			  	en: {
			  		ainda_restam: "There are still",
			  	},
			  	es: {
			  		ainda_restam: "Aún quedan",
			  	}
			  },
		
			  {
			  	pt: {
			  		assinatura_atualizada: "Assinatura atualizada",
			  	},
			  	en: {
			  		assinatura_atualizada: "Updated signature",
			  	},
			  	es: {
			  		assinatura_atualizada: "Firma actualizada",
			  	}
			  },
		
			  {
			  	pt: {
			  		concordo_termos: "e que concordo com todas as suas disposições",
			  	},
			  	en: {
			  		concordo_termos: "and that I agree with all of its provisions",
			  	},
			  	es: {
			  		concordo_termos: "y que estoy de acuerdo con todas sus disposiciones",
			  	}
			  },
		
			  {
			  	pt: {
			  		contrato_servico_elastic: "CONTRATO DE SERVIÇO DA KINOLOGY",
			  	},
			  	en: {
			  		contrato_servico_elastic: "KINOLOGY SERVICE AGREEMENT",
			  	},
			  	es: {
			  		contrato_servico_elastic: "CONTRATO DE SERVICIO DE KINOLOGY",
			  	}
			  },
		
			  {
			  	pt: {
			  		declaro_que_li: "Declaro que li o",
			  	},
			  	en: {
			  		declaro_que_li: "I declare that I have read the",
			  	},
			  	es: {
			  		declaro_que_li: "Declaro que he leído el",
			  	}
			  },
		
			  {
			  	pt: {
			  		mes_palavra: "mês",
			  	},
			  	en: {
			  		mes_palavra: "month",
			  	},
			  	es: {
			  		mes_palavra: "mes",
			  	}
			  },
		
			  {
			  	pt: {
			  		planos: "Planos",
			  	},
			  	en: {
			  		planos: "Plans",
			  	},
			  	es: {
			  		planos: "Planes",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhuma_anotacao_excluida: "Nenhuma anotação excluída",
			  	},
			  	en: {
			  		nenhuma_anotacao_excluida: "No notes deleted",
			  	},
			  	es: {
			  		nenhuma_anotacao_excluida: "No se eliminaron notas",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluir_exercicio_pergunta: "Excluir exercício?",
			  	},
			  	en: {
			  		excluir_exercicio_pergunta: "Exclude exercise?",
			  	},
			  	es: {
			  		excluir_exercicio_pergunta: "¿Excluir el ejercicio?",
			  	}
			  },
		
			  {
			  	pt: {
			  		titulo_protocolo_existe: "Título do protocolo já existe",
			  	},
			  	en: {
			  		titulo_protocolo_existe: "Protocol title already exists",
			  	},
			  	es: {
			  		titulo_protocolo_existe: " El título del protocolo ya existe",
			  	}
			  },
		
			  {
			  	pt: {
			  		sujeito_termos_condicoes: "Sujeito a termos e condições.",
			  	},
			  	en: {
			  		sujeito_termos_condicoes: "Subject to terms and conditions.",
			  	},
			  	es: {
			  		sujeito_termos_condicoes: "Sujeto a términos y condiciones.",
			  	}
			  },
		
			  {
			  	pt: {
			  		modo_visualizacao: "Selecione pelo menos um modo de visualização",
			  	},
			  	en: {
			  		modo_visualizacao: "Select at least one view mode",
			  	},
			  	es: {
			  		modo_visualizacao: "Seleccione al menos un modo de vista",
			  	}
			  },
		
			  {
			  	pt: {
			  		perido_analise: "Qual período deseja analisar os exercícios?",
			  	},
			  	en: {
			  		perido_analise: "What period do you want to study the exercises?",
			  	},
			  	es: {
			  		perido_analise: "¿En qué periodo quieres estudiar los ejercicios?",
			  	}
			  },
		
			  {
			  	pt: {
			  		ilimitado: "Ilimitado",
			  	},
			  	en: {
			  		ilimitado: "Unlimited",
			  	},
			  	es: {
			  		ilimitado: "Ilimitado",
			  	}
			  },
		
			  {
			  	pt: {
			  		plan_tratamento: "Plano de tratamento proposto",
			  	},
			  	en: {
			  		plan_tratamento: "Proposed treatment plan",
			  	},
			  	es: {
			  		plan_tratamento: "Plan de tratamiento propuesto",
			  	}
			  },
		
			  {
			  	pt: {
			  		fotos: "Fotos",
			  	},
			  	en: {
			  		fotos: "Photos",
			  	},
			  	es: {
			  		fotos: "Fotos",
			  	}
			  },
		
			  {
			  	pt: {
			  		exames_gerais: "Exames gerais",
			  	},
			  	en: {
			  		exames_gerais: "General exams",
			  	},
			  	es: {
			  		exames_gerais: "Exámenes generales",
			  	}
			  },
		
			  {
			  	pt: {
			  		dados_gerais: "Dados gerais",
			  	},
			  	en: {
			  		dados_gerais: "General data",
			  	},
			  	es: {
			  		dados_gerais: "Datos generales",
			  	}
			  },
		
			  {
			  	pt: {
			  		frequencia_respiratoria: "Frequência respiratória",
			  	},
			  	en: {
			  		frequencia_respiratoria: "Respiratory frequency",
			  	},
			  	es: {
			  		frequencia_respiratoria: "Frecuencia respiratoria",
			  	}
			  },
		
			  {
			  	pt: {
			  		frequencia_cardiaca: "Frequência cardíaca",
			  	},
			  	en: {
			  		frequencia_cardiaca: "Heart rate",
			  	},
			  	es: {
			  		frequencia_cardiaca: "Frecuencia cardiaca",
			  	}
			  },
		
			  {
			  	pt: {
			  		press_arterial: "Pressão aterial",
			  	},
			  	en: {
			  		press_arterial: "Blood pressure",
			  	},
			  	es: {
			  		press_arterial: "Presión arterial",
			  	}
			  },
		
			  {
			  	pt: {
			  		sinais_vitais: "Sinais vitais",
			  	},
			  	en: {
			  		sinais_vitais: "Vital signs",
			  	},
			  	es: {
			  		sinais_vitais: "Signos vitales",
			  	}
			  },
		
			  {
			  	pt: {
			  		queixa_ataul: "Queixa atual",
			  	},
			  	en: {
			  		queixa_ataul: "Current complaint",
			  	},
			  	es: {
			  		queixa_ataul: "Queja actual",
			  	}
			  },
		
			  {
			  	pt: {
			  		exame_fisico: "Exame Físico",
			  	},
			  	en: {
			  		exame_fisico: "Physical exam",
			  	},
			  	es: {
			  		exame_fisico: "Examen físico",
			  	}
			  },
		
			  {
			  	pt: {
			  		history_geral: "Histórico geral",
			  	},
			  	en: {
			  		history_geral: "General history",
			  	},
			  	es: {
			  		history_geral: "Historia general",
			  	}
			  },
		
			  {
			  	pt: {
			  		relatorio_final: "Relatório Final",
			  	},
			  	en: {
			  		relatorio_final: "Final report",
			  	},
			  	es: {
			  		relatorio_final: "Informe final",
			  	}
			  },
		
			  {
			  	pt: {
			  		h_saude: "Histórico de saúde",
			  	},
			  	en: {
			  		h_saude: "Health history",
			  	},
			  	es: {
			  		h_saude: "Historia de salud",
			  	}
			  },
		
			  {
			  	pt: {
			  		historico_familiar: "Histórico Famíliar",
			  	},
			  	en: {
			  		historico_familiar: "Family history",
			  	},
			  	es: {
			  		historico_familiar: "Historia familiar",
			  	}
			  },
		
			  {
			  	pt: {
			  		encaminhamento: "Encaminhamento",
			  	},
			  	en: {
			  		encaminhamento: "Forwarding",
			  	},
			  	es: {
			  		encaminhamento: "Reenvío",
			  	}
			  },
		
			  {
			  	pt: {
			  		atividade_exercidas: "Atividades Exercidas",
			  	},
			  	en: {
			  		atividade_exercidas: "Activities performed",
			  	},
			  	es: {
			  		atividade_exercidas: "Actividades realizadas",
			  	}
			  },
		
			  {
			  	pt: {
			  		history_patient: "Histórico do Paciente",
			  	},
			  	en: {
			  		history_patient: "Patient History",
			  	},
			  	es: {
			  		history_patient: "Historial del paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		deseja_emitir_e_ou: "Quais dados deseja considerar para os relatórios Multifatorial e / ou Fisioterapêutico",
			  	},
			  	en: {
			  		deseja_emitir_e_ou: "Data Considered for Multifactorial / or Physiotherapeutic Reports",
			  	},
			  	es: {
			  		deseja_emitir_e_ou: "Datos considerados para informes multifactoriales / o fisioterapéuticos",
			  	}
			  },
		
			  {
			  	pt: {
			  		delete_sucess_anotation: "Anotação excluída com sucesso!",
			  	},
			  	en: {
			  		delete_sucess_anotation: "Note purchased successfully!",
			  	},
			  	es: {
			  		delete_sucess_anotation: "¡Nota comprada con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		sf_andamento33: " Ao iniciar um novo SF-36, os dados dos que já estão em andamento serão deletados.",
			  	},
			  	en: {
			  		sf_andamento33: "When starting a new SF-36, the data of those already in progress will be deleted.",
			  	},
			  	es: {
			  		sf_andamento33: "Al iniciar un nuevo SF-36, se borrarán los datos de los que ya están en curso.",
			  	}
			  },
		
			  {
			  	pt: {
			  		sf_andamento22: "Verificamos que já existe um SF-36 iniciado, deseja gerar um novo ou continuar o que já está em andamento ?",
			  	},
			  	en: {
			  		sf_andamento22: "We have verified that there is already an SF-36 started, do you want to generate a new one or continue the one that is already in progress?",
			  	},
			  	es: {
			  		sf_andamento22: "Hemos comprobado que ya hay un SF-36 iniciado, ¿quieres generar uno nuevo o continuar con el que ya está en marcha?",
			  	}
			  },
		
			  {
			  	pt: {
			  		sf_andamento1: "SF-36 em andamento",
			  	},
			  	en: {
			  		sf_andamento1: "SF-36 in progress",
			  	},
			  	es: {
			  		sf_andamento1: "SF-36 en progreso",
			  	}
			  },
		
			  {
			  	pt: {
			  		gerar_novo: "Gerar/continuar",
			  	},
			  	en: {
			  		gerar_novo: "Generate/continue",
			  	},
			  	es: {
			  		gerar_novo: "Generar/continuar",
			  	}
			  },
		
			  {
			  	pt: {
			  		veja_oque_falta: "Veja o que falta para visualizar o relatório",
			  	},
			  	en: {
			  		veja_oque_falta: "See what is missing to view the report",
			  	},
			  	es: {
			  		veja_oque_falta: "Ver lo que falta para ver el informe",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao_ha_dados: "Não há dados para exibir",
			  	},
			  	en: {
			  		nao_ha_dados: "No data to display",
			  	},
			  	es: {
			  		nao_ha_dados: "No hay información para mostrar",
			  	}
			  },
		
			  {
			  	pt: {
			  		fisioterapeutico_explicacao: "Compila as informações conseguidas nos questionários SF36 e de Anamnese com foco em seu desenvolvimento fisioterapêutico.",
			  	},
			  	en: {
			  		fisioterapeutico_explicacao: "Compiles the information obtained from the SF36 and Anamnesis with focus on their physiotherapeutic development.",
			  	},
			  	es: {
			  		fisioterapeutico_explicacao: "Recopila la información obtenida de los cuestionarios SF36 y Anamnesis  con un enfoque en su desarrollo fisioterapéutico.",
			  	}
			  },
		
			  {
			  	pt: {
			  		multifatorial_explicacao: "Compila as informações conseguidas nos questionários SF36 e de Anamnese.",
			  	},
			  	en: {
			  		multifatorial_explicacao: "Compiles the information obtained from the SF36 and Anamnesis",
			  	},
			  	es: {
			  		multifatorial_explicacao: "Recopila la información obtenida de los cuestionarios SF36 y Anamnesis.",
			  	}
			  },
		
			  {
			  	pt: {
			  		problema_sugestao_salvo: "Recebemos seu Problema/Sugestão",
			  	},
			  	en: {
			  		problema_sugestao_salvo: "We have received your Problem/Suggestion",
			  	},
			  	es: {
			  		problema_sugestao_salvo: "Hemos recibido su Problema/Sugerencia",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_esqueci_senha_subtitulo: "Inserir o email do cadastro para redefinir a senha",
			  	},
			  	en: {
			  		login_esqueci_senha_subtitulo: "Insert registered email to change password",
			  	},
			  	es: {
			  		login_esqueci_senha_subtitulo: "Introducir el email registrado para redefinir la contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		anamnese_opcoes: "Selecione as opções da anamnese",
			  	},
			  	en: {
			  		anamnese_opcoes: "Select anamnesis options",
			  	},
			  	es: {
			  		anamnese_opcoes: " Seleccionar opciones de anamnesis",
			  	}
			  },
			  {
				pt: {
					cadastre_seu_cartao: "Estamos mudando nosso sistema financeiro para melhor atendê-lo. Por isso solicitamos que atualize, antes do próximo vencimento, seu cartão de crédito no sistema para manter seus pagamentos em dias.",
				},
				en: {
					cadastre_seu_cartao: "We are changing our financial system to better serve you. Therefore, we ask that you update your credit card in the system before the next due date to keep your payments up to date.",
				},
				es: {
					cadastre_seu_cartao: "Estamos cambiando nuestro sistema financiero para servirle mejor. Por lo tanto, le solicitamos que actualice su tarjeta de crédito en el sistema antes de la próxima fecha de vencimiento para mantener sus pagos al día.",
				}
			  },
			  {
				pt: {
					cadastrar: "Cadastrar",
				},
				en: {
					cadastrar: "Register",
				},
				es: {
					cadastrar: "Registrar",
				}
			  },
			  {
				pt: {
					entendi: "Ok, entendi",
				},
				en: {
					entendi: "Ok, I got it",
				},
				es: {
					entendi: "OK entendí",
				}
			  },
			  {
				pt: {
					cadastrar_cartao: "Cadastrar Cartão",
				},
				en: {
					cadastrar_cartao: "Register Card",
				},
				es: {
					cadastrar_cartao: "Tarjeta de registro",
				}
			  },
		
			  {
			  	pt: {
			  		anamnese_avaliacao: "Anamnese Avaliação",
			  	},
			  	en: {
			  		anamnese_avaliacao: "Anamnesis Evaluation",
			  	},
			  	es: {
			  		anamnese_avaliacao: " Evaluación de Anamnesis",
			  	}
			  },
		
			  {
			  	pt: {
			  		btn_fisioterapeutico: "Fisioterapêutico",
			  	},
			  	en: {
			  		btn_fisioterapeutico: "Physiotherapeutic",
			  	},
			  	es: {
			  		btn_fisioterapeutico: " Fisioterapéutico",
			  	}
			  },
		
			  {
			  	pt: {
			  		btn_multifatorial: "Multifatorial",
			  	},
			  	en: {
			  		btn_multifatorial: "Multifactorial",
			  	},
			  	es: {
			  		btn_multifatorial: "Multifactorial",
			  	}
			  },
		
			  {
			  	pt: {
			  		btn_assimetria: "Assimetria",
			  	},
			  	en: {
			  		btn_assimetria: " Asymmetry",
			  	},
			  	es: {
			  		btn_assimetria: " Asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		btn_evolucao: "Evolução",
			  	},
			  	en: {
			  		btn_evolucao: "Evolution",
			  	},
			  	es: {
			  		btn_evolucao: "Evolución",
			  	}
			  },
		
			  {
			  	pt: {
			  		btn_forca_tempo: "Força x Tempo",
			  	},
			  	en: {
			  		btn_forca_tempo: "Force x Time",
			  	},
			  	es: {
			  		btn_forca_tempo: "Fuerza x Tiempo",
			  	}
			  },
		
			  {
			  	pt: {
			  		maior_que_10mb: "Arquivo maior que 10 MB",
			  	},
			  	en: {
			  		maior_que_10mb: "File larger than 10 MB",
			  	},
			  	es: {
			  		maior_que_10mb: "Archivo mayor que 10 MB",
			  	}
			  },
		
			  {
			  	pt: {
			  		n_conselho: "N° do Conselho",
			  	},
			  	en: {
			  		n_conselho: "Council No.",
			  	},
			  	es: {
			  		n_conselho: "N° de Consejo",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhum_paciente_excluido: "Nenhum paciente excluído",
			  	},
			  	en: {
			  		nenhum_paciente_excluido: "No patient excluded",
			  	},
			  	es: {
			  		nenhum_paciente_excluido: "Ningún paciente excluido",
			  	}
			  },
		
			  {
			  	pt: {
			  		assinatura: "Assinatura",
			  	},
			  	en: {
			  		assinatura: "Signature",
			  	},
			  	es: {
			  		assinatura: "Firma",
			  	}
			  },
		
			  {
			  	pt: {
			  		enviar_assinatura: "enviar assinatura",
			  	},
			  	en: {
			  		enviar_assinatura: "send signature",
			  	},
			  	es: {
			  		enviar_assinatura: "enviar firma",
			  	}
			  },
		
			  {
			  	pt: {
			  		adicionar_assinatura: "Adicionar Assinatura",
			  	},
			  	en: {
			  		adicionar_assinatura: "Add Signature",
			  	},
			  	es: {
			  		adicionar_assinatura: "Adicionar Firma",
			  	}
			  },
		
			  {
			  	pt: {
			  		realisar_avaliacao_fisica: "Gerar Avaliação Física",
			  	},
			  	en: {
			  		realisar_avaliacao_fisica: "Generate Physical Avaliation",
			  	},
			  	es: {
			  		realisar_avaliacao_fisica: "Generar Evaluación Física",
			  	}
			  },
		
			  {
			  	pt: {
			  		no_encontrado_avaliador: "Opa, nenhum avaliador com esse nome!",
			  	},
			  	en: {
			  		no_encontrado_avaliador: "Oops, no evaluator with that name!",
			  	},
			  	es: {
			  		no_encontrado_avaliador: "¡Vaya, no hay evaluadores con ese nombre!",
			  	}
			  },
		
			  {
			  	pt: {
			  		somente_esse_mes: "Somente esse mês",
			  	},
			  	en: {
			  		somente_esse_mes: "This month only",
			  	},
			  	es: {
			  		somente_esse_mes: "Sólo este mes",
			  	}
			  },
		
			  {
			  	pt: {
			  		multi_fisio: "Multifatorial e Fisioterapêutico",
			  	},
			  	en: {
			  		multi_fisio: "Multifactorial and Physical Therapy",
			  	},
			  	es: {
			  		multi_fisio: "Multifactorial y Fisioterapia",
			  	}
			  },
		
			  {
			  	pt: {
			  		emissoes_ilimitadas: "Emissões ilimitadas de relatórios",
			  	},
			  	en: {
			  		emissoes_ilimitadas: "Unlimited report output",
			  	},
			  	es: {
			  		emissoes_ilimitadas: "Emisión ilimitada de informes",
			  	}
			  },
		
			  {
			  	pt: {
			  		limit_caractes: "O limite de caracteres é de ",
			  	},
			  	en: {
			  		limit_caractes: "The character limit is",
			  	},
			  	es: {
			  		limit_caractes: "El límite de caracteres es",
			  	}
			  },
		
			  {
			  	pt: {
			  		error_empty_questionnaire: "Erro ao buscar questionário.",
			  	},
			  	en: {
			  		error_empty_questionnaire: "Error fetching questionnaire.",
			  	},
			  	es: {
			  		error_empty_questionnaire: "Error al obtener el cuestionario.",
			  	}
			  },
		
			  {
			  	pt: {
			  		assinatura_sucess: "Sua assinatura foi salva com sucesso!",
			  	},
			  	en: {
			  		assinatura_sucess: "Your subscription has been successfully saved!",
			  	},
			  	es: {
			  		assinatura_sucess: "¡Tu suscripción ha sido guardada con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		conselho: "Conselho",
			  	},
			  	en: {
			  		conselho: "Council",
			  	},
			  	es: {
			  		conselho: "Consejo",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_uma_opcao_ass: "Escolha uma das opções",
			  	},
			  	en: {
			  		escolha_uma_opcao_ass: "Choose one of the options",
			  	},
			  	es: {
			  		escolha_uma_opcao_ass: "Elige una de las opciones",
			  	}
			  },
		
			  {
			  	pt: {
			  		preview_ass: "Pré-visualização",
			  	},
			  	en: {
			  		preview_ass: "Preview",
			  	},
			  	es: {
			  		preview_ass: "Avance",
			  	}
			  },
		
			  {
			  	pt: {
			  		envie_uma_foto_ass: "Envie uma foto da sua assinatura em fundo branco",
			  	},
			  	en: {
			  		envie_uma_foto_ass: "Upload a photo of your signature on a white background",
			  	},
			  	es: {
			  		envie_uma_foto_ass: "Sube una foto de tu firma sobre fondo blanco",
			  	}
			  },
		
			  {
			  	pt: {
			  		questionnaire_completed_02: "Seu questionário foi salvo com sucesso!",
			  	},
			  	en: {
			  		questionnaire_completed_02: "Your quiz has been saved successfully!",
			  	},
			  	es: {
			  		questionnaire_completed_02: "¡Tu cuestionario se ha guardado con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		questionnaire_completed: "Questionário Concluído!",
			  	},
			  	en: {
			  		questionnaire_completed: "Quiz Completed!",
			  	},
			  	es: {
			  		questionnaire_completed: "Cuestionario completado!",
			  	}
			  },
		
			  {
			  	pt: {
			  		anamnese_em_andamento_app_p1: "Verificamos que já existe uma anamnese iniciada, deseja gerar uma nova ou continuar a que já está em andamento ?",
			  	},
			  	en: {
			  		anamnese_em_andamento_app_p1: "We verified that an anamnesis already exists, do you want to generate a new one or continue the one that is already in progress?",
			  	},
			  	es: {
			  		anamnese_em_andamento_app_p1: "Hemos comprobado que ya existe una anamnesis, ¿quieres generar una nueva o continuar con la que ya está en curso?",
			  	}
			  },
		
			  {
			  	pt: {
			  		realizada_em: "Realizada em",
			  	},
			  	en: {
			  		realizada_em: "Held in",
			  	},
			  	es: {
			  		realizada_em: "Celebrado en",
			  	}
			  },
		
			  {
			  	pt: {
			  		sf_36_question: "Questionário SF-36 automatizado",
			  	},
			  	en: {
			  		sf_36_question: "Automated SF-36 questionnaire",
			  	},
			  	es: {
			  		sf_36_question: "Cuestionario SF-36 automatizado",
			  	}
			  },
		
			  {
			  	pt: {
			  		adicionar_campo: "Adicionar campo",
			  	},
			  	en: {
			  		adicionar_campo: "Add field",
			  	},
			  	es: {
			  		adicionar_campo: "Agregue campo",
			  	}
			  },
		
			  {
			  	pt: {
			  		execucao_migrada: "A execução migrada",
			  	},
			  	en: {
			  		execucao_migrada: "Execution migrated",
			  	},
			  	es: {
			  		execucao_migrada: "Ejecución migrada",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_paci_migrar: "Escolha o paciente que desejar migrar as execuções",
			  	},
			  	en: {
			  		escolha_paci_migrar: "Choose the patient you want to migrate executions ",
			  	},
			  	es: {
			  		escolha_paci_migrar: "Elija el paciente para el que desea migrar las ejecuciones",
			  	}
			  },
		
			  {
			  	pt: {
			  		migrar: "Migrar",
			  	},
			  	en: {
			  		migrar: "Migrate",
			  	},
			  	es: {
			  		migrar: "Migrar",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_resultado: "Escolha o resultado que deseja excluir ou migrar dos relatórios:",
			  	},
			  	en: {
			  		escolha_resultado: "Choose the result you want to delete or migrate from the reports:",
			  	},
			  	es: {
			  		escolha_resultado: "Elija el resultado que desea eliminar o migrar de los informes:",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluir_execucao_pc: "Excluir ou migrar execução do paciente",
			  	},
			  	en: {
			  		excluir_execucao_pc: "Delete or migrate patient run",
			  	},
			  	es: {
			  		excluir_execucao_pc: "Borrar o migrar la ejecución del paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		preencha_todos_campos: "Preencha todos os campos",
			  	},
			  	en: {
			  		preencha_todos_campos: "Fill in all fields",
			  	},
			  	es: {
			  		preencha_todos_campos: "Rellene todos los campos",
			  	}
			  },
		
			  {
			  	pt: {
			  		realizado_em: "Realizado em",
			  	},
			  	en: {
			  		realizado_em: "Held in",
			  	},
			  	es: {
			  		realizado_em: "Efectuado en",
			  	}
			  },
		
			  {
			  	pt: {
			  		em_andamento: "Em andamento",
			  	},
			  	en: {
			  		em_andamento: "In progress",
			  	},
			  	es: {
			  		em_andamento: "En proceso",
			  	}
			  },
		
			  {
			  	pt: {
			  		emitir_anamnese: "Emitir anamnese",
			  	},
			  	en: {
			  		emitir_anamnese: "Issue anamnesis",
			  	},
			  	es: {
			  		emitir_anamnese: "emitir anamnesis",
			  	}
			  },
		
			  {
			  	pt: {
			  		estetica: "Estética",
			  	},
			  	en: {
			  		estetica: "Esthetic",
			  	},
			  	es: {
			  		estetica: "Estético",
			  	}
			  },
		
			  {
			  	pt: {
			  		esportiva: "Esportiva",
			  	},
			  	en: {
			  		esportiva: "Sporting",
			  	},
			  	es: {
			  		esportiva: "Atlética",
			  	}
			  },
		
			  {
			  	pt: {
			  		anamnese_andamento_p2: "Ao iniciar uma nova anamnese, os dados das que já estão em andamento serão deletados.",
			  	},
			  	en: {
			  		anamnese_andamento_p2: "When starting a new anamnesis, the data of those already in progress will be deleted.",
			  	},
			  	es: {
			  		anamnese_andamento_p2: "Al iniciar una nueva anamnesis, se borrarán los datos que ya están en curso.",
			  	}
			  },
		
			  {
			  	pt: {
			  		anamnese_andamento_p1: "Verificamos que já existe uma anamnese iniciada, deseja gerar uma nova ou continuar a que já está em andamento.",
			  	},
			  	en: {
			  		anamnese_andamento_p1: "We verified that an anamnesis already exists, you want to generate a new one or continue the one that is already in progress.",
			  	},
			  	es: {
			  		anamnese_andamento_p1: "Verificamos que existe una anamnesis, desea generar una nueva o continuar con la que ya está en curso.",
			  	}
			  },
		
			  {
			  	pt: {
			  		anamnese_andamento: "Anamnese em andamento",
			  	},
			  	en: {
			  		anamnese_andamento: "Anamnesis in progress",
			  	},
			  	es: {
			  		anamnese_andamento: "Anamnesis en curso",
			  	}
			  },
		
			  {
			  	pt: {
			  		cuidado: "Cuidado!",
			  	},
			  	en: {
			  		cuidado: "Caution!",
			  	},
			  	es: {
			  		cuidado: "¡Cuidado!",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluir_dor: "Deseja realmente excluir os pontos de dor marcados?",
			  	},
			  	en: {
			  		excluir_dor: "Do you really want to delete marked pain points?",
			  	},
			  	es: {
			  		excluir_dor: "¿Realmente quieres eliminar los puntos débiles marcados?",
			  	}
			  },
		
			  {
			  	pt: {
			  		max_dez_mb: "Max. 10mb/imagem",
			  	},
			  	en: {
			  		max_dez_mb: "Max. 10mb/image",
			  	},
			  	es: {
			  		max_dez_mb: "Max. 10mb/imagen",
			  	}
			  },
		
			  {
			  	pt: {
			  		dez_imagens: "Limite de 10 imagens",
			  	},
			  	en: {
			  		dez_imagens: "Limit of 10 images",
			  	},
			  	es: {
			  		dez_imagens: "Límite de 10 imágenes",
			  	}
			  },
		
			  {
			  	pt: {
			  		proximo_button: "Próximo",
			  	},
			  	en: {
			  		proximo_button: " Next",
			  	},
			  	es: {
			  		proximo_button: "Próximo",
			  	}
			  },
		
			  {
			  	pt: {
			  		new_anamnese: "Gerar Anamnese",
			  	},
			  	en: {
			  		new_anamnese: "Generate Anamnesis",
			  	},
			  	es: {
			  		new_anamnese: "Generar Anamnesis",
			  	}
			  },
		
			  {
			  	pt: {
			  		enviar_sf36: "Enviar o Questionário SF-36 para ser respondido diretamente pelo paciente de forma assíncrona",
			  	},
			  	en: {
			  		enviar_sf36: "Send the SF-36 Questionnaire to be answered directly by the patient asynchronously",
			  	},
			  	es: {
			  		enviar_sf36: "Enviar el cuestionario SF-36 para que el paciente lo responda directamente de forma asíncrona",
			  	}
			  },
		
			  {
			  	pt: {
			  		execute_o_questionario: "Execute o questionário SF-36 com seu",
			  	},
			  	en: {
			  		execute_o_questionario: "Run the SF-36 questionnaire with your",
			  	},
			  	es: {
			  		execute_o_questionario: "Realice el cuestionario SF-36 con su",
			  	}
			  },
		
			  {
			  	pt: {
			  		sem_dados_disponiveis: "Sem dados disponíveis",
			  	},
			  	en: {
			  		sem_dados_disponiveis: "No Data available",
			  	},
			  	es: {
			  		sem_dados_disponiveis: "No hay datos disponibles",
			  	}
			  },
		
			  {
			  	pt: {
			  		questionario: "Questionário",
			  	},
			  	en: {
			  		questionario: "Questionnaire",
			  	},
			  	es: {
			  		questionario: "Cuestionario",
			  	}
			  },
		
			  {
			  	pt: {
			  		vc_recebera: "Você receberá uma notificação assim que o paciente concluir a execução e os resultados serão inclusos no perfil do paciente.",
			  	},
			  	en: {
			  		vc_recebera: "You will receive a notification as soon as the patient has completed the run and the results will be included in the patient Profile.",
			  	},
			  	es: {
			  		vc_recebera: "Recibirá una notificación una vez que el paciente haya completado la prueba y los resultados se incluirán en el perfil del paciente.",
			  	}
			  },
		
			  {
			  	pt: {
			  		insira_email_paciente: "Insira aqui o e-mail do seu paciente",
			  	},
			  	en: {
			  		insira_email_paciente: "Enter your patient e-mail address here",
			  	},
			  	es: {
			  		insira_email_paciente: "Introduzca aquí e-mail de su paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		fazer_com_o_paciente: "Fazer com o(a) paciente",
			  	},
			  	en: {
			  		fazer_com_o_paciente: "Do with the patient",
			  	},
			  	es: {
			  		fazer_com_o_paciente: "Hacer con el paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		enviar_anamnese: "Enviar a anamnese para ser respondida diretamente pelo paciente de forma assíncrona.",
			  	},
			  	en: {
			  		enviar_anamnese: "Send the anamnesis to be answered directly by the patient asynchronously. asynchronously.",
			  	},
			  	es: {
			  		enviar_anamnese: "Envíe la anamnesis para que sea respondida directamente por el paciente de forma asíncrona.",
			  	}
			  },
		
			  {
			  	pt: {
			  		reabilitacao: "Reabilitação",
			  	},
			  	en: {
			  		reabilitacao: "Rehabilitation",
			  	},
			  	es: {
			  		reabilitacao: "Rehabilitación",
			  	}
			  },
		
			  {
			  	pt: {
			  		saude: "Saúde",
			  	},
			  	en: {
			  		saude: "Health",
			  	},
			  	es: {
			  		saude: "Salud",
			  	}
			  },
		
			  {
			  	pt: {
			  		questionario_qualidade_vida: "Questionário de qualidade de vida",
			  	},
			  	en: {
			  		questionario_qualidade_vida: "Quality of life questionnaire",
			  	},
			  	es: {
			  		questionario_qualidade_vida: "Cuestionario de calidad de vida",
			  	}
			  },
		
			  {
			  	pt: {
			  		sem_historico_questionario: "Sem histórico de questionário",
			  	},
			  	en: {
			  		sem_historico_questionario: "No questionnaire history",
			  	},
			  	es: {
			  		sem_historico_questionario: "No hay historial de cuestionarios",
			  	}
			  },
		
			  {
			  	pt: {
			  		sem_historico_anamnese: "Sem histórico de Anamnese",
			  	},
			  	en: {
			  		sem_historico_anamnese: "No anamnesis history",
			  	},
			  	es: {
			  		sem_historico_anamnese: "No hay antecedentes de anamnesis",
			  	}
			  },
		
			  {
			  	pt: {
			  		avaliacao_fisica: "avaliação física",
			  	},
			  	en: {
			  		avaliacao_fisica: "physical evaluation",
			  	},
			  	es: {
			  		avaliacao_fisica: "evaluación física",
			  	}
			  },
		
			  {
			  	pt: {
			  		realizar: "Realizar",
			  	},
			  	en: {
			  		realizar: "Realize",
			  	},
			  	es: {
			  		realizar: "Realizar",
			  	}
			  },
		
			  {
			  	pt: {
			  		continuar_anamnese: "Continuar",
			  	},
			  	en: {
			  		continuar_anamnese: "Continue",
			  	},
			  	es: {
			  		continuar_anamnese: "Continuar",
			  	}
			  },
		
			  {
			  	pt: {
			  		gerar_nova: "Gerar nova",
			  	},
			  	en: {
			  		gerar_nova: "Generate new",
			  	},
			  	es: {
			  		gerar_nova: "Generar nuevo",
			  	}
			  },
		
			  {
			  	pt: {
			  		qualidade_de_vida: "Qualidade de vida",
			  	},
			  	en: {
			  		qualidade_de_vida: "Quality of Life",
			  	},
			  	es: {
			  		qualidade_de_vida: "Calidad de vida",
			  	}
			  },
		
			  {
			  	pt: {
			  		ultimo_iq: "Último IQ",
			  	},
			  	en: {
			  		ultimo_iq: "Last IQ",
			  	},
			  	es: {
			  		ultimo_iq: "Último IQ",
			  	}
			  },
		
			  {
			  	pt: {
			  		a_exclusao_n_cancela_plano: "a exclusão não resultará em cancelamento do plano",
			  	},
			  	en: {
			  		a_exclusao_n_cancela_plano: "deletion will not result in plan cancellation",
			  	},
			  	es: {
			  		a_exclusao_n_cancela_plano: "la eliminación no resultará en la cancelación del plan",
			  	}
			  },
		
			  {
			  	pt: {
			  		deseja_excluir_sua_conta: "Deseja excluir sua conta?",
			  	},
			  	en: {
			  		deseja_excluir_sua_conta: " Do you want to delete your account?",
			  	},
			  	es: {
			  		deseja_excluir_sua_conta: "Quieres eliminar tu cuenta?",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluir_conta: "Excluir minha conta",
			  	},
			  	en: {
			  		excluir_conta: " Delete my account",
			  	},
			  	es: {
			  		excluir_conta: " Borrar mi cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_anamnese: "Escolha sua Anamnese",
			  	},
			  	en: {
			  		escolha_anamnese: "Choose your Anamnesis",
			  	},
			  	es: {
			  		escolha_anamnese: "Elige tu Anamnesis",
			  	}
			  },
		
			  {
			  	pt: {
			  		quais: "Quais?",
			  	},
			  	en: {
			  		quais: "Which?",
			  	},
			  	es: {
			  		quais: "¿Cual?",
			  	}
			  },
		
			  {
			  	pt: {
			  		ultimo_i_q: "Último I/Q",
			  	},
			  	en: {
			  		ultimo_i_q: "Last I/Q",
			  	},
			  	es: {
			  		ultimo_i_q: " Último I/Q",
			  	}
			  },
		
			  {
			  	pt: {
			  		imc: "IMC",
			  	},
			  	en: {
			  		imc: "BMI",
			  	},
			  	es: {
			  		imc: "IMC",
			  	}
			  },
		
			  {
			  	pt: {
			  		historico_quiz_sf_36: "Histórico de Questionários SF-36",
			  	},
			  	en: {
			  		historico_quiz_sf_36: "History of SF-36 Questionnaires",
			  	},
			  	es: {
			  		historico_quiz_sf_36: "Historia de los cuestionarios SF-36",
			  	}
			  },
		
			  {
			  	pt: {
			  		quiz_sf_36: "Questionário SF-36",
			  	},
			  	en: {
			  		quiz_sf_36: "SF-36 Questionnaire",
			  	},
			  	es: {
			  		quiz_sf_36: "Cuestionario SF-36",
			  	}
			  },
		
			  {
			  	pt: {
			  		new_quiz: "Gerar um novo questionário",
			  	},
			  	en: {
			  		new_quiz: " Generate a new questionnaire",
			  	},
			  	es: {
			  		new_quiz: "Generar una nueva cuestionario",
			  	}
			  },
		
			  {
			  	pt: {
			  		continue_quiz: "Continuar questionário salvo",
			  	},
			  	en: {
			  		continue_quiz: "Continue Saved Questionnaire",
			  	},
			  	es: {
			  		continue_quiz: "Continuar cuestionario guardado",
			  	}
			  },
		
			  {
			  	pt: {
			  		historico_anamnese: "Histórico de Anamneses",
			  	},
			  	en: {
			  		historico_anamnese: "History of Anamnesis",
			  	},
			  	es: {
			  		historico_anamnese: "Historia de la Anamnesis",
			  	}
			  },
		
			  {
			  	pt: {
			  		continue_anaminese: "Continuar Anamnese",
			  	},
			  	en: {
			  		continue_anaminese: "Continue Anamnesis",
			  	},
			  	es: {
			  		continue_anaminese: "Continuar Anamnesis",
			  	}
			  },
		
			  {
			  	pt: {
			  		anamnese: "Anamnese",
			  	},
			  	en: {
			  		anamnese: "Anamnesis",
			  	},
			  	es: {
			  		anamnese: "Anamnesia",
			  	}
			  },
		
			  {
			  	pt: {
			  		alert_inadim_subTitle: "Atualize os dados de pagamento para voltar a utilizar as funções exclusivas.",
			  	},
			  	en: {
			  		alert_inadim_subTitle: "Update payment details to use exclusive functions again.",
			  	},
			  	es: {
			  		alert_inadim_subTitle: "Actualice los detalles de pago para usar funciones exclusivas nuevamente.",
			  	}
			  },
		
			  {
			  	pt: {
			  		alert_inadim_title: "Ops... Houve um problema!",
			  	},
			  	en: {
			  		alert_inadim_title: "Oops... There was a problem!",
			  	},
			  	es: {
			  		alert_inadim_title: "Ups... ¡Hubo un problema!",
			  	}
			  },
		
			  {
			  	pt: {
			  		valores_refes: "* Valor(es) de referência de mediana de força máxima em avaliação 							isométrica para paciente do sexo masculino dentro da faixa etária 							de 20 a 24 anos (MELDRUM, 2007) e para paciente do sexo masculino 							e feminino dentro da faixa etária de 20 a 39 anos (PASCO, 2020).",
			  	},
			  	en: {
			  		valores_refes: " * Maximum strength median reference value(s) under evaluation isometric for male patient within age group from 20 to 24 years old (MELDRUM, 2007) and for a male patient and female within the age group of 20 to 39 years (PASCO, 2020).",
			  	},
			  	es: {
			  		valores_refes: "* Valor(es) de referencia de la mediana de la resistencia máxima en evaluación isométrico para paciente masculino dentro del grupo de edad de 20 a 24 años (MELDRUM, 2007) y para un paciente masculino y femenino dentro del grupo de edad de 20 a 39 años (PASCO, 2020).",
			  	}
			  },
		
			  {
			  	pt: {
			  		dino_foi_desconectado: "O dinamômetro foi desconectado",
			  	},
			  	en: {
			  		dino_foi_desconectado: "The dynamometer has been disconnected",
			  	},
			  	es: {
			  		dino_foi_desconectado: "El dinamómetro ha sido desconectado.",
			  	}
			  },
		
			  {
			  	pt: {
			  		dino_desconectado: "Dinamô desconectado",
			  	},
			  	en: {
			  		dino_desconectado: "Dynamo disconnected",
			  	},
			  	es: {
			  		dino_desconectado: "Dínamo desconectado",
			  	}
			  },
		
			  {
			  	pt: {
			  		dinamo_esta_conectado: "Dinamômetro está conectado ao seu dispositivo",
			  	},
			  	en: {
			  		dinamo_esta_conectado: "Dynamometer is connected to your device",
			  	},
			  	es: {
			  		dinamo_esta_conectado: "El dinamómetro está conectado a su dispositivo",
			  	}
			  },
		
			  {
			  	pt: {
			  		dinamo_conectado: "Dinamô conectado",
			  	},
			  	en: {
			  		dinamo_conectado: "Connected dinamô",
			  	},
			  	es: {
			  		dinamo_conectado: "dinamo conectado",
			  	}
			  },
		
			  {
			  	pt: {
			  		yes: "Sim",
			  	},
			  	en: {
			  		yes: "Yes",
			  	},
			  	es: {
			  		yes: "Sí",
			  	}
			  },
		
			  {
			  	pt: {
			  		not: "Não",
			  	},
			  	en: {
			  		not: "No",
			  	},
			  	es: {
			  		not: "No",
			  	}
			  },
		
			  {
			  	pt: {
			  		email_deve_corresponder: "Os e-mails devem corresponder",
			  	},
			  	en: {
			  		email_deve_corresponder: "Emails must match",
			  	},
			  	es: {
			  		email_deve_corresponder: "Los e-mails deben coincidir",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhum_exercicio_selecionado: "Nenhum exercício para selecionar",
			  	},
			  	en: {
			  		nenhum_exercicio_selecionado: "No exercises to select",
			  	},
			  	es: {
			  		nenhum_exercicio_selecionado: "No hay ejercicios para seleccionar",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_upgrade_descricao: "Mas calma, para resolver isto é simples. Basta entrar em contato com a gente e solicitar a mudança no seu pacote de serviços.",
			  	},
			  	en: {
			  		modal_upgrade_descricao: "But calm down, to solve this is simple. Just contact us and request a change in your service package.",
			  	},
			  	es: {
			  		modal_upgrade_descricao: "Pero tranquilos, solucionar esto es sencillo. Solo contáctenos y solicite un cambio en su paquete de servicios.",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_upgrade_funcionalidade: "Você ainda não tem acesso a essa funcionalidade",
			  	},
			  	en: {
			  		modal_upgrade_funcionalidade: "You do not yet have access to this functionality",
			  	},
			  	es: {
			  		modal_upgrade_funcionalidade: "Todavía no tienes acceso a esta funcionalidad",
			  	}
			  },
		
			  {
			  	pt: {
			  		descricao_permissao_armazenamento: "Utilizamos essa permissão para armazenar os dados dos exercícios",
			  	},
			  	en: {
			  		descricao_permissao_armazenamento: "We use this permission to store exercise data",
			  	},
			  	es: {
			  		descricao_permissao_armazenamento: "Usamos este permiso para almacenar datos de ejercicio.",
			  	}
			  },
		
			  {
			  	pt: {
			  		descricao_permissao_notificacoes: "Fique por dentro de todas as novidades e atualizações da Kinology",
			  	},
			  	en: {
			  		descricao_permissao_notificacoes: "Stay up to date with all the news and updates from Kinology",
			  	},
			  	es: {
			  		descricao_permissao_notificacoes: "Manténgase al día con todas las noticias y actualizaciones de Kinology",
			  	}
			  },
		
			  {
			  	pt: {
			  		descricao_permissao_camera: "Utilize a câmera para personalizar os perfis dos pacientes e fisioterapeutas",
			  	},
			  	en: {
			  		descricao_permissao_camera: "Use the camera to customize the profiles of patients and physical therapists",
			  	},
			  	es: {
			  		descricao_permissao_camera: "Utilice una cámara para personalizar o perfis dos pacientes e fisioterapeutas",
			  	}
			  },
		
			  {
			  	pt: {
			  		descricao_permissao_ble: "Sincronizamos o smartphone com o dinamô através do bluetooth",
			  	},
			  	en: {
			  		descricao_permissao_ble: "We synchronize the smartphone with the dinamô through bluetooth",
			  	},
			  	es: {
			  		descricao_permissao_ble: "Sincronizamos el smartphone con la dinamo a través de bluetooth",
			  	}
			  },
		
			  {
			  	pt: {
			  		descricao_permissao_localizacao: "Utilizamos a geolocalização para sincronizar com o dinamô",
			  	},
			  	en: {
			  		descricao_permissao_localizacao: "We use geolocation to sync with dynamo",
			  	},
			  	es: {
			  		descricao_permissao_localizacao: "Usamos geolocalización para sincronizar con dinamo",
			  	}
			  },
		
			  {
			  	pt: {
			  		armazenamento: "Armazenamento",
			  	},
			  	en: {
			  		armazenamento: "Storage",
			  	},
			  	es: {
			  		armazenamento: "Almacenamiento",
			  	}
			  },
		
			  {
			  	pt: {
			  		notificacoes: "Notificações",
			  	},
			  	en: {
			  		notificacoes: "Notifications",
			  	},
			  	es: {
			  		notificacoes: "Notificaciones",
			  	}
			  },
		
			  {
			  	pt: {
			  		habilitar_permissoes: "É necessário conceder as permissões de acesso para prosseguir.",
			  	},
			  	en: {
			  		habilitar_permissoes: "You must grant access permissions to proceed.",
			  	},
			  	es: {
			  		habilitar_permissoes: "Debe otorgar permisos de acceso para continuar.",
			  	}
			  },
		
			  {
			  	pt: {
			  		habilitar: "Conceder permissões",
			  	},
			  	en: {
			  		habilitar: "Grant permissions",
			  	},
			  	es: {
			  		habilitar: "Otorgar permisos",
			  	}
			  },
		
			  {
			  	pt: {
			  		aviso_exercicio_funcional: "Exercícios realizados em modo funcional não são contabilizados no relatório de assimetria",
			  	},
			  	en: {
			  		aviso_exercicio_funcional: "Exercises performed in functional mode are not counted in the asymmetry report",
			  	},
			  	es: {
			  		aviso_exercicio_funcional: "Los ejercicios realizados en modo funcional no se contabilizan en el informe de asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		basta_pedir_adm: "Mas calma, pra resolver isto é simples. Basta falarAdministrador da sua conta para realizar um Upgrade.",
			  	},
			  	en: {
			  		basta_pedir_adm: "But calm down, to solve this is simple. just talk to the Administrator of your account to perform an Upgrade.",
			  	},
			  	es: {
			  		basta_pedir_adm: "Pero tranquilos, solucionar esto es sencillo. solo habla con el Administrador de su cuenta para realizar un Upgrade.",
			  	}
			  },
		
			  {
			  	pt: {
			  		antes_continuar_verifique_ble: "Antes de continuar, verifique se seu bluetooth está ativado",
			  	},
			  	en: {
			  		antes_continuar_verifique_ble: "Before proceeding, make sure your bluetooth is turned on",
			  	},
			  	es: {
			  		antes_continuar_verifique_ble: "Antes de continuar, asegúrese de que su bluetooth esté encendido",
			  	}
			  },
		
			  {
			  	pt: {
			  		basta_realizar_upgrade: "Mas calma, pra resolver isto é simples. Basta realizar um UPGRADE no seu plano.",
			  	},
			  	en: {
			  		basta_realizar_upgrade: "But calm down, to solve this is simple. Just perform an UPGRADE on your plan.",
			  	},
			  	es: {
			  		basta_realizar_upgrade: "Pero tranquilos, solucionar esto es sencillo. Simplemente realice una ACTUALIZACIÓN en su plan.",
			  	}
			  },
		
			  {
			  	pt: {
			  		error_assinatura: "Erro ao tentar realizar assinatura!",
			  	},
			  	en: {
			  		error_assinatura: "Error trying to subscribe!",
			  	},
			  	es: {
			  		error_assinatura: "¡Error al intentar suscribirse!",
			  	}
			  },
		
			  {
			  	pt: {
			  		assinatura_realizada: "Assinatura realizada com sucesso!",
			  	},
			  	en: {
			  		assinatura_realizada: "Subscription successfully completed!",
			  	},
			  	es: {
			  		assinatura_realizada: "¡Suscripción completada con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		falha_faturas: "Falha de faturas pendentes.",
			  	},
			  	en: {
			  		falha_faturas: "Failed outstanding invoices.",
			  	},
			  	es: {
			  		falha_faturas: "Facturas pendientes fallidas.",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_iugu: "Falha ao buscar código Iugu",
			  	},
			  	en: {
			  		erro_iugu: "Failed to fetch Iugu code",
			  	},
			  	es: {
			  		erro_iugu: "Error al obtener el código Iugu",
			  	}
			  },
		
			  {
			  	pt: {
			  		concordo_com_disposicoes: "e que concordo com todas as suas disposições.*",
			  	},
			  	en: {
			  		concordo_com_disposicoes: "and that I agree to all of its provisions.*",
			  	},
			  	es: {
			  		concordo_com_disposicoes: "y que estoy de acuerdo con todas sus disposiciones.*",
			  	}
			  },
		
			  {
			  	pt: {
			  		contrato_service: "CONTRATO DE SERVIÇO DA KINOLOGY",
			  	},
			  	en: {
			  		contrato_service: "KINOLOGY SERVICE AGREEMENT",
			  	},
			  	es: {
			  		contrato_service: "ACUERDO DE SERVICIO KINOLOGY",
			  	}
			  },
		
			  {
			  	pt: {
			  		declaro_q_li: "Declaro que li o",
			  	},
			  	en: {
			  		declaro_q_li: "I declare that I have read the",
			  	},
			  	es: {
			  		declaro_q_li: "Declaro que he leído el",
			  	}
			  },
		
			  {
			  	pt: {
			  		ativar_ble: "Ative seu bluetooth para continuar",
			  	},
			  	en: {
			  		ativar_ble: "Activate your bluetooth to continue",
			  	},
			  	es: {
			  		ativar_ble: "Activa tu bluetooth para continuar",
			  	}
			  },
		
			  {
			  	pt: {
			  		antes_continuar_verifique_ble_permissao: "Antes de continuar, verifique se o Kinology tem permissão para utilizar o bluetooth",
			  	},
			  	en: {
			  		antes_continuar_verifique_ble_permissao: "Before proceeding, make sure that Kinology is allowed to use bluetooth",
			  	},
			  	es: {
			  		antes_continuar_verifique_ble_permissao: "Antes de continuar, asegúrese de que Kinology pueda usar bluetooth",
			  	}
			  },
		
			  {
			  	pt: {
			  		antes_continuar_verifique_bluetooth: "Antes de continuar, verifique se seu bluetooth e GPS estão ativados",
			  	},
			  	en: {
			  		antes_continuar_verifique_bluetooth: "Before proceeding, make sure your bluetooth and GPS are turned on",
			  	},
			  	es: {
			  		antes_continuar_verifique_bluetooth: "Antes de continuar, asegúrese de que su bluetooth y GPS estén encendidos",
			  	}
			  },
		
			  {
			  	pt: {
			  		de_permissao_ble: "Dê permissão sobre o bluetooth ao Kinology para continuar",
			  	},
			  	en: {
			  		de_permissao_ble: "Give Kinology bluetooth permission to continue",
			  	},
			  	es: {
			  		de_permissao_ble: "Dar permiso a Kinology bluetooth para continuar",
			  	}
			  },
		
			  {
			  	pt: {
			  		conectado_sucesso_ble: "Conectado com sucesso!",
			  	},
			  	en: {
			  		conectado_sucesso_ble: "Successfully connected!",
			  	},
			  	es: {
			  		conectado_sucesso_ble: "¡Conectado con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		ativar_gps: "Ative seu GPS para continuar",
			  	},
			  	en: {
			  		ativar_gps: "Activate your GPS to continue",
			  	},
			  	es: {
			  		ativar_gps: "Activa tu GPS para continuar",
			  	}
			  },
		
			  {
			  	pt: {
			  		antes_continuar_verifique_gps: "Antes de continuar, verifique se seu GPS está ativado",
			  	},
			  	en: {
			  		antes_continuar_verifique_gps: "Before proceeding, make sure your GPS is turned on",
			  	},
			  	es: {
			  		antes_continuar_verifique_gps: "Antes de continuar, asegúrese de que su GPS esté encendido",
			  	}
			  },
		
			  {
			  	pt: {
			  		ativar_ble_gps: "Ative seu Bluetooth e GPS para continuar",
			  	},
			  	en: {
			  		ativar_ble_gps: "Activate your Bluetooth and GPS to continue",
			  	},
			  	es: {
			  		ativar_ble_gps: "Activa tu Bluetooth y GPS para continuar",
			  	}
			  },
		
			  {
			  	pt: {
			  		ativar_ble_gps_permissao_gps: "Ative seu Bluetooth, GPS e conceda permissão a GPS para continuar",
			  	},
			  	en: {
			  		ativar_ble_gps_permissao_gps: "Activate your Bluetooth, GPS and grant GPS permission to continue",
			  	},
			  	es: {
			  		ativar_ble_gps_permissao_gps: "Active su Bluetooth, GPS y otorgue permiso de GPS para continuar",
			  	}
			  },
		
			  {
			  	pt: {
			  		novidades_em_breve: "Novidades em breve",
			  	},
			  	en: {
			  		novidades_em_breve: "News soon",
			  	},
			  	es: {
			  		novidades_em_breve: "Noticias en breve",
			  	}
			  },
		
			  {
			  	pt: {
			  		busque_um_país: "Busque um país",
			  	},
			  	en: {
			  		busque_um_país: "Search a country",
			  	},
			  	es: {
			  		busque_um_país: "Buscar un país",
			  	}
			  },
		
			  {
			  	pt: {
			  		busque_exercicio: "Busque um exercício",
			  	},
			  	en: {
			  		busque_exercicio: "Find an exercise",
			  	},
			  	es: {
			  		busque_exercicio: "Encuentra un ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_nome_existe: "O nome desse protocolo já existe.",
			  	},
			  	en: {
			  		protocolo_nome_existe: "The name of this protocol already exists.",
			  	},
			  	es: {
			  		protocolo_nome_existe: "El nombre de este protocolo ya existe.",
			  	}
			  },
		
			  {
			  	pt: {
			  		mes: "mês",
			  	},
			  	en: {
			  		mes: "month",
			  	},
			  	es: {
			  		mes: "mes",
			  	}
			  },
		
			  {
			  	pt: {
			  		no_delete_protocol: "Você não pode excluir esse protocolo.",
			  	},
			  	en: {
			  		no_delete_protocol: "You cannot delete this protocol.",
			  	},
			  	es: {
			  		no_delete_protocol: "No puede eliminar este protocolo.",
			  	}
			  },
		
			  {
			  	pt: {
			  		no_edit_protocol: "Você não pode editar esse protocolo.",
			  	},
			  	en: {
			  		no_edit_protocol: "You cannot edit this protocol.",
			  	},
			  	es: {
			  		no_edit_protocol: "No puede editar este protocolo.",
			  	}
			  },
		
			  {
			  	pt: {
			  		confirm_buy: "Confirmar comprar",
			  	},
			  	en: {
			  		confirm_buy: "Confirm buy",
			  	},
			  	es: {
			  		confirm_buy: "Confirmar compra",
			  	}
			  },
		
			  {
			  	pt: {
			  		cred_deb: "Cartão de crédito/débito",
			  	},
			  	en: {
			  		cred_deb: "Credit/Debit card",
			  	},
			  	es: {
			  		cred_deb: "Tarjeta de Crédito / Débito",
			  	}
			  },
		
			  {
			  	pt: {
			  		plano: "Plano",
			  	},
			  	en: {
			  		plano: "Plan",
			  	},
			  	es: {
			  		plano: "Plan",
			  	}
			  },
		
			  {
			  	pt: {
			  		resumo: "Resumo",
			  	},
			  	en: {
			  		resumo: "Summary",
			  	},
			  	es: {
			  		resumo: "Resumen",
			  	}
			  },
		
			  {
			  	pt: {
			  		confirm_dado: "Confirmação de dados",
			  	},
			  	en: {
			  		confirm_dado: "Data confirmation",
			  	},
			  	es: {
			  		confirm_dado: "Confirmación de datos",
			  	}
			  },
		
			  {
			  	pt: {
			  		saia_sub: "Saia já da subjetividade!",
			  	},
			  	en: {
			  		saia_sub: "Get out of subjectivity now!",
			  	},
			  	es: {
			  		saia_sub: "¡Sal de la subjetividad ya!",
			  	}
			  },
		
			  {
			  	pt: {
			  		termos_e_cond: "termos e condições.",
			  	},
			  	en: {
			  		termos_e_cond: "terms and conditions.",
			  	},
			  	es: {
			  		termos_e_cond: "términos y condiciones.",
			  	}
			  },
		
			  {
			  	pt: {
			  		sujeito_a: "Sujeito a",
			  	},
			  	en: {
			  		sujeito_a: "Subject to",
			  	},
			  	es: {
			  		sujeito_a: "Sujeto a",
			  	}
			  },
		
			  {
			  	pt: {
			  		assinar_plano: "Assinar plano",
			  	},
			  	en: {
			  		assinar_plano: "Sign plan",
			  	},
			  	es: {
			  		assinar_plano: "Firmar plano",
			  	}
			  },
		
			  {
			  	pt: {
			  		no_add_exec_net: "Não foi possível excluir a execução, pois o dispositivo não está conectado na internet.",
			  	},
			  	en: {
			  		no_add_exec_net: "Unable to delete run as the device is not connected to the internet.",
			  	},
			  	es: {
			  		no_add_exec_net: "No se puede eliminar la ejecución porque el dispositivo no está conectado a Internet.",
			  	}
			  },
		
			  {
			  	pt: {
			  		proto_edit_success: "Protocolo editado com sucesso!",
			  	},
			  	en: {
			  		proto_edit_success: "Protocol edited successfully!",
			  	},
			  	es: {
			  		proto_edit_success: "¡Protocolo editado con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		info_edit_protocolo: "Altere as informações do protocolo",
			  	},
			  	en: {
			  		info_edit_protocolo: "Change protocol information",
			  	},
			  	es: {
			  		info_edit_protocolo: "Cambiar la información del protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		ops_no_protocol: "Opa, não encontramos nenhum protocolo com esse nome.",
			  	},
			  	en: {
			  		ops_no_protocol: "Oops, we didnt find any protocol with that name.",
			  	},
			  	es: {
			  		ops_no_protocol: "Vaya, no encontramos ningún protocolo con ese nombre.",
			  	}
			  },
		
			  {
			  	pt: {
			  		pesqui_protocol: "Pesquisar protocolo",
			  	},
			  	en: {
			  		pesqui_protocol: "Search protocol",
			  	},
			  	es: {
			  		pesqui_protocol: "Buscar Protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		realizar_up: "Desejo realizar um upgrade.",
			  	},
			  	en: {
			  		realizar_up: "I want to perform an upgrade.",
			  	},
			  	es: {
			  		realizar_up: "Quiero realizar una actualización.",
			  	}
			  },
		
			  {
			  	pt: {
			  		deletar: "Deletar",
			  	},
			  	en: {
			  		deletar: "Delete",
			  	},
			  	es: {
			  		deletar: "Borrar",
			  	}
			  },
		
			  {
			  	pt: {
			  		edit_anotation: "Editar anotação",
			  	},
			  	en: {
			  		edit_anotation: "Edit annotation",
			  	},
			  	es: {
			  		edit_anotation: "Editar anotación",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhuma_execucao_excluida: "Nenhuma execução excluída",
			  	},
			  	en: {
			  		nenhuma_execucao_excluida: "No Execution Deleted",
			  	},
			  	es: {
			  		nenhuma_execucao_excluida: "No se han eliminado execuciones",
			  	}
			  },
		
			  {
			  	pt: {
			  		encontrei_falha: "Encontrei uma falha, estou com um problema ou gostaria de fazer uma sugestão para melhorar o Kinology.",
			  	},
			  	en: {
			  		encontrei_falha: "I found a bug, I have a problem, or I would like to make a suggestion to improve Kinology.",
			  	},
			  	es: {
			  		encontrei_falha: "Encontré un error, tengo un problema o me gustaría hacer una sugerencia para mejorar Kinology.",
			  	}
			  },
		
			  {
			  	pt: {
			  		desciption: "Descrição",
			  	},
			  	en: {
			  		desciption: "Description",
			  	},
			  	es: {
			  		desciption: "Descripción",
			  	}
			  },
		
			  {
			  	pt: {
			  		desativar_demoo: "Desativar modo demo",
			  	},
			  	en: {
			  		desativar_demoo: "Disable demo mode",
			  	},
			  	es: {
			  		desativar_demoo: "Desactivar el modo demo",
			  	}
			  },
		
			  {
			  	pt: {
			  		iniciar_demoo: "Iniciar (Demo)",
			  	},
			  	en: {
			  		iniciar_demoo: "Start (Demo)",
			  	},
			  	es: {
			  		iniciar_demoo: "Iniciar (Demo)",
			  	}
			  },
		
			  {
			  	pt: {
			  		aguarde: "Aguarde",
			  	},
			  	en: {
			  		aguarde: "Wait",
			  	},
			  	es: {
			  		aguarde: "Esperar",
			  	}
			  },
		
			  {
			  	pt: {
			  		options_sexo_fem: "Feminino",
			  	},
			  	en: {
			  		options_sexo_fem: "Feminine",
			  	},
			  	es: {
			  		options_sexo_fem: "Femenino",
			  	}
			  },
		
			  {
			  	pt: {
			  		numero_tel_invalido: "Número  inválido",
			  	},
			  	en: {
			  		numero_tel_invalido: "Invalid number",
			  	},
			  	es: {
			  		numero_tel_invalido: "Número invalido",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_paci_acessar: "Escolha o perfil que deseja acessar",
			  	},
			  	en: {
			  		escolha_paci_acessar: "Choose the profile you want to access",
			  	},
			  	es: {
			  		escolha_paci_acessar: "Elija el perfil al que desea acceder",
			  	}
			  },
		
			  {
			  	pt: {
			  		executar_home: "Executar",
			  	},
			  	en: {
			  		executar_home: "Run",
			  	},
			  	es: {
			  		executar_home: "Correr",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercise_home: "Exercício ",
			  	},
			  	en: {
			  		exercise_home: "Exercise",
			  	},
			  	es: {
			  		exercise_home: "Ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		paciente_home: "Paciente",
			  	},
			  	en: {
			  		paciente_home: "Patient",
			  	},
			  	es: {
			  		paciente_home: "Paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		relatorios_home: "Relatórios ",
			  	},
			  	en: {
			  		relatorios_home: "Reports",
			  	},
			  	es: {
			  		relatorios_home: "Informes",
			  	}
			  },
		
			  {
			  	pt: {
			  		acessar: "Acessar",
			  	},
			  	en: {
			  		acessar: "Access",
			  	},
			  	es: {
			  		acessar: "Acceso",
			  	}
			  },
		
			  {
			  	pt: {
			  		quick_actions: "Ações rápidas",
			  	},
			  	en: {
			  		quick_actions: "quick actions",
			  	},
			  	es: {
			  		quick_actions: "acciones rápidas",
			  	}
			  },
		
			  {
			  	pt: {
			  		no_exercise: "Nenhum exercício",
			  	},
			  	en: {
			  		no_exercise: "No exercise",
			  	},
			  	es: {
			  		no_exercise: "No ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		ultima_semana: "Última semana",
			  	},
			  	en: {
			  		ultima_semana: "Last week",
			  	},
			  	es: {
			  		ultima_semana: "Semana pasada",
			  	}
			  },
		
			  {
			  	pt: {
			  		exer_avaliados: "Exercícios avaliados",
			  	},
			  	en: {
			  		exer_avaliados: "Exercises evaluated",
			  	},
			  	es: {
			  		exer_avaliados: "Ejercicios evaluados",
			  	}
			  },
		
			  {
			  	pt: {
			  		custom_period: "Período personalizado",
			  	},
			  	en: {
			  		custom_period: "Custom period",
			  	},
			  	es: {
			  		custom_period: "Periodo personalizado",
			  	}
			  },
		
			  {
			  	pt: {
			  		select_datas: "Selecionar datas",
			  	},
			  	en: {
			  		select_datas: "select dates",
			  	},
			  	es: {
			  		select_datas: "Seleccionar fechas",
			  	}
			  },
		
			  {
			  	pt: {
			  		q_p_deseja_analisar: "Qual período deseja analisar?",
			  	},
			  	en: {
			  		q_p_deseja_analisar: "Which period do you want to analyze?",
			  	},
			  	es: {
			  		q_p_deseja_analisar: "¿Qué período quieres analizar?",
			  	}
			  },
		
			  {
			  	pt: {
			  		q_v_q_deseja: "Qual a visualização que você deseja?",
			  	},
			  	en: {
			  		q_v_q_deseja: "Which view do you want?",
			  	},
			  	es: {
			  		q_v_q_deseja: "¿Qué vista quieres?",
			  	}
			  },
		
			  {
			  	pt: {
			  		periodo_personalizado: "Período personalizado",
			  	},
			  	en: {
			  		periodo_personalizado: "Custom period",
			  	},
			  	es: {
			  		periodo_personalizado: "Periodo personalizado",
			  	}
			  },
		
			  {
			  	pt: {
			  		ultimo_mes: "Último mês",
			  	},
			  	en: {
			  		ultimo_mes: "Last month",
			  	},
			  	es: {
			  		ultimo_mes: "el mes pasado",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeiro_acesso_nacionalidade: "País",
			  	},
			  	en: {
			  		primeiro_acesso_nacionalidade: "Country",
			  	},
			  	es: {
			  		primeiro_acesso_nacionalidade: "País",
			  	}
			  },
		
			  {
			  	pt: {
			  		etiqueta_atribuida: "Etiqueta atribuída com sucesso!",
			  	},
			  	en: {
			  		etiqueta_atribuida: "Tag successfully assigned!",
			  	},
			  	es: {
			  		etiqueta_atribuida: "¡Etiqueta asignada correctamente!",
			  	}
			  },
		
			  {
			  	pt: {
			  		etiqueta_excluida: "Etiqueta excluída com sucesso.",
			  	},
			  	en: {
			  		etiqueta_excluida: "Tag deleted successfully.",
			  	},
			  	es: {
			  		etiqueta_excluida: "Etiqueta eliminada correctamente.",
			  	}
			  },
		
			  {
			  	pt: {
			  		altere_algo: "Altere algo para poder editar",
			  	},
			  	en: {
			  		altere_algo: "Change something to be able to edit",
			  	},
			  	es: {
			  		altere_algo: "Cambiar algo para poder editar",
			  	}
			  },
		
			  {
			  	pt: {
			  		min_etiqueta_4: "O nome da etiqueta deve conter no mínimo 4 caracteres.",
			  	},
			  	en: {
			  		min_etiqueta_4: "The tag name must contain at least 4 characters.",
			  	},
			  	es: {
			  		min_etiqueta_4: "El nombre de la etiqueta debe contener al menos 4 caracteres.",
			  	}
			  },
		
			  {
			  	pt: {
			  		time_descanso_save: "O intervalo de descanso foi salvo",
			  	},
			  	en: {
			  		time_descanso_save: "The rest interval has been saved",
			  	},
			  	es: {
			  		time_descanso_save: "Se ha guardado el intervalo de descanso.",
			  	}
			  },
		
			  {
			  	pt: {
			  		time_contration_save: "O tempo de contração foi salvo",
			  	},
			  	en: {
			  		time_contration_save: "The contraction time was saved",
			  	},
			  	es: {
			  		time_contration_save: "Se ahorró el tiempo de contracción",
			  	}
			  },
		
			  {
			  	pt: {
			  		time_contration_no_zero: "O tempo de contração não pode ser 0",
			  	},
			  	en: {
			  		time_contration_no_zero: "Contraction time cannot be 0",
			  	},
			  	es: {
			  		time_contration_no_zero: "El tiempo de contracción no puede ser 0",
			  	}
			  },
		
			  {
			  	pt: {
			  		marque_exer: "Marque os exercícios que vão compor seu protocolo",
			  	},
			  	en: {
			  		marque_exer: "Mark the exercises that will make up your protocol",
			  	},
			  	es: {
			  		marque_exer: "Marque los ejercicios que conformarán su protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		legenda_descricao_evolucao: "Valor(es) de referência de mediana de força máxima em avaliação isométrica para paciente do sexo masculino dentro da faixa etária de 20 a 24 anos.",
			  	},
			  	en: {
			  		legenda_descricao_evolucao: "Reference value(s) of median maximum strength in isometric assessment for male patients aged 20 to 24 years.",
			  	},
			  	es: {
			  		legenda_descricao_evolucao: "Valor(es) de referencia de la fuerza máxima mediana en la evaluación isométrica para pacientes varones de 20 a 24 años.",
			  	}
			  },
		
			  {
			  	pt: {
			  		referencia: "Referência",
			  	},
			  	en: {
			  		referencia: "Reference",
			  	},
			  	es: {
			  		referencia: "Referencia",
			  	}
			  },
		
			  {
			  	pt: {
			  		Tempo_total: "Tempo Total (s)",
			  	},
			  	en: {
			  		Tempo_total: "Total Time (s)",
			  	},
			  	es: {
			  		Tempo_total: "Tiempo total (s)",
			  	}
			  },
		
			  {
			  	pt: {
			  		drag_protocolo: "Segure e arraste o exercício para mudar a ordem",
			  	},
			  	en: {
			  		drag_protocolo: "Hold and drag the exercise to change the order",
			  	},
			  	es: {
			  		drag_protocolo: "Mantenga presionado y arrastre el ejercicio para cambiar el orden",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_upgrade_funcionalidade_indisponivel2: "Faça o upgrade para ter acesso à todas as funcionalidades do app.",
			  	},
			  	en: {
			  		modal_upgrade_funcionalidade_indisponivel2: "Upgrade to have access to all the app´s features.",
			  	},
			  	es: {
			  		modal_upgrade_funcionalidade_indisponivel2: "Actualice para tener acceso a todas las funciones de la aplicación.",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_upgrade_funcionalidade_indisponivel: "Esta funcionalidade não está disponível para o seu plano de assinatura. ",
			  	},
			  	en: {
			  		modal_upgrade_funcionalidade_indisponivel: "This functionality is not available for your subscription plan.",
			  	},
			  	es: {
			  		modal_upgrade_funcionalidade_indisponivel: "Esta funcionalidad no está disponible para su plan de suscripción.",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_upgrade_funcionalidade3: "Aproveite mais do seu Kinology!",
			  	},
			  	en: {
			  		modal_upgrade_funcionalidade3: "Get more from your Kinology!",
			  	},
			  	es: {
			  		modal_upgrade_funcionalidade3: "¡Obtenga más de su Kinology!",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_upgrade_funcionalidade2: "Mas calma, pra resolver isto é simples. Basta entrar em contato com a gente e solicitar a mudança no seu pacote de serviços.",
			  	},
			  	en: {
			  		modal_upgrade_funcionalidade2: "But calm down, to solve this is simple. Just contact us and request a change in your service package.",
			  	},
			  	es: {
			  		modal_upgrade_funcionalidade2: "Pero cálmate, solucionar esto es sencillo. Simplemente contáctenos y solicite un cambio en su paquete de servicios.",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_upgrade_avaliador: "Você atingiu o número limite de cadastro de avaliadores incluso em seu plano.",
			  	},
			  	en: {
			  		modal_upgrade_avaliador: "You have reached the maximum number of appraisers registration included in your plan.",
			  	},
			  	es: {
			  		modal_upgrade_avaliador: "Ha alcanzado el número máximo de registros de tasadores incluidos en su plan.",
			  	}
			  },
		
			  {
			  	pt: {
			  		pesquise_etiqueta: "Pesquise ou adicione uma etiqueta",
			  	},
			  	en: {
			  		pesquise_etiqueta: "Search or add a tag",
			  	},
			  	es: {
			  		pesquise_etiqueta: "Buscar o agregar una etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		vc_recebeu: "Você recebeu uma indicação para conhecer o Kinology com 50% off no primeiro mês. Revolucione seus atendimentos com uma tecnologia baseada em dados e validada cientificamente.",
			  	},
			  	en: {
			  		vc_recebeu: "You received a referral to meet Kinology with 50% off in the first month. Revolutionize your services with a technology based on data and scientifically validated.",
			  	},
			  	es: {
			  		vc_recebeu: "Recibió una remisión para conocer a Kinology con un 50% de descuento en el primer mes. Revolucione sus servicios con una tecnología basada en datos y validada científicamente.",
			  	}
			  },
		
			  {
			  	pt: {
			  		indicar_ao: "Ao indicar alguém, após a efetivação da assinatura do indicado, você e seu amigo obter 50% de desconto na próxima mensalidade.",
			  	},
			  	en: {
			  		indicar_ao: "When referring someone, after the subscription of the indicated, you and your friend get 50% discount on the next monthly fee.",
			  	},
			  	es: {
			  		indicar_ao: "Al recomendar a alguien, luego de la suscripción de lo indicado, usted y su amigo obtienen un 50% de descuento en la próxima mensualidad.",
			  	}
			  },
		
			  {
			  	pt: {
			  		friends: "Indicar amigos",
			  	},
			  	en: {
			  		friends: "Refer friends",
			  	},
			  	es: {
			  		friends: "Recomienda amigos",
			  	}
			  },
		
			  {
			  	pt: {
			  		monthly: "na mensalidade",
			  	},
			  	en: {
			  		monthly: "in the monthly",
			  	},
			  	es: {
			  		monthly: "en el mensual",
			  	}
			  },
		
			  {
			  	pt: {
			  		discount: "de desconto",
			  	},
			  	en: {
			  		discount: "discount",
			  	},
			  	es: {
			  		discount: "descuento",
			  	}
			  },
		
			  {
			  	pt: {
			  		indique_ganhe: "Indique e ganhe",
			  	},
			  	en: {
			  		indique_ganhe: "Mominate and win",
			  	},
			  	es: {
			  		indique_ganhe: "nominar y ganar",
			  	}
			  },
		
			  {
			  	pt: {
			  		digite_seu_problema: "Digite aqui seu problema e/ou sugestão.",
			  	},
			  	en: {
			  		digite_seu_problema: "Enter your problem and/or suggestion here.",
			  	},
			  	es: {
			  		digite_seu_problema: "Ingrese su problema y / o sugerencia aquí.",
			  	}
			  },
		
			  {
			  	pt: {
			  		Digite_seu_nome: "Digite aqui seu nome",
			  	},
			  	en: {
			  		Digite_seu_nome: "Enter your name here",
			  	},
			  	es: {
			  		Digite_seu_nome: "Introduzca su nombre aquí",
			  	}
			  },
		
			  {
			  	pt: {
			  		execucao_indice_iq: "Índice I/Q",
			  	},
			  	en: {
			  		execucao_indice_iq: "I/Q index",
			  	},
			  	es: {
			  		execucao_indice_iq: "Índice I/C",
			  	}
			  },
		
			  {
			  	pt: {
			  		kilogramas: "quilogramas",
			  	},
			  	en: {
			  		kilogramas: "kilograms",
			  	},
			  	es: {
			  		kilogramas: "kilogramas",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_protocolo: "Protocolos sugeridos",
			  	},
			  	en: {
			  		menu_lateral_exercicios_protocolo: "Suggested protocols",
			  	},
			  	es: {
			  		menu_lateral_exercicios_protocolo: "Protocolos sugeridos",
			  	}
			  },
		
			  {
			  	pt: {
			  		entrar_contato: "Caso necessário, entrar em contato comigo por aqui",
			  	},
			  	en: {
			  		entrar_contato: "If necessary, contact me here",
			  	},
			  	es: {
			  		entrar_contato: "Si es necesario, contáctame aquí",
			  	}
			  },
		
			  {
			  	pt: {
			  		edicao_erro_6: "Email de avaliador não pode ser vazio",
			  	},
			  	en: {
			  		edicao_erro_6: "Evaluator email cannot be empty",
			  	},
			  	es: {
			  		edicao_erro_6: "El correo electrónico del evaluador no puede estar vacío",
			  	}
			  },
		
			  {
			  	pt: {
			  		telefone: "Telefone",
			  	},
			  	en: {
			  		telefone: "Telephone",
			  	},
			  	es: {
			  		telefone: "Teléfono",
			  	}
			  },
		
			  {
			  	pt: {
			  		limite_maximo_etiquetas: "Limite máximo de etiquetas atingido",
			  	},
			  	en: {
			  		limite_maximo_etiquetas: "Maximum tag limit reached",
			  	},
			  	es: {
			  		limite_maximo_etiquetas: "Se alcanzó el límite máximo de etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		qual_sua_duvida: "Qual é a sua dúvida?",
			  	},
			  	en: {
			  		qual_sua_duvida: "What is your question?",
			  	},
			  	es: {
			  		qual_sua_duvida: "Cuál es su pregunta?",
			  	}
			  },
		
			  {
			  	pt: {
			  		sugestoes: "Sugestões",
			  	},
			  	en: {
			  		sugestoes: "Suggestions",
			  	},
			  	es: {
			  		sugestoes: "Sugerencias",
			  	}
			  },
		
			  {
			  	pt: {
			  		alterar_relatorio: "Alterar relatório",
			  	},
			  	en: {
			  		alterar_relatorio: "Change report",
			  	},
			  	es: {
			  		alterar_relatorio: "Cambiar informe",
			  	}
			  },
		
			  {
			  	pt: {
			  		problemas: "Problemas",
			  	},
			  	en: {
			  		problemas: "Problems",
			  	},
			  	es: {
			  		problemas: "Problemas",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_intervalo: "Intervalo de recuperação",
			  	},
			  	en: {
			  		menu_lateral_exercicios_intervalo: " Recovery interval",
			  	},
			  	es: {
			  		menu_lateral_exercicios_intervalo: "Intervalo de recuperación",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_editar_tag: "Editar etiqueta",
			  	},
			  	en: {
			  		modal_editar_tag: "Edit tag",
			  	},
			  	es: {
			  		modal_editar_tag: "Editar etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		failure_avaliation: "Não foi possível salvar sua avalição",
			  	},
			  	en: {
			  		failure_avaliation: "Unable to save your evaluation",
			  	},
			  	es: {
			  		failure_avaliation: "No se puede guardar su evaluación",
			  	}
			  },
		
			  {
			  	pt: {
			  		helpme: "Me ajuda",
			  	},
			  	en: {
			  		helpme: "Help me",
			  	},
			  	es: {
			  		helpme: "Ayúdame",
			  	}
			  },
		
			  {
			  	pt: {
			  		problemas_sugestoes: "Problemas e sugestões",
			  	},
			  	en: {
			  		problemas_sugestoes: "Problems and suggestions",
			  	},
			  	es: {
			  		problemas_sugestoes: "Problemas y sugerencias",
			  	}
			  },
		
			  {
			  	pt: {
			  		me_ajuda: "Me ajuda",
			  	},
			  	en: {
			  		me_ajuda: "Help me",
			  	},
			  	es: {
			  		me_ajuda: "Me ayuda",
			  	}
			  },
		
			  {
			  	pt: {
			  		central_ajuda: "Central de ajuda",
			  	},
			  	en: {
			  		central_ajuda: "Help Center",
			  	},
			  	es: {
			  		central_ajuda: "Centro de ayuda",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_duvidas: "Central de ajuda",
			  	},
			  	en: {
			  		menu_lateral_duvidas: "Help Center",
			  	},
			  	es: {
			  		menu_lateral_duvidas: "Centro de ayuda",
			  	}
			  },
		
			  {
			  	pt: {
			  		desativacao_falhou: "Desativação falhou",
			  	},
			  	en: {
			  		desativacao_falhou: "Deactivation failed",
			  	},
			  	es: {
			  		desativacao_falhou: "Desactivación fallida",
			  	}
			  },
		
			  {
			  	pt: {
			  		falha_salvar_cartao: "Falha ao salvar cartão",
			  	},
			  	en: {
			  		falha_salvar_cartao: "Failed to save card",
			  	},
			  	es: {
			  		falha_salvar_cartao: "Fallo al salvar la tarjeta",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_logradouro: "Logradouro",
			  	},
			  	en: {
			  		menu_lateral_logradouro: "Address",
			  	},
			  	es: {
			  		menu_lateral_logradouro: "Dirección",
			  	}
			  },
		
			  {
			  	pt: {
			  		no_paciente: "Nenhum paciente encontrado, clique no botão para adicionar!",
			  	},
			  	en: {
			  		no_paciente: "No patients found, click the add button!",
			  	},
			  	es: {
			  		no_paciente: "No se encontraron pacientes, haga clic en el botón agregar.",
			  	}
			  },
		
			  {
			  	pt: {
			  		modo_exercicio: "Selecione o modo do exercício",
			  	},
			  	en: {
			  		modo_exercicio: "Select exercise mode",
			  	},
			  	es: {
			  		modo_exercicio: "Seleccione el modo de ejercicio",
			  	}
			  },
			  {
				pt: {
					config_exercise_functional: "Exercício Funcional",
				},
				en: {
					config_exercise_functional: "Functional Exercise",
				},
				es: {
					config_exercise_functional: "Ejercicio funcional",
				}
			},
			{
				pt: {
					description_config_exercise_functional: "Permite habilitar o exercício no modo funcional",
				},
				en: {
					description_config_exercise_functional: "Allows you to enable exercise in functional mode",
				},
				es: {
					description_config_exercise_functional: "Le permite habilitar el ejercicio en modo funcional",
				}
			},
		
			  {
			  	pt: {
			  		gerenciar_proto_criar: "Novo protocolo",
			  	},
			  	en: {
			  		gerenciar_proto_criar: "New protocol",
			  	},
			  	es: {
			  		gerenciar_proto_criar: "Nuevo protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuir_tags_excluir: "Excluir etiquetas",
			  	},
			  	en: {
			  		atribuir_tags_excluir: "Delete tags",
			  	},
			  	es: {
			  		atribuir_tags_excluir: "Eliminar etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		tags_criar_nova_etiqueta: "Adicionar etiqueta",
			  	},
			  	en: {
			  		tags_criar_nova_etiqueta: "new tag",
			  	},
			  	es: {
			  		tags_criar_nova_etiqueta: "Nueva etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_lixeira: "Lixeira",
			  	},
			  	en: {
			  		menu_lateral_lixeira: "Trashcan",
			  	},
			  	es: {
			  		menu_lateral_lixeira: "Papelera",
			  	}
			  },
		
			  {
			  	pt: {
			  		adicionar_avaliador: "Novo avaliador",
			  	},
			  	en: {
			  		adicionar_avaliador: "New evaluator",
			  	},
			  	es: {
			  		adicionar_avaliador: "Nuevo evaluador",
			  	}
			  },
		
			  {
			  	pt: {
			  		execucao_force_media: "Força média",
			  	},
			  	en: {
			  		execucao_force_media: "Medium force",
			  	},
			  	es: {
			  		execucao_force_media: "Fuerza media",
			  	}
			  },
		
			  {
			  	pt: {
			  		baixe_app: "Baixe nosso aplicativo direto da loja de aplicativos do seu celular.",
			  	},
			  	en: {
			  		baixe_app: "Download our app straight from your mobile app store.",
			  	},
			  	es: {
			  		baixe_app: "Descargue nuestra aplicación directamente desde su tienda de aplicaciones móviles.",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperar: "Recuperar",
			  	},
			  	en: {
			  		recuperar: "To recover",
			  	},
			  	es: {
			  		recuperar: "Para recuperar",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluir_metodo_pagamento: "Excluir método de pagamento?",
			  	},
			  	en: {
			  		excluir_metodo_pagamento: "Delete payment method?",
			  	},
			  	es: {
			  		excluir_metodo_pagamento: "¿Eliminar método de pago?",
			  	}
			  },
		
			  {
			  	pt: {
			  		localizacao: "Localização",
			  	},
			  	en: {
			  		localizacao: "Location",
			  	},
			  	es: {
			  		localizacao: "Localización",
			  	}
			  },
		
			  {
			  	pt: {
			  		forca_max: "Força máx.",
			  	},
			  	en: {
			  		forca_max: "Max force",
			  	},
			  	es: {
			  		forca_max: "Fuerza máx.",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuir_condicionamento: "Atribuir Condicionamento físico",
			  	},
			  	en: {
			  		atribuir_condicionamento: "Assign physical conditioning",
			  	},
			  	es: {
			  		atribuir_condicionamento: "Asignar acondicionamiento físico",
			  	}
			  },
		
			  {
			  	pt: {
			  		apagar_anotacao: "Deseja apagar a anotação do dia",
			  	},
			  	en: {
			  		apagar_anotacao: "Do you want to delete the note of the day",
			  	},
			  	es: {
			  		apagar_anotacao: " ¿Quieres borrar la nota del día?",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_atleta: "Atleta",
			  	},
			  	en: {
			  		menu_lateral_atleta: "Athlete",
			  	},
			  	es: {
			  		menu_lateral_atleta: "Atleta",
			  	}
			  },
		
			  {
			  	pt: {
			  		proximo: "PRÓXIMO",
			  	},
			  	en: {
			  		proximo: "NEXT",
			  	},
			  	es: {
			  		proximo: "SIGUIENTE",
			  	}
			  },
		
			  {
			  	pt: {
			  		direita: "Direita",
			  	},
			  	en: {
			  		direita: "Right",
			  	},
			  	es: {
			  		direita: "Derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		esquerda: "Esquerda",
			  	},
			  	en: {
			  		esquerda: "Left",
			  	},
			  	es: {
			  		esquerda: "Izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		agrupamentos_inferiores_frontal: "Agrupamentos inferiores frontal",
			  	},
			  	en: {
			  		agrupamentos_inferiores_frontal: "Front lower clusters",
			  	},
			  	es: {
			  		agrupamentos_inferiores_frontal: "Grupos inferiores delanteros",
			  	}
			  },
		
			  {
			  	pt: {
			  		agrupamentos_inferiores_dorsal: "Agrupamentos inferiores dorsal",
			  	},
			  	en: {
			  		agrupamentos_inferiores_dorsal: "Lower dorsal groupings",
			  	},
			  	es: {
			  		agrupamentos_inferiores_dorsal: "Racimos inferiores dorsales",
			  	}
			  },
		
			  {
			  	pt: {
			  		agrupamentos_superiores_dorsal: "Agrupamentos superiores dorsal",
			  	},
			  	en: {
			  		agrupamentos_superiores_dorsal: "Upper dorsal groupings",
			  	},
			  	es: {
			  		agrupamentos_superiores_dorsal: "Grupos dorsales superiores",
			  	}
			  },
		
			  {
			  	pt: {
			  		agrupamentos_superiores_frontal: "Agrupamentos superiores frontal",
			  	},
			  	en: {
			  		agrupamentos_superiores_frontal: "Upper front groupings",
			  	},
			  	es: {
			  		agrupamentos_superiores_frontal: "Grupos superiores delanteros",
			  	}
			  },
		
			  {
			  	pt: {
			  		sem_dados_suficientes_relatorio: "Você ainda não tem dados suficientes para gerar o gráfico desse exercício",
			  	},
			  	en: {
			  		sem_dados_suficientes_relatorio: "You do not yet have enough data to generate the graph for this exercise",
			  	},
			  	es: {
			  		sem_dados_suficientes_relatorio: "Todavía no tienes suficientes datos para generar el gráfico de este ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		comecar_protocolo_sugerido: "Quero começar com os protocolos sugeridos Kinology",
			  	},
			  	en: {
			  		comecar_protocolo_sugerido: " I want to start with the suggested Kinology protocols",
			  	},
			  	es: {
			  		comecar_protocolo_sugerido: "Quiero comenzar con los protocolos Kinology sugeridos",
			  	}
			  },
		
			  {
			  	pt: {
			  		novo_protocolo: "Novo protocolo",
			  	},
			  	en: {
			  		novo_protocolo: "New protocol",
			  	},
			  	es: {
			  		novo_protocolo: "Nuevo protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		salvar_alteracoes: "SALVAR ALTERAÇÕES",
			  	},
			  	en: {
			  		salvar_alteracoes: "SAVE EDITIONS",
			  	},
			  	es: {
			  		salvar_alteracoes: "GUARDAR EDICIONES",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_opcao: "Escolha uma opção",
			  	},
			  	en: {
			  		escolha_opcao: "Choose an option",
			  	},
			  	es: {
			  		escolha_opcao: "Elija una opción",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_conecta4: "Para executar uma avaliação, é necessário que você acesse a plataforma de um dispositivo Windows ou MacOS.",
			  	},
			  	en: {
			  		modal_conecta4: "To run an assessment, you must access the platform from a Windows or MacOS device.",
			  	},
			  	es: {
			  		modal_conecta4: "Para ejecutar una evaluación, debe acceder a la plataforma desde un dispositivo Windows o MacOS.",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_conecta2: "Para executar uma avaliação, é necessário que você acesse a plataforma pelo navegador Google Chrome.",
			  	},
			  	en: {
			  		modal_conecta2: "To run a trial, you need to access the platform through the Google Chrome browser.",
			  	},
			  	es: {
			  		modal_conecta2: " Para ejecutar una prueba, debe acceder a la plataforma a través del navegador Google Chrome.",
			  	}
			  },
		
			  {
			  	pt: {
			  		alterar_arquivo: "Alterar arquivo",
			  	},
			  	en: {
			  		alterar_arquivo: "Change file",
			  	},
			  	es: {
			  		alterar_arquivo: "Cambiar el archivo",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_periodo: "Escolha o período que deseja analisar",
			  	},
			  	en: {
			  		escolha_periodo: "Choose the period you want to analyze",
			  	},
			  	es: {
			  		escolha_periodo: "Elija el período que desea analizar",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_criado: "Exercício criado",
			  	},
			  	en: {
			  		exercicio_criado: " Exercise created",
			  	},
			  	es: {
			  		exercicio_criado: "Ejercicio creado",
			  	}
			  },
		
			  {
			  	pt: {
			  		musculo_dominante: "Músculo dominante",
			  	},
			  	en: {
			  		musculo_dominante: "Dominant muscle",
			  	},
			  	es: {
			  		musculo_dominante: "Músculo dominante",
			  	}
			  },
		
			  {
			  	pt: {
			  		baixa_chance_lesao: "Baixa chance de lesão (<=10% de assimetria )",
			  	},
			  	en: {
			  		baixa_chance_lesao: "Low chance of injury (<=10% asymmetry )",
			  	},
			  	es: {
			  		baixa_chance_lesao: "Baja probabilidad de lesión (<=10% de asimetría )",
			  	}
			  },
		
			  {
			  	pt: {
			  		media_chance_lesao: "Média chance de lesão (>10% e <20% de assimetria)",
			  	},
			  	en: {
			  		media_chance_lesao: "Medium chance of injury (>10% and <20% asymmetry)",
			  	},
			  	es: {
			  		media_chance_lesao: "Probabilidad media de lesión (>10% y <20% de asimetría)",
			  	}
			  },
		
			  {
			  	pt: {
			  		alta_chance_lesao: "Alta chance de lesão (>=20% de assimetria)",
			  	},
			  	en: {
			  		alta_chance_lesao: "High chance of injury (>=20% asymmetry)",
			  	},
			  	es: {
			  		alta_chance_lesao: "Alta probabilidad de lesión (>=20% de asimetría)",
			  	}
			  },
		
			  {
			  	pt: {
			  		sem_historico_assimetria: "Sem histórico de assimetria",
			  	},
			  	en: {
			  		sem_historico_assimetria: " No history of asymmetry",
			  	},
			  	es: {
			  		sem_historico_assimetria: "No hay antecedentes de asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		execute_exercicios_bilaterais: "Execute exercícios bilaterais para ter resultados aqui",
			  	},
			  	en: {
			  		execute_exercicios_bilaterais: "Perform bilateral exercises to get results here",
			  	},
			  	es: {
			  		execute_exercicios_bilaterais: "Realiza ejercicios bilaterales para obtener resultados aquí",
			  	}
			  },
		
			  {
			  	pt: {
			  		sem_dados_assimetria: "sem dados de assimetria para esses agrupamentos musculares",
			  	},
			  	en: {
			  		sem_dados_assimetria: "no asymmetry data for these muscle groupings.",
			  	},
			  	es: {
			  		sem_dados_assimetria: "sin datos de asimetría para estas agrupaciones musculares. agrupaciones musculares.",
			  	}
			  },
		
			  {
			  	pt: {
			  		relatorio_enviado: "Relatório enviado",
			  	},
			  	en: {
			  		relatorio_enviado: "Report sent",
			  	},
			  	es: {
			  		relatorio_enviado: "Informe enviado",
			  	}
			  },
		
			  {
			  	pt: {
			  		relatorio_gerado_sucesso: "Relatório gerado com sucesso",
			  	},
			  	en: {
			  		relatorio_gerado_sucesso: "Report generated successfully",
			  	},
			  	es: {
			  		relatorio_gerado_sucesso: "Informe generado con éxito",
			  	}
			  },
		
			  {
			  	pt: {
			  		execute_algum_exercicio: "Execute algum exercício para ter resultados aqui",
			  	},
			  	en: {
			  		execute_algum_exercicio: "Perform some exercise to get results here",
			  	},
			  	es: {
			  		execute_algum_exercicio: "Haz algo de ejercicio para obtener resultados aquí",
			  	}
			  },
		
			  {
			  	pt: {
			  		dados_insuficientes_gerar_graficos: "Dados insuficientes para gerar gráficos",
			  	},
			  	en: {
			  		dados_insuficientes_gerar_graficos: "Insufficient dice to generate graphs",
			  	},
			  	es: {
			  		dados_insuficientes_gerar_graficos: "Dados insuficientes para gerar gráficos",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolher_forca_minima_manual: "Escolher força mínima manualmente",
			  	},
			  	en: {
			  		escolher_forca_minima_manual: "Choose minimum force manually",
			  	},
			  	es: {
			  		escolher_forca_minima_manual: "Elija manualmente la fuerza mínima",
			  	}
			  },
		
			  {
			  	pt: {
			  		baixe_app_acesso_pelo_site: "Baixe nosso aplicativo direto da loja de aplicativos do seu celular. Acesse a versão Web em nosso site, através de um computador.",
			  	},
			  	en: {
			  		baixe_app_acesso_pelo_site: "Download our app straight from your mobile app store. Access the web version on our website from a computer.",
			  	},
			  	es: {
			  		baixe_app_acesso_pelo_site: "Descargue nuestra aplicación directamente desde su tienda de aplicaciones móviles. Acceda a la versión web en nuestro sitio web desde una computadora.",
			  	}
			  },
		
			  {
			  	pt: {
			  		agrupamento_inferiores: "Agrupamentos Inferiores",
			  	},
			  	en: {
			  		agrupamento_inferiores: "Lower Groupings",
			  	},
			  	es: {
			  		agrupamento_inferiores: "Agrupaciones inferiores",
			  	}
			  },
		
			  {
			  	pt: {
			  		agrupamento_superiores: "Agrupamentos Superiores",
			  	},
			  	en: {
			  		agrupamento_superiores: "Higher Groupings",
			  	},
			  	es: {
			  		agrupamento_superiores: "Agrupaciones superiores",
			  	}
			  },
		
			  {
			  	pt: {
			  		buscando_dino: "Buscando dinamômetro...",
			  	},
			  	en: {
			  		buscando_dino: "Searching for dynamometer...",
			  	},
			  	es: {
			  		buscando_dino: "Buscando el dinamómetro...",
			  	}
			  },
		
			  {
			  	pt: {
			  		luz_continua: "Luz contínua: conectado",
			  	},
			  	en: {
			  		luz_continua: "Continuous light: connected",
			  	},
			  	es: {
			  		luz_continua: "Luz continua: conectada",
			  	}
			  },
		
			  {
			  	pt: {
			  		luz_piscando: "Luz piscando: procurando",
			  	},
			  	en: {
			  		luz_piscando: "Blinking light: searching",
			  	},
			  	es: {
			  		luz_piscando: "Luz intermitente: búsqueda",
			  	}
			  },
		
			  {
			  	pt: {
			  		verifique_seu_dino: "Verifique seu dinamômetro",
			  	},
			  	en: {
			  		verifique_seu_dino: "Check your dynamometer",
			  	},
			  	es: {
			  		verifique_seu_dino: "Compruebe su dinamómetro",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhuma_anotacao_paciente: "Nenhuma anotação para esse paciente",
			  	},
			  	en: {
			  		nenhuma_anotacao_paciente: "No annotation for this patient",
			  	},
			  	es: {
			  		nenhuma_anotacao_paciente: "No hay anotaciones para este paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhuma_etiqueta_selecionada: "Nenhuma etiqueta selecionada",
			  	},
			  	en: {
			  		nenhuma_etiqueta_selecionada: "No tag selected",
			  	},
			  	es: {
			  		nenhuma_etiqueta_selecionada: "No se ha seleccionado ninguna etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		vc_deseja_resgatar_execucao: "Voce deseja resgatar a execução",
			  	},
			  	en: {
			  		vc_deseja_resgatar_execucao: "You want to rescue the execution",
			  	},
			  	es: {
			  		vc_deseja_resgatar_execucao: "Desea recordar la ejecución",
			  	}
			  },
		
			  {
			  	pt: {
			  		vc_deseja_resgatar_exercicio: "Você deseja resgatar o exercício",
			  	},
			  	en: {
			  		vc_deseja_resgatar_exercicio: "You want to redeem the exercise",
			  	},
			  	es: {
			  		vc_deseja_resgatar_exercicio: "Desea canjear el ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		deseja_resgatar_paciente: "Você deseja resgatar o paciente",
			  	},
			  	en: {
			  		deseja_resgatar_paciente: "You want to rescue the patient",
			  	},
			  	es: {
			  		deseja_resgatar_paciente: "Quieres rescatar al paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		vc_nao_pode_editar_usuario: "Você não pode editar esse usuário",
			  	},
			  	en: {
			  		vc_nao_pode_editar_usuario: "You cannot edit this user",
			  	},
			  	es: {
			  		vc_nao_pode_editar_usuario: "No puede editar este usuario",
			  	}
			  },
		
			  {
			  	pt: {
			  		vc_nao_pode_excluir_usuario: "Você não pode excluir esse usuário",
			  	},
			  	en: {
			  		vc_nao_pode_excluir_usuario: " You cannot delete this user",
			  	},
			  	es: {
			  		vc_nao_pode_excluir_usuario: "No puede eliminar este usuario",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_conecta: "Para conectar ao Dinamômetro e realizar o exercício, por favor ative:",
			  	},
			  	en: {
			  		modal_conecta: "To connect to the Dynamometer and perform the exercise, please activate:",
			  	},
			  	es: {
			  		modal_conecta: "Para conectarse al dinamómetro y realizar el ejercicio, active:",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluido_ha: "Excluído há",
			  	},
			  	en: {
			  		excluido_ha: "Deleted",
			  	},
			  	es: {
			  		excluido_ha: "Borrado hace",
			  	}
			  },
		
			  {
			  	pt: {
			  		usuario: "Usuários",
			  	},
			  	en: {
			  		usuario: "Users",
			  	},
			  	es: {
			  		usuario: "Usuarios",
			  	}
			  },
		
			  {
			  	pt: {
			  		digite_sua_cidade: "Digite sua cidade. Ex: São Paulo",
			  	},
			  	en: {
			  		digite_sua_cidade: "Enter your city",
			  	},
			  	es: {
			  		digite_sua_cidade: "Introduzca su ciudad",
			  	}
			  },
		
			  {
			  	pt: {
			  		digite_estado: "Digite seu estado. Ex: SP",
			  	},
			  	en: {
			  		digite_estado: "Type your state",
			  	},
			  	es: {
			  		digite_estado: "Escriba su estado",
			  	}
			  },
		
			  {
			  	pt: {
			  		digite_numero_endereco: "Digite o número referente ao endereço",
			  	},
			  	en: {
			  		digite_numero_endereco: "Enter the number for the address",
			  	},
			  	es: {
			  		digite_numero_endereco: "Introduzca el número de la dirección",
			  	}
			  },
		
			  {
			  	pt: {
			  		digite_seu_bairro: "Digite seu Bairro",
			  	},
			  	en: {
			  		digite_seu_bairro: "Enter your Neighborhood",
			  	},
			  	es: {
			  		digite_seu_bairro: "Introduzca su barrio",
			  	}
			  },
		
			  {
			  	pt: {
			  		digite_cep: "Digite seu CEP",
			  	},
			  	en: {
			  		digite_cep: "Enter your zip code",
			  	},
			  	es: {
			  		digite_cep: "Introduzca su código postal",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_person_endereco: "Endereço",
			  	},
			  	en: {
			  		menu_lateral_person_endereco: "Address",
			  	},
			  	es: {
			  		menu_lateral_person_endereco: "Dirección",
			  	}
			  },
		
			  {
			  	pt: {
			  		aproveite_sem_moderacao: "Aproveite e use sem moderação!",
			  	},
			  	en: {
			  		aproveite_sem_moderacao: "Enjoy and use without moderation!",
			  	},
			  	es: {
			  		aproveite_sem_moderacao: "Disfruta y usa sin moderación!",
			  	}
			  },
		
			  {
			  	pt: {
			  		todas_funcionalidades_liberadas: "Todas as funcionalidades do app liberadas até 31/09",
			  	},
			  	en: {
			  		todas_funcionalidades_liberadas: "All features of the app released until 09/31",
			  	},
			  	es: {
			  		todas_funcionalidades_liberadas: "Todas las funcionalidades de la app liberadas hasta el 31/09",
			  	}
			  },
		
			  {
			  	pt: {
			  		aproveite_todas_as_novidades: "Aproveite todas as novidades do Novo Kinology",
			  	},
			  	en: {
			  		aproveite_todas_as_novidades: "Enjoy all the new features of the New Kinology",
			  	},
			  	es: {
			  		aproveite_todas_as_novidades: "Disfruta de todas las novedades del nuevo Kinology",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao_possivel_cadastro_dispositivo: "Não foi possível concluir o cadastro pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		nao_possivel_cadastro_dispositivo: "Unable to complete registration because the device is not connected to the internet",
			  	},
			  	es: {
			  		nao_possivel_cadastro_dispositivo: "No se puede completar el registro porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		seu_cartao_salvo_sucesso: "Seu cartão foi salvo com sucesso",
			  	},
			  	en: {
			  		seu_cartao_salvo_sucesso: "Your card was saved successfully",
			  	},
			  	es: {
			  		seu_cartao_salvo_sucesso: "Su tarjeta se ha guardado correctamente",
			  	}
			  },
		
			  {
			  	pt: {
			  		dados_cartao_invalidos: "Dados de cartão inválidos",
			  	},
			  	en: {
			  		dados_cartao_invalidos: "Invalid card data",
			  	},
			  	es: {
			  		dados_cartao_invalidos: "Datos de la tarjeta no válidos",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao_possivel_personalizar_relatorio: "Não foi possível personalizar o relatório",
			  	},
			  	en: {
			  		nao_possivel_personalizar_relatorio: "Unable to customize report",
			  	},
			  	es: {
			  		nao_possivel_personalizar_relatorio: "No se puede personalizar el informe",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperacao_senha_indisponivel: "Recuperação de senha indisponível pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		recuperacao_senha_indisponivel: "Password recovery unavailable because the device is not connected to the internet",
			  	},
			  	es: {
			  		recuperacao_senha_indisponivel: "La recuperación de la contraseña no está disponible porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao_possivel_concluir_login: "Não foi possível concluir o login pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		nao_possivel_concluir_login: "Unable to complete login as the device is not connected to the internet",
			  	},
			  	es: {
			  		nao_possivel_concluir_login: "No se puede completar el inicio de sesión porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		anotacao_criado_sucesso: "Anotação criada com sucesso",
			  	},
			  	en: {
			  		anotacao_criado_sucesso: "Annotation created successfully",
			  	},
			  	es: {
			  		anotacao_criado_sucesso: "Anotación creada con éxito",
			  	}
			  },
		
			  {
			  	pt: {
			  		avaliador_editado_sucesso: "Avaliador editado com sucesso!",
			  	},
			  	en: {
			  		avaliador_editado_sucesso: "Evaluator edited successfully!",
			  	},
			  	es: {
			  		avaliador_editado_sucesso: "Evaluador editado con éxito",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperar_essas_execucoes: "Deseja recuperar essas execuções?",
			  	},
			  	en: {
			  		recuperar_essas_execucoes: "Do you want to recover these executions?",
			  	},
			  	es: {
			  		recuperar_essas_execucoes: " ¿Quieres recuperar estas ejecuciones?",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperar_essa_execucao: "Deseja recuperar essa execução?",
			  	},
			  	en: {
			  		recuperar_essa_execucao: "Do you want to recover this execution?",
			  	},
			  	es: {
			  		recuperar_essa_execucao: "¿Quieres recuperar esta ejecución?",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperar_esses_exercicios: "Deseja recuperar esses exercícios?",
			  	},
			  	en: {
			  		recuperar_esses_exercicios: "Do you want to recover these exercises?",
			  	},
			  	es: {
			  		recuperar_esses_exercicios: "¿Quieres recuperar estos ejercicios?",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperar_esse_exercicio: "Deseja recuperar esse exercício?",
			  	},
			  	en: {
			  		recuperar_esse_exercicio: "Do you want to retrieve this exercise?",
			  	},
			  	es: {
			  		recuperar_esse_exercicio: "¿Quieres recuperar este ejercicio?",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperar_estas_contas: "Deseja recuperar essas contas?",
			  	},
			  	en: {
			  		recuperar_estas_contas: "Do you want to recover these accounts?",
			  	},
			  	es: {
			  		recuperar_estas_contas: "¿Quieres recuperar estas cuentas?",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperar_esta_conta: "Deseja recuperar essa conta?",
			  	},
			  	en: {
			  		recuperar_esta_conta: "Do you want to recover this account?",
			  	},
			  	es: {
			  		recuperar_esta_conta: "¿Quieres recuperar esta cuenta?",
			  	}
			  },
		
			  {
			  	pt: {
			  		dado_paciente: "Dado do paciente",
			  	},
			  	en: {
			  		dado_paciente: "Patient data",
			  	},
			  	es: {
			  		dado_paciente: "Dato del paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		dados_paciente: "Dados do paciente",
			  	},
			  	en: {
			  		dados_paciente: "Patient data",
			  	},
			  	es: {
			  		dados_paciente: "Datos del paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		execucoes: "Execuções",
			  	},
			  	en: {
			  		execucoes: "Executions",
			  	},
			  	es: {
			  		execucoes: "Ejecuciones",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhum_exercicio_excluido: "Nenhum exercício excluído",
			  	},
			  	en: {
			  		nenhum_exercicio_excluido: "No exercises excluded",
			  	},
			  	es: {
			  		nenhum_exercicio_excluido: "Ningún ejercicios excluido",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhum_excluido: "Nenhum protocolo excluído",
			  	},
			  	en: {
			  		nenhum_excluido: "No protocol excluded",
			  	},
			  	es: {
			  		nenhum_excluido: "Ningún protocolo excluido",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_nao_executado: "Protocolo não executado",
			  	},
			  	en: {
			  		protocolo_nao_executado: "Protocol not executed",
			  	},
			  	es: {
			  		protocolo_nao_executado: "Protocolo no ejecutado",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_criado_em: "Exercício criado em:",
			  	},
			  	en: {
			  		exercicio_criado_em: "Exercise created in:",
			  	},
			  	es: {
			  		exercicio_criado_em: "Ejercicio creado en:",
			  	}
			  },
		
			  {
			  	pt: {
			  		titulo_existe: "Título de exercício já existe",
			  	},
			  	en: {
			  		titulo_existe: "Exercise title already exists",
			  	},
			  	es: {
			  		titulo_existe: "El título del ejercicio ya existe",
			  	}
			  },
		
			  {
			  	pt: {
			  		no_encontrado: "Opa, nenhum paciente com esse nome!",
			  	},
			  	en: {
			  		no_encontrado: " Oops, no patients with that name!",
			  	},
			  	es: {
			  		no_encontrado: "¡Vaya, no hay pacientes con ese nombre!",
			  	}
			  },
		
			  {
			  	pt: {
			  		download_compartilhar: "Relatório baixado com sucesso",
			  	},
			  	en: {
			  		download_compartilhar: "Report downloaded successfully",
			  	},
			  	es: {
			  		download_compartilhar: "Informe descargado correctamente",
			  	}
			  },
		
			  {
			  	pt: {
			  		falha_smtp: "Falha de SMTP",
			  	},
			  	en: {
			  		falha_smtp: "SMTP failure",
			  	},
			  	es: {
			  		falha_smtp: "Fallo de SMTP",
			  	}
			  },
		
			  {
			  	pt: {
			  		email_enviado: "Email enviado",
			  	},
			  	en: {
			  		email_enviado: "Email sent",
			  	},
			  	es: {
			  		email_enviado: "Email enviado",
			  	}
			  },
		
			  {
			  	pt: {
			  		sem_dados_exportar: "Sem dados para exportar",
			  	},
			  	en: {
			  		sem_dados_exportar: "No data to export",
			  	},
			  	es: {
			  		sem_dados_exportar: "No hay datos para exportar",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_cidade_uf: "Cidade/UF",
			  	},
			  	en: {
			  		menu_lateral_cidade_uf: "City/State",
			  	},
			  	es: {
			  		menu_lateral_cidade_uf: "Ciudad/Estado",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_bairro: "Bairro",
			  	},
			  	en: {
			  		menu_lateral_bairro: "Neighborhood",
			  	},
			  	es: {
			  		menu_lateral_bairro: "Vecindario",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuicao_concluida: "Atribuição concluída",
			  	},
			  	en: {
			  		atribuicao_concluida: " Assignment completed",
			  	},
			  	es: {
			  		atribuicao_concluida: "Asignación completada",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuicao_falhou: "Atribuição falhou",
			  	},
			  	en: {
			  		atribuicao_falhou: "Assignment failed",
			  	},
			  	es: {
			  		atribuicao_falhou: "Asignación fallida",
			  	}
			  },
		
			  {
			  	pt: {
			  		nome_muito_curto: "Nome muito curto, o nome deve conter de 3 a 30 caracteres.",
			  	},
			  	en: {
			  		nome_muito_curto: "Very short name, the name should contain 3 to 30 characters.",
			  	},
			  	es: {
			  		nome_muito_curto: "Nombre muy corto, el nombre debe contener entre 3 y 30 caracteres.",
			  	}
			  },
		
			  {
			  	pt: {
			  		sem_hemisferio: "Sem divisão",
			  	},
			  	en: {
			  		sem_hemisferio: "No division",
			  	},
			  	es: {
			  		sem_hemisferio: "Sin división",
			  	}
			  },
		
			  {
			  	pt: {
			  		confirm_excluir_tags: "Tem certeza que deseja excluir essas etiquetas?",
			  	},
			  	en: {
			  		confirm_excluir_tags: "Are you sure you want to delete these tags?",
			  	},
			  	es: {
			  		confirm_excluir_tags: "¿Está seguro de que desea eliminar estas etiquetas?",
			  	}
			  },
		
			  {
			  	pt: {
			  		etiqueta_existe: "Etiqueta já existe",
			  	},
			  	en: {
			  		etiqueta_existe: "Tag already exists",
			  	},
			  	es: {
			  		etiqueta_existe: "La etiqueta ya existe",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperar_todas_selecionadas: "RECUPERAR TODAS SELECIONADAS",
			  	},
			  	en: {
			  		recuperar_todas_selecionadas: "RECALL ALL SELECTED",
			  	},
			  	es: {
			  		recuperar_todas_selecionadas: " RECUPERAR TODOS LOS SELECCIONADOS",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperacao_falhou: "Recuperação falhou",
			  	},
			  	en: {
			  		recuperacao_falhou: "Recovery failed",
			  	},
			  	es: {
			  		recuperacao_falhou: "Recuperación fallida",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperacao_concluida: "Recuperação concluída",
			  	},
			  	en: {
			  		recuperacao_concluida: "Recovery completed",
			  	},
			  	es: {
			  		recuperacao_concluida: "Recuperación completada",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_paciente_desta_conta: "Email associado a um paciente desta conta",
			  	},
			  	en: {
			  		erro_paciente_desta_conta: "Email associated with a patient on this account",
			  	},
			  	es: {
			  		erro_paciente_desta_conta: "Correo electrónico asociado con un paciente en esta cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_avaliador_outra_conta: "Email associado a um avaliador de outra conta",
			  	},
			  	en: {
			  		erro_avaliador_outra_conta: "Email associated with an evaluator from another account",
			  	},
			  	es: {
			  		erro_avaliador_outra_conta: "Correo electrónico asociado a un evaluador de otra cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_avaliador_desta_conta: "Email associado a um avaliador desta conta",
			  	},
			  	en: {
			  		erro_avaliador_desta_conta: "Email associated with an evaluator for this account",
			  	},
			  	es: {
			  		erro_avaliador_desta_conta: "Correo electrónico asociado a un evaluador de esta cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_usuario_outra_conta: "Email associado a um usuário de outra conta",
			  	},
			  	en: {
			  		erro_usuario_outra_conta: "Email associated with a user from another account",
			  	},
			  	es: {
			  		erro_usuario_outra_conta: "Correo electrónico asociado a un usuario de otra cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_usuario_desta_conta: "Email associado a um usuário desta conta.",
			  	},
			  	en: {
			  		erro_usuario_desta_conta: "Email associated with a user of this account.",
			  	},
			  	es: {
			  		erro_usuario_desta_conta: "Correo electrónico asociado a un usuario de esta cuenta.",
			  	}
			  },
		
			  {
			  	pt: {
			  		cadastro_falhou: "Cadastro falhou",
			  	},
			  	en: {
			  		cadastro_falhou: "Registration failed",
			  	},
			  	es: {
			  		cadastro_falhou: "Registro fallido",
			  	}
			  },
		
			  {
			  	pt: {
			  		cadastro_concluido: "Cadastro concluído",
			  	},
			  	en: {
			  		cadastro_concluido: "Registration completed",
			  	},
			  	es: {
			  		cadastro_concluido: "Registro completado",
			  	}
			  },
		
			  {
			  	pt: {
			  		desativacao_concluida: "Desativação concluída",
			  	},
			  	en: {
			  		desativacao_concluida: "Deactivation completed",
			  	},
			  	es: {
			  		desativacao_concluida: "Desactivación completada",
			  	}
			  },
		
			  {
			  	pt: {
			  		edicao_completa: "Edição concluída",
			  	},
			  	en: {
			  		edicao_completa: "Editing completed",
			  	},
			  	es: {
			  		edicao_completa: "Edición completada",
			  	}
			  },
		
			  {
			  	pt: {
			  		edicao_falhou: "Edição falhou",
			  	},
			  	en: {
			  		edicao_falhou: "Edit failed",
			  	},
			  	es: {
			  		edicao_falhou: "Error al editar",
			  	}
			  },
		
			  {
			  	pt: {
			  		adicao_concluida: "Adição concluída",
			  	},
			  	en: {
			  		adicao_concluida: "Addition completed",
			  	},
			  	es: {
			  		adicao_concluida: "Adición completada",
			  	}
			  },
		
			  {
			  	pt: {
			  		adicao_falhou: "Adição falhou",
			  	},
			  	en: {
			  		adicao_falhou: "Addition failed",
			  	},
			  	es: {
			  		adicao_falhou: "Adición fallida",
			  	}
			  },
		
			  {
			  	pt: {
			  		falha_guardar_cartao: "Falha ao armazenar cartão",
			  	},
			  	en: {
			  		falha_guardar_cartao: "Failed to save card",
			  	},
			  	es: {
			  		falha_guardar_cartao: "No se pudo almacenar la tarjeta",
			  	}
			  },
		
			  {
			  	pt: {
			  		cartao_invalido: "Dados de cartão inválidos",
			  	},
			  	en: {
			  		cartao_invalido: "Invalid card data",
			  	},
			  	es: {
			  		cartao_invalido: "Datos de la tarjeta no válidos",
			  	}
			  },
		
			  {
			  	pt: {
			  		cartao_armazenado: "Cartão armazenado",
			  	},
			  	en: {
			  		cartao_armazenado: "Saved card",
			  	},
			  	es: {
			  		cartao_armazenado: " Tarjeta almacenada",
			  	}
			  },
		
			  {
			  	pt: {
			  		atualizacao_falhou2: "Atualização falhou",
			  	},
			  	en: {
			  		atualizacao_falhou2: "Update failed",
			  	},
			  	es: {
			  		atualizacao_falhou2: "Actualización fallida",
			  	}
			  },
		
			  {
			  	pt: {
			  		personalizacao_concluida: "Personalização concluída",
			  	},
			  	en: {
			  		personalizacao_concluida: " Customization completed",
			  	},
			  	es: {
			  		personalizacao_concluida: " Personalización completada",
			  	}
			  },
		
			  {
			  	pt: {
			  		falha_personalizacao: "Falha na personalização",
			  	},
			  	en: {
			  		falha_personalizacao: " Customization failed",
			  	},
			  	es: {
			  		falha_personalizacao: "Falló la personalización",
			  	}
			  },
		
			  {
			  	pt: {
			  		edicao_erro_5: "Email associado a um paciente desta conta",
			  	},
			  	en: {
			  		edicao_erro_5: "Email associated with a patient on this account",
			  	},
			  	es: {
			  		edicao_erro_5: "Correo electrónico asociado con un paciente en esta cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		edicao_erro_4: "Email associado a um avaliador de outra conta",
			  	},
			  	en: {
			  		edicao_erro_4: "Email associated with an evaluator from another account",
			  	},
			  	es: {
			  		edicao_erro_4: "Correo electrónico asociado a un evaluador de otra cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		edicao_erro_3: "Email associado a um avaliador desta conta",
			  	},
			  	en: {
			  		edicao_erro_3: "Email associated with an evaluator for this account",
			  	},
			  	es: {
			  		edicao_erro_3: "Correo electrónico asociado a un evaluador de esta cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		edicao_erro_2: "Email associado a um usuário de outra conta",
			  	},
			  	en: {
			  		edicao_erro_2: "Email associated with a user from another account",
			  	},
			  	es: {
			  		edicao_erro_2: "Correo electrónico asociado a un usuario de otra cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		edicao_erro_1: "Email associado a um usuário desta conta",
			  	},
			  	en: {
			  		edicao_erro_1: "Email associated with a user of this account",
			  	},
			  	es: {
			  		edicao_erro_1: "Correo electrónico asociado a un usuario de esta cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		edicao_concluida: "Edição concluída",
			  	},
			  	en: {
			  		edicao_concluida: "Editing completed",
			  	},
			  	es: {
			  		edicao_concluida: "Edición completada",
			  	}
			  },
		
			  {
			  	pt: {
			  		senha_incorreta: "Senha não confere",
			  	},
			  	en: {
			  		senha_incorreta: "Password does not match",
			  	},
			  	es: {
			  		senha_incorreta: "Las contraseñas no coinciden",
			  	}
			  },
		
			  {
			  	pt: {
			  		alteracao_concluida: "Alteração concluída",
			  	},
			  	en: {
			  		alteracao_concluida: "Change completed",
			  	},
			  	es: {
			  		alteracao_concluida: "Cambio completado",
			  	}
			  },
		
			  {
			  	pt: {
			  		maximo: "Máximo",
			  	},
			  	en: {
			  		maximo: "Maximum",
			  	},
			  	es: {
			  		maximo: "Máximo",
			  	}
			  },
		
			  {
			  	pt: {
			  		data: "Data",
			  	},
			  	en: {
			  		data: "Date",
			  	},
			  	es: {
			  		data: "Fecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		posicao_dinamico: "Posição de treino dinâmico",
			  	},
			  	en: {
			  		posicao_dinamico: "Dynamic training position",
			  	},
			  	es: {
			  		posicao_dinamico: "Posición dinámica de entrenamiento",
			  	}
			  },
		
			  {
			  	pt: {
			  		posicao_isometria: "Posição para isometria",
			  	},
			  	en: {
			  		posicao_isometria: "Position for isometrics",
			  	},
			  	es: {
			  		posicao_isometria: "Posición para los isométricos",
			  	}
			  },
		
			  {
			  	pt: {
			  		success: "Sucesso",
			  	},
			  	en: {
			  		success: "Success",
			  	},
			  	es: {
			  		success: "Éxito",
			  	}
			  },
		
			  {
			  	pt: {
			  		config_tempo_contracao: "Tempo sobre tensão",
			  	},
			  	en: {
			  		config_tempo_contracao: "Time over tension",
			  	},
			  	es: {
			  		config_tempo_contracao: "Tiempo sobre tensión",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluir_exercicio_: "Excluir exercício",
			  	},
			  	en: {
			  		excluir_exercicio_: "Exclude exercise",
			  	},
			  	es: {
			  		excluir_exercicio_: "Excluir el ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		lista_de_exercicios_revisao: "Revisão",
			  	},
			  	en: {
			  		lista_de_exercicios_revisao: "Review",
			  	},
			  	es: {
			  		lista_de_exercicios_revisao: "Revisión",
			  	}
			  },
		
			  {
			  	pt: {
			  		seu_nome: "Seu nome",
			  	},
			  	en: {
			  		seu_nome: "Your name",
			  	},
			  	es: {
			  		seu_nome: "Su nombre",
			  	}
			  },
		
			  {
			  	pt: {
			  		atualizacao_falhou: "Atualização falhou. Tente novamente",
			  	},
			  	en: {
			  		atualizacao_falhou: "Update failed. Please try again",
			  	},
			  	es: {
			  		atualizacao_falhou: "Actualización fallida. Por favor, inténtelo de nuevo",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_sucesso: "Protocolo adicionado com sucesso!",
			  	},
			  	en: {
			  		protocolo_sucesso: "Protocol added successfully!",
			  	},
			  	es: {
			  		protocolo_sucesso: "¡Protocolo añadido con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		acrecenta_protocolos: "Acrescenta os protocolos sugeridos pela Kinology à lista de treinos do paciente",
			  	},
			  	en: {
			  		acrecenta_protocolos: "Adds the protocols suggested by Kinology to the patients training list",
			  	},
			  	es: {
			  		acrecenta_protocolos: "Añade los protocolos sugeridos por Kinology a la lista de entrenamiento del paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_sugerido: "Protocolo sugerido",
			  	},
			  	en: {
			  		protocolo_sugerido: "Suggested protocol",
			  	},
			  	es: {
			  		protocolo_sugerido: "Protocolos sugeridos",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao_possivel_desativar_exercicio: "Não foi possível desativar o(s) exercícios(s)",
			  	},
			  	en: {
			  		nao_possivel_desativar_exercicio: "Exercise(s) could not be deactivated",
			  	},
			  	es: {
			  		nao_possivel_desativar_exercicio: "No se ha podido desactivar el ejercicio(s)",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_interno: "Ops, ocorreu um erro interno",
			  	},
			  	en: {
			  		erro_interno: "Oops, an internal error occurs",
			  	},
			  	es: {
			  		erro_interno: "Vaya, se produjo un error interno",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercise_delete_success: "Exercício(s) excluídos com sucesso!",
			  	},
			  	en: {
			  		exercise_delete_success: "Exercise(s) successfully deleted!",
			  	},
			  	es: {
			  		exercise_delete_success: "¡Ejercicio(s) eliminado(s) con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		falha_protocolo: "Falha na criação do exercício",
			  	},
			  	en: {
			  		falha_protocolo: "Failed exercise creation",
			  	},
			  	es: {
			  		falha_protocolo: "Fallo en la creación del ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao_possivel_desativar_protocolo: "Não foi possível desativar o(s) protocolo(s)",
			  	},
			  	en: {
			  		nao_possivel_desativar_protocolo: "Unable to deactivate protocol(s)",
			  	},
			  	es: {
			  		nao_possivel_desativar_protocolo: "No se puede desactivar el protocolo(s)",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_ja_atualizado: "Perfil já está atualizado",
			  	},
			  	en: {
			  		perfil_ja_atualizado: "Profile is already updated",
			  	},
			  	es: {
			  		perfil_ja_atualizado: "El perfil ya está actualizado",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_atulizado_sucesso: "Perfil atualizado com sucesso",
			  	},
			  	en: {
			  		perfil_atulizado_sucesso: "Profile successfully updated",
			  	},
			  	es: {
			  		perfil_atulizado_sucesso: "Perfil actualizado con éxito",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_concluido_compartilhar: "Protocolo concluído. COMPARTILHAR AGORA",
			  	},
			  	en: {
			  		protocolo_concluido_compartilhar: "Protocol concluded. SHARE NOW",
			  	},
			  	es: {
			  		protocolo_concluido_compartilhar: "Protocolo concluido. COMPARTIR",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluir_exercicio: "Excluir o(s) exercício(s) selecionado(s)?",
			  	},
			  	en: {
			  		excluir_exercicio: "Delete the selected exercise(s)?",
			  	},
			  	es: {
			  		excluir_exercicio: "¿Eliminar los ejercicios seleccionados?",
			  	}
			  },
		
			  {
			  	pt: {
			  		hemisferio_esquerdo: "Esquerdo",
			  	},
			  	en: {
			  		hemisferio_esquerdo: "Left",
			  	},
			  	es: {
			  		hemisferio_esquerdo: "Izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		hemisferio_direito: "Direito",
			  	},
			  	en: {
			  		hemisferio_direito: "Right",
			  	},
			  	es: {
			  		hemisferio_direito: "Derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		paciente_resgatado: "Paciente resgatado com sucesso",
			  	},
			  	en: {
			  		paciente_resgatado: "Patient successfully rescued",
			  	},
			  	es: {
			  		paciente_resgatado: "Paciente rescatado con éxito",
			  	}
			  },
		
			  {
			  	pt: {
			  		dispositivo_n_conectado_internet_recuperar: "Não foi possível recuperar o usuário pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		dispositivo_n_conectado_internet_recuperar: "The user could not be recovered because the device is not connected to the Internet",
			  	},
			  	es: {
			  		dispositivo_n_conectado_internet_recuperar: "No se ha podido recuperar el usuario porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		dispositivo_n_conectado_internet_desativar: "Não foi possível desativar o usuário pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		dispositivo_n_conectado_internet_desativar: "Unable to deactivate user because the device is not connected to the Internet",
			  	},
			  	es: {
			  		dispositivo_n_conectado_internet_desativar: "No se puede desactivar el usuario porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		dispositivo_n_conectado_internet: "Não foi possível concluir o cadastro do avaliador pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		dispositivo_n_conectado_internet: "Evaluator registration could not be completed as the device is not connected to the Internet",
			  	},
			  	es: {
			  		dispositivo_n_conectado_internet: "No ha sido posible completar el registro del evaluador porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		pc_editado_sucesso: "Paciente editado com sucesso!",
			  	},
			  	en: {
			  		pc_editado_sucesso: "Patient edited successfully!",
			  	},
			  	es: {
			  		pc_editado_sucesso: "¡Paciente editado con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		add_pc_aval_erro_5: "Email associado a um paciente desta conta",
			  	},
			  	en: {
			  		add_pc_aval_erro_5: "Email associated with a patient from this account",
			  	},
			  	es: {
			  		add_pc_aval_erro_5: "Correo electrónico asociado a un paciente en esta cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		add_pc_aval_erro_4: "Email associado a um avaliador de outra conta",
			  	},
			  	en: {
			  		add_pc_aval_erro_4: "Email associated with an evaluator from another account",
			  	},
			  	es: {
			  		add_pc_aval_erro_4: "Correo electrónico asociado a un revisor de otra cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		add_pc_aval_erro_3: "Email associado a um avaliador desta conta",
			  	},
			  	en: {
			  		add_pc_aval_erro_3: "Email associated to an evaluator of this account",
			  	},
			  	es: {
			  		add_pc_aval_erro_3: "Correo electrónico asociado a un evaluador de esta cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		add_pc_aval_erro_2: "Email associado a um usuário de outra conta",
			  	},
			  	en: {
			  		add_pc_aval_erro_2: "Email associated with a user from another account",
			  	},
			  	es: {
			  		add_pc_aval_erro_2: "Correo electrónico asociado a un usuario de otra cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		add_pc_aval_erro_1: "Email associado a um usuário desta conta.",
			  	},
			  	en: {
			  		add_pc_aval_erro_1: "Email associated with a user of this account.",
			  	},
			  	es: {
			  		add_pc_aval_erro_1: "Correo electrónico asociado a un usuario de esta cuenta.",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao_autorizado: "Não autorizado!",
			  	},
			  	en: {
			  		nao_autorizado: "Not allowed!",
			  	},
			  	es: {
			  		nao_autorizado: "¡No está permitido!",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_servidor: "Erro interno do servidor",
			  	},
			  	en: {
			  		erro_servidor: "Internal server error",
			  	},
			  	es: {
			  		erro_servidor: "Error interno del servidor",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_processar_solicitacao: "Não conseguimos processar a solicitação",
			  	},
			  	en: {
			  		erro_processar_solicitacao: "The name of this exercise already exists.",
			  	},
			  	es: {
			  		erro_processar_solicitacao: "El nombre de este ejercicio ya existe.",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_nome_existe: "O nome desse exercício já existe.",
			  	},
			  	en: {
			  		exercicio_nome_existe: "The name of this exercise already exists.",
			  	},
			  	es: {
			  		exercicio_nome_existe: "El nombre de este ejercicio ya existe.",
			  	}
			  },
		
			  {
			  	pt: {
			  		falha_exercicio: "Falha na criação do exercício.",
			  	},
			  	en: {
			  		falha_exercicio: "Failed to create the exercise.",
			  	},
			  	es: {
			  		falha_exercicio: "No se ha podido crear el ejercicio.",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_sucesso: "Exercício criado com sucesso!",
			  	},
			  	en: {
			  		exercicio_sucesso: "Exercise created successfully!",
			  	},
			  	es: {
			  		exercicio_sucesso: "¡Ejercicio creado con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_calibrar_legenda: "A calibragem do Dinamômetro é efetuada para regular o peso executado",
			  	},
			  	en: {
			  		menu_calibrar_legenda: "Dynamometer calibration is performed to regulate the weight performed",
			  	},
			  	es: {
			  		menu_calibrar_legenda: "La calibración del dinamómetro se realiza para regular el peso realizado",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_my_etiquetas: "Minhas etiquetas",
			  	},
			  	en: {
			  		menu_lateral_my_etiquetas: "My tags",
			  	},
			  	es: {
			  		menu_lateral_my_etiquetas: "Mis etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		da_sua_lixeira: "da sua lixeira?",
			  	},
			  	en: {
			  		da_sua_lixeira: "from your trash can?",
			  	},
			  	es: {
			  		da_sua_lixeira: "de su contenedor?",
			  	}
			  },
		
			  {
			  	pt: {
			  		lixeira: "Lixeira",
			  	},
			  	en: {
			  		lixeira: "Trashcan",
			  	},
			  	es: {
			  		lixeira: "Papelera",
			  	}
			  },
		
			  {
			  	pt: {
			  		terminei_execucao: "Terminei a execução",
			  	},
			  	en: {
			  		terminei_execucao: "I finished the execution",
			  	},
			  	es: {
			  		terminei_execucao: "He terminado la ejecución",
			  	}
			  },
		
			  {
			  	pt: {
			  		cadastro_sem_internet: "Não foi possível concluir o cadastro do paciente pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		cadastro_sem_internet: "Unable to complete patient registration because the device is not connected to the Internet",
			  	},
			  	es: {
			  		cadastro_sem_internet: "No se puede completar el registro del paciente porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_contagem_preparar2: "Vai!",
			  	},
			  	en: {
			  		modal_contagem_preparar2: "Go!",
			  	},
			  	es: {
			  		modal_contagem_preparar2: "Ir!",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhum: "Nenhum",
			  	},
			  	en: {
			  		nenhum: "No",
			  	},
			  	es: {
			  		nenhum: "No",
			  	}
			  },
		
			  {
			  	pt: {
			  		sugerido: "Sugerido",
			  	},
			  	en: {
			  		sugerido: "Suggested",
			  	},
			  	es: {
			  		sugerido: "Sugerencia",
			  	}
			  },
		
			  {
			  	pt: {
			  		sugerir_outro: "Sugerir outro:",
			  	},
			  	en: {
			  		sugerir_outro: "Suggest another:",
			  	},
			  	es: {
			  		sugerir_outro: "Sugiere otra:",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_sugerido: "Exercício sugerido",
			  	},
			  	en: {
			  		exercicio_sugerido: "Suggested exercise",
			  	},
			  	es: {
			  		exercicio_sugerido: "Ejercicio sugerido",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolhar_referencia_maxima: "Escolha a sua referência de força máxima",
			  	},
			  	en: {
			  		escolhar_referencia_maxima: "Choose your maximum strength reference",
			  	},
			  	es: {
			  		escolhar_referencia_maxima: "Elija su referencia de fuerza máxima",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuir_paciente_cond_fisica: "Atribuir os pacientes selecionados a condição física:",
			  	},
			  	en: {
			  		atribuir_paciente_cond_fisica: "Assign the selected patients to physical condition:",
			  	},
			  	es: {
			  		atribuir_paciente_cond_fisica: "Asignar los pacientes seleccionados a la condición física:",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuir_aval_ao_paciente: "Atribuir avaliador ao paciente:",
			  	},
			  	en: {
			  		atribuir_aval_ao_paciente: "Assign evaluator to patient:",
			  	},
			  	es: {
			  		atribuir_aval_ao_paciente: "Asigne un evaluador al paciente:",
			  	}
			  },
		
			  {
			  	pt: {
			  		time_2: "sob tensão",
			  	},
			  	en: {
			  		time_2: "under tension",
			  	},
			  	es: {
			  		time_2: "bajo tensión",
			  	}
			  },
		
			  {
			  	pt: {
			  		time_1: "Tempo",
			  	},
			  	en: {
			  		time_1: "Time",
			  	},
			  	es: {
			  		time_1: "Tiempo",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_as_etiquetas: "Escolha as etiquetas para listar os pacientes incluídos nelas:",
			  	},
			  	en: {
			  		escolha_as_etiquetas: "Choose the tags to list the patients included in them:",
			  	},
			  	es: {
			  		escolha_as_etiquetas: "Elija las etiquetas para enumerar los pacientes incluidos en ellas:",
			  	}
			  },
		
			  {
			  	pt: {
			  		preparar: "Preparar",
			  	},
			  	en: {
			  		preparar: "Prepare",
			  	},
			  	es: {
			  		preparar: "Prepare",
			  	}
			  },
		
			  {
			  	pt: {
			  		para_habilitar: "Para habilitar, clique no botão abaixo.",
			  	},
			  	en: {
			  		para_habilitar: "You need to enable access permissions to proceed.",
			  	},
			  	es: {
			  		para_habilitar: "You need to enable access permissions to proceed.",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuir_pacientes_selecionados: "Atribuir os pacientes selecionados a condição física:",
			  	},
			  	en: {
			  		atribuir_pacientes_selecionados: "Assign the selected patients to physical condition:",
			  	},
			  	es: {
			  		atribuir_pacientes_selecionados: "Asignar los pacientes seleccionados a la condición física:",
			  	}
			  },
		
			  {
			  	pt: {
			  		resgatar: "Resgatar",
			  	},
			  	en: {
			  		resgatar: "Rescue",
			  	},
			  	es: {
			  		resgatar: "Rescate",
			  	}
			  },
		
			  {
			  	pt: {
			  		informacoes_seram_excluidas: "As informações serão excluídas de todos os relatórios",
			  	},
			  	en: {
			  		informacoes_seram_excluidas: "The information will be deleted from all reports",
			  	},
			  	es: {
			  		informacoes_seram_excluidas: "La información se eliminará de todos los informes",
			  	}
			  },
		
			  {
			  	pt: {
			  		deseja_refazer_acao: "Clique se deseja desfazer a ação.",
			  	},
			  	en: {
			  		deseja_refazer_acao: "Click if you want to undo the action.",
			  	},
			  	es: {
			  		deseja_refazer_acao: "Haga clic si desea deshacer la acción.",
			  	}
			  },
		
			  {
			  	pt: {
			  		foi_excluido_sucesso: "foi excluído com sucesso!",
			  	},
			  	en: {
			  		foi_excluido_sucesso: "has been successfully deleted!",
			  	},
			  	es: {
			  		foi_excluido_sucesso: "ha sido eliminado con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		o_ponto: "O ponto ",
			  	},
			  	en: {
			  		o_ponto: "The point",
			  	},
			  	es: {
			  		o_ponto: "El punto",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio: "Exercício",
			  	},
			  	en: {
			  		exercicio: "Exercise",
			  	},
			  	es: {
			  		exercicio: "Ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		avaliação_alguma_pessoa: "Para isso é necessário já ter realizado alguma avaliação com esta pessoa.",
			  	},
			  	en: {
			  		avaliação_alguma_pessoa: "For this it is necessary to have already performed an evaluation with this person.",
			  	},
			  	es: {
			  		avaliação_alguma_pessoa: "Para ello es necesario haber realizado ya una evaluación con esta persona.",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_legend: "Você pode criar o protocolo baseado nas últimas avaliações, basta selecionar um dos percentuais que o Kinology atualiza os valores das cargas para você.",
			  	},
			  	en: {
			  		protocolo_legend: "You can create the protocol based on the latest evaluations, just select one of the percentages and Kinology updates the load values for you.",
			  	},
			  	es: {
			  		protocolo_legend: "Puede crear el protocolo basándose en las últimas evaluaciones, sólo tiene que seleccionar uno de los porcentajes y Kinology actualiza los valores de carga por usted.",
			  	}
			  },
		
			  {
			  	pt: {
			  		conectar_ative: "Para conectar ao Dinamômetro e realizar o exercício, por favor ative:",
			  	},
			  	en: {
			  		conectar_ative: " To connect to the Dynamometer and perform the exercise, please activate:",
			  	},
			  	es: {
			  		conectar_ative: " Para conectarse al Dinamómetro y realizar el ejercicio, por favor, actívelo:",
			  	}
			  },
		
			  {
			  	pt: {
			  		nenhum_paciente_presente: "Nenhum paciente presente na etiqueta",
			  	},
			  	en: {
			  		nenhum_paciente_presente: "No patient present on the label",
			  	},
			  	es: {
			  		nenhum_paciente_presente: "Ningún paciente presente en la etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamentos: "Pagamentos",
			  	},
			  	en: {
			  		pagamentos: "Payments",
			  	},
			  	es: {
			  		pagamentos: "Pagos",
			  	}
			  },
		
			  {
			  	pt: {
			  		meu_perfil: "Meu perfil",
			  	},
			  	en: {
			  		meu_perfil: "My Profile",
			  	},
			  	es: {
			  		meu_perfil: "Mi perfil",
			  	}
			  },
		
			  {
			  	pt: {
			  		digite_endereco: "Digite seu endereço",
			  	},
			  	en: {
			  		digite_endereco: "Enter your address",
			  	},
			  	es: {
			  		digite_endereco: "Introduzca su dirección",
			  	}
			  },
		
			  {
			  	pt: {
			  		demostra_evolucao: "Demonstra toda a evolução do paciente em um determinado período",
			  	},
			  	en: {
			  		demostra_evolucao: "Shows the patients entire evolution over a given period",
			  	},
			  	es: {
			  		demostra_evolucao: "Muestra la evolución completa del paciente durante un periodo determinado",
			  	}
			  },
		
			  {
			  	pt: {
			  		defina_unidade: "Defina a unidade de medida que será utilizada nos relatórios de avaliação e assimetria",
			  	},
			  	en: {
			  		defina_unidade: "Define the unit of measure that will be used in the evaluation and asymmetry reports",
			  	},
			  	es: {
			  		defina_unidade: "Definir la unidad de medida que se utilizará en la evaluación y informes de evaluación y asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		referencia_assimetria: "Referência de assimetria",
			  	},
			  	en: {
			  		referencia_assimetria: "Asymmetry reference",
			  	},
			  	es: {
			  		referencia_assimetria: "Referencia de asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		configs_demo_experiente: "Ative a demonstração e experimente todas as funcionalidades do app mesmo sem o dinamômetro",
			  	},
			  	en: {
			  		configs_demo_experiente: "Activate the demo and try all the features of the app even without the dynamometer",
			  	},
			  	es: {
			  		configs_demo_experiente: "Activa la demo y prueba todas las funciones de la aplicación incluso sin el dinamómetro",
			  	}
			  },
		
			  {
			  	pt: {
			  		insira_a_data_inicial: "Insira a data inicial",
			  	},
			  	en: {
			  		insira_a_data_inicial: "Enter the start date",
			  	},
			  	es: {
			  		insira_a_data_inicial: "Introduzca la fecha de inicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		criar_minha_conta: "Criar minha conta",
			  	},
			  	en: {
			  		criar_minha_conta: "Create my account",
			  	},
			  	es: {
			  		criar_minha_conta: "Crear mi cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		data_inicial: "Data Inicial",
			  	},
			  	en: {
			  		data_inicial: "Start Date",
			  	},
			  	es: {
			  		data_inicial: "Fecha de inicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		data_final_menor_inicial: "A data final não pode ser menor que a inicial.",
			  	},
			  	en: {
			  		data_final_menor_inicial: "The end date cannot be less than the start date.",
			  	},
			  	es: {
			  		data_final_menor_inicial: "La fecha de finalización no puede ser inferior a la fecha de inicio.",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_o_modo: "Escolha o modo de visualização do seu gráfico e o período que deseja analisar",
			  	},
			  	en: {
			  		escolha_o_modo: "Choose the visualization mode of your chart and the period you wish to analyze",
			  	},
			  	es: {
			  		escolha_o_modo: "Elija el modo de visualización de su gráfico y el período que desea analizar",
			  	}
			  },
		
			  {
			  	pt: {
			  		demostra_visualmente: "Demonstra visualmente a assimetria de cada agrupamento",
			  	},
			  	en: {
			  		demostra_visualmente: "Visually demonstrates the asymmetry of each cluster",
			  	},
			  	es: {
			  		demostra_visualmente: "Demuestra visualmente la asimetría de cada grupo",
			  	}
			  },
		
			  {
			  	pt: {
			  		assimetria: "Assimetria",
			  	},
			  	en: {
			  		assimetria: "Asymmetry",
			  	},
			  	es: {
			  		assimetria: "Asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		demostra_relacoes: "Demonstra a relação da força-tempo de cada exercício executado",
			  	},
			  	en: {
			  		demostra_relacoes: "Demonstrates the force-time relationship of each exercise performed",
			  	},
			  	es: {
			  		demostra_relacoes: "Demuestra la relación fuerza-tiempo de cada ejercicio realizado",
			  	}
			  },
		
			  {
			  	pt: {
			  		forca_tempo: "Força - Tempo",
			  	},
			  	en: {
			  		forca_tempo: "Force - Time",
			  	},
			  	es: {
			  		forca_tempo: "Fuerza - Tiempo",
			  	}
			  },
		
			  {
			  	pt: {
			  		historico_assimetria: "Histórico de Assimetria",
			  	},
			  	en: {
			  		historico_assimetria: "Historical Asymmetry",
			  	},
			  	es: {
			  		historico_assimetria: "Historia de la asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		relatorio_evolucao: "Relatório de Evolução",
			  	},
			  	en: {
			  		relatorio_evolucao: "Evolution Report",
			  	},
			  	es: {
			  		relatorio_evolucao: "Informe de progreso",
			  	}
			  },
		
			  {
			  	pt: {
			  		relatorio_forca_tempo: "Relatório de Força-tempo",
			  	},
			  	en: {
			  		relatorio_forca_tempo: "Force-Time Report",
			  	},
			  	es: {
			  		relatorio_forca_tempo: "Informe sobre la fuerza-tiempo",
			  	}
			  },
		
			  {
			  	pt: {
			  		baixe_pdf: "Baixe o arquivo em PDF",
			  	},
			  	en: {
			  		baixe_pdf: "Download the PDF file",
			  	},
			  	es: {
			  		baixe_pdf: "Descargar el archivo PDF",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolhar_como_compartilhar: "Escolha como quer compartilhar",
			  	},
			  	en: {
			  		escolhar_como_compartilhar: "Choose how you want to share",
			  	},
			  	es: {
			  		escolhar_como_compartilhar: "Elige cómo quieres compartir",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_data: "Escolha a data",
			  	},
			  	en: {
			  		escolha_data: "Choose the date",
			  	},
			  	es: {
			  		escolha_data: "Elige la fecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		data_final: "Data Final",
			  	},
			  	en: {
			  		data_final: "Final date",
			  	},
			  	es: {
			  		data_final: "Fecha final",
			  	}
			  },
		
			  {
			  	pt: {
			  		todo_periodo: "Todo o período",
			  	},
			  	en: {
			  		todo_periodo: "all period",
			  	},
			  	es: {
			  		todo_periodo: " Todo el período",
			  	}
			  },
		
			  {
			  	pt: {
			  		hoje: "Hoje",
			  	},
			  	en: {
			  		hoje: "Today",
			  	},
			  	es: {
			  		hoje: "Hoy dia",
			  	}
			  },
		
			  {
			  	pt: {
			  		formula_assimetria: "Fórmula coeficiente de assimetria:",
			  	},
			  	en: {
			  		formula_assimetria: "Asymmetry coefficient formula:",
			  	},
			  	es: {
			  		formula_assimetria: "Fórmula del coeficiente de asimetría:",
			  	}
			  },
		
			  {
			  	pt: {
			  		formula_desvio: "Fórmula desvio padrão:",
			  	},
			  	en: {
			  		formula_desvio: " Standard deviation formula:",
			  	},
			  	es: {
			  		formula_desvio: "Fórmula de desviación estándar:",
			  	}
			  },
		
			  {
			  	pt: {
			  		os_protocolos_exercicios: "Os protocolos e exercícios abaixo geram dados para o relatório de assimetria. Se o resultado não está aqui, verifique se realizou todos os exercícios",
			  	},
			  	en: {
			  		os_protocolos_exercicios: " The protocols and exercises below generate data for the asymmetry report. If the result is not here, check if you performed all the exercises",
			  	},
			  	es: {
			  		os_protocolos_exercicios: " Los protocolos y ejercicios siguientes generan datos para el informe de asimetría. Si el resultado no está aquí, verifique si realizó todos los ejercicios",
			  	}
			  },
		
			  {
			  	pt: {
			  		este_relatorio: "Este relatório mostra a Força Máxima e Força Média de todas as execuções em ordem cronológica. Clique em",
			  	},
			  	en: {
			  		este_relatorio: "This report shows the Maximum Strength and Average Strength of all runs in chronological order. click in",
			  	},
			  	es: {
			  		este_relatorio: "Este informe muestra la fuerza máxima y la fuerza media de todas las carreras en orden cronológico. clickea en",
			  	}
			  },
		
			  {
			  	pt: {
			  		selecionar_resultados: "Selecione os resultados que deseja analisar",
			  	},
			  	en: {
			  		selecionar_resultados: "Select the results you want to analyze",
			  	},
			  	es: {
			  		selecionar_resultados: "Seleccione los resultados que desea analizar",
			  	}
			  },
		
			  {
			  	pt: {
			  		sem_dados_suficientes: "Você ainda não tem dados suficientes para gerar o gráfico",
			  	},
			  	en: {
			  		sem_dados_suficientes: "You do not yet have enough data to generate the graph",
			  	},
			  	es: {
			  		sem_dados_suficientes: "Você ainda não tem dados suficientes para gerar o gráfico",
			  	}
			  },
			  {
				pt: {
					digite_aqui: "Digite aqui",
				},
				en: {
					digite_aqui: "Type here",
				},
				es: {
					digite_aqui: "Digite aquí",
				}
			  },
			  {
				pt: {
					questionario_respondido_success: "Questionário respondido com sucesso",
				},
				en: {
					questionario_respondido_success: "Questionnaire answered successfully",
				},
				es: {
					questionario_respondido_success: "Cuestionario respondido exitosamente",
				}
			  },
		
			  {
			  	pt: {
			  		digite_anotacao: "Digite aqui sua anotação",
			  	},
			  	en: {
			  		digite_anotacao: "Type your annotation here",
			  	},
			  	es: {
			  		digite_anotacao: "Introduzca aquí su anotación",
			  	}
			  },
		
			  {
			  	pt: {
			  		criar_anotacao: "Criar anotação",
			  	},
			  	en: {
			  		criar_anotacao: "Create Annotation",
			  	},
			  	es: {
			  		criar_anotacao: "Crear anotación",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_excluido_sucesso: "Protocolo excluído com sucesso!",
			  	},
			  	en: {
			  		protocolo_excluido_sucesso: "Protocol successfully deleted!",
			  	},
			  	es: {
			  		protocolo_excluido_sucesso: "¡Protocolo eliminado con éxito!",
			  	}
			  },
		
			  {
			  	pt: {
			  		sucesso: "Sucesso",
			  	},
			  	en: {
			  		sucesso: "Success",
			  	},
			  	es: {
			  		sucesso: "Éxito",
			  	}
			  },
		
			  {
			  	pt: {
			  		digite_abaixo_protocolo: "Digite abaixo o nome do seu protocolo",
			  	},
			  	en: {
			  		digite_abaixo_protocolo: "Which muscle will be affected by this exercise?",
			  	},
			  	es: {
			  		digite_abaixo_protocolo: "¿Qué músculo se verá afectado por este ejercicio?",
			  	}
			  },
		
			  {
			  	pt: {
			  		musculo_afetado: "Qual músculo será afetado por esse exercício?",
			  	},
			  	en: {
			  		musculo_afetado: "Which muscle will be affected by this exercise?",
			  	},
			  	es: {
			  		musculo_afetado: "¿Qué músculo se verá afectado por este ejercicio?",
			  	}
			  },
		
			  {
			  	pt: {
			  		digite_abaixo: "Digite abaixo o nome do seu exercício",
			  	},
			  	en: {
			  		digite_abaixo: "Type the name of your exercise below",
			  	},
			  	es: {
			  		digite_abaixo: "Introduzca el nombre de su ejercicio a continuación",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_percentual: "Escolha o percentual de RM do exercício",
			  	},
			  	en: {
			  		escolha_percentual: "Choose the MRI percentage of the exercise",
			  	},
			  	es: {
			  		escolha_percentual: "Elija el porcentaje de RM del ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		no_modo_de: "no modo de:",
			  	},
			  	en: {
			  		no_modo_de: "in the mode of:",
			  	},
			  	es: {
			  		no_modo_de: "no modo de:",
			  	}
			  },
		
			  {
			  	pt: {
			  		tempo_s: "Tempo (s)",
			  	},
			  	en: {
			  		tempo_s: "Time (s)",
			  	},
			  	es: {
			  		tempo_s: "Hora (s)",
			  	}
			  },
		
			  {
			  	pt: {
			  		media: "Média",
			  	},
			  	en: {
			  		media: "Medium",
			  	},
			  	es: {
			  		media: "Media",
			  	}
			  },
		
			  {
			  	pt: {
			  		livre: "Livre",
			  	},
			  	en: {
			  		livre: "Free",
			  	},
			  	es: {
			  		livre: "Gratis",
			  	}
			  },
		
			  {
			  	pt: {
			  		maximo_executado: "Máximo executado",
			  	},
			  	en: {
			  		maximo_executado: "Maximum performed",
			  	},
			  	es: {
			  		maximo_executado: "Máximo ejecutado",
			  	}
			  },
		
			  {
			  	pt: {
			  		series: "Séries",
			  	},
			  	en: {
			  		series: " Series",
			  	},
			  	es: {
			  		series: " Serie",
			  	}
			  },
		
			  {
			  	pt: {
			  		maxima: "Máxima",
			  	},
			  	en: {
			  		maxima: "maximum",
			  	},
			  	es: {
			  		maxima: "máximo",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_uma_opcao: "Escolha uma das opções *",
			  	},
			  	en: {
			  		escolha_uma_opcao: "Choose one of the options *",
			  	},
			  	es: {
			  		escolha_uma_opcao: "Elija una de las opciones *",
			  	}
			  },
		
			  {
			  	pt: {
			  		metros: "Metros",
			  	},
			  	en: {
			  		metros: "Meters",
			  	},
			  	es: {
			  		metros: "Metros",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_concluido: "Exercício concluído",
			  	},
			  	en: {
			  		exercicio_concluido: "Ejercicio completado",
			  	},
			  	es: {
			  		exercicio_concluido: "Exercise completed",
			  	}
			  },
		
			  {
			  	pt: {
			  		compartilhe_agora: "Compartilhe agora",
			  	},
			  	en: {
			  		compartilhe_agora: "Protocol concluded",
			  	},
			  	es: {
			  		compartilhe_agora: "Protocolo concluido",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_concluido: "Protocolo concluído",
			  	},
			  	en: {
			  		protocolo_concluido: "Protocol concluded",
			  	},
			  	es: {
			  		protocolo_concluido: "Protocolo concluido",
			  	}
			  },
		
			  {
			  	pt: {
			  		faltam: "Faltam",
			  	},
			  	en: {
			  		faltam: "Missing",
			  	},
			  	es: {
			  		faltam: "Falta",
			  	}
			  },
		
			  {
			  	pt: {
			  		clique_play: "Clique no play para executar o exercício:",
			  	},
			  	en: {
			  		clique_play: "Click play to perform the exercise:",
			  	},
			  	es: {
			  		clique_play: "Pulsa el play para realizar el ejercicio:",
			  	}
			  },
		
			  {
			  	pt: {
			  		tenha_acesso: "Tenha pacientes e exercícios ilimitados, e muito mais",
			  	},
			  	en: {
			  		tenha_acesso: "Get unlimited patients and exercises, and much more",
			  	},
			  	es: {
			  		tenha_acesso: "Obtenga pacientes y ejercicios ilimitados, y mucho más",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_seu_plano: "Escolha seu plano",
			  	},
			  	en: {
			  		escolha_seu_plano: "Choose your plan",
			  	},
			  	es: {
			  		escolha_seu_plano: "Elija su plan",
			  	}
			  },
		
			  {
			  	pt: {
			  		acesso_funcionalidade: "Tenha acesso à todas as funcionalidades do APP!!",
			  	},
			  	en: {
			  		acesso_funcionalidade: "Get access to all the functionalities of the APP!",
			  	},
			  	es: {
			  		acesso_funcionalidade: "¡Accede a todas las funcionalidades de la APP!",
			  	}
			  },
		
			  {
			  	pt: {
			  		conheca_modo_demo: "Conheça nosso modo demo",
			  	},
			  	en: {
			  		conheca_modo_demo: "Get to know our demo mode",
			  	},
			  	es: {
			  		conheca_modo_demo: "Descubra nuestro modo de demostración",
			  	}
			  },
		
			  {
			  	pt: {
			  		ola: "Olá,",
			  	},
			  	en: {
			  		ola: "Hello,",
			  	},
			  	es: {
			  		ola: "Hola,",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_menu_inferior_close: "Fechar",
			  	},
			  	en: {
			  		pag_inicial_menu_inferior_close: "Close",
			  	},
			  	es: {
			  		pag_inicial_menu_inferior_close: "Cerrar",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_infe_menu: "Menu",
			  	},
			  	en: {
			  		menu_infe_menu: "Menu",
			  	},
			  	es: {
			  		menu_infe_menu: "Menú",
			  	}
			  },
		
			  {
			  	pt: {
			  		iq_esquerdo: "I/Q Esquerdo",
			  	},
			  	en: {
			  		iq_esquerdo: " I/Q Left",
			  	},
			  	es: {
			  		iq_esquerdo: "I/Q  izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		iq_direito: "I/Q Direito",
			  	},
			  	en: {
			  		iq_direito: "I/Q Right",
			  	},
			  	es: {
			  		iq_direito: "I/Q derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		limpar_selecao: "Limpar seleção",
			  	},
			  	en: {
			  		limpar_selecao: "Clear selection",
			  	},
			  	es: {
			  		limpar_selecao: "Deseleccionar",
			  	}
			  },
		
			  {
			  	pt: {
			  		selecionar_todos: "Selecionar todos",
			  	},
			  	en: {
			  		selecionar_todos: "Select all",
			  	},
			  	es: {
			  		selecionar_todos: " Seleccionar todo",
			  	}
			  },
		
			  {
			  	pt: {
			  		forca_kg: "Força (Kg)",
			  	},
			  	en: {
			  		forca_kg: "Force (Kg)",
			  	},
			  	es: {
			  		forca_kg: "Fuerza (Kg)",
			  	}
			  },
		
			  {
			  	pt: {
			  		remover_dados: "Remover dados",
			  	},
			  	en: {
			  		remover_dados: "Remove data",
			  	},
			  	es: {
			  		remover_dados: "Eliminar datos",
			  	}
			  },
		
			  {
			  	pt: {
			  		grupos_musculares: "Ver grupos musculares",
			  	},
			  	en: {
			  		grupos_musculares: "View muscle groups",
			  	},
			  	es: {
			  		grupos_musculares: "Ver grupos de músculos",
			  	}
			  },
		
			  {
			  	pt: {
			  		ver_histórico: "Ver histórico",
			  	},
			  	en: {
			  		ver_histórico: "View history",
			  	},
			  	es: {
			  		ver_histórico: " Ver historial",
			  	}
			  },
		
			  {
			  	pt: {
			  		compartilhar: "Compartilhar",
			  	},
			  	en: {
			  		compartilhar: "Share",
			  	},
			  	es: {
			  		compartilhar: "Cuota",
			  	}
			  },
		
			  {
			  	pt: {
			  		adicionar_paciente: "Novo paciente",
			  	},
			  	en: {
			  		adicionar_paciente: "New patient",
			  	},
			  	es: {
			  		adicionar_paciente: "Nuevo paciente",
			  	}
			  },
			  {
				pt: {
					responder: "Responder",
				},
				en: {
					responder: "To respond",
				},
				es: {
					responder: "Responder",
				}
			  },
			  {
				pt: {
					respondido: "Já respondi",
				},
				en: {
					respondido: "Already answered",
				},
				es: {
					respondido: "Ya respondí",
				}
			  },
		
			  {
			  	pt: {
			  		lista_pacientes: "Lista de pacientes",
			  	},
			  	en: {
			  		lista_pacientes: "Patient list",
			  	},
			  	es: {
			  		lista_pacientes: "Lista de pacientes",
			  	}
			  },
		
			  {
			  	pt: {
			  		filtrar: "FILTRAR",
			  	},
			  	en: {
			  		filtrar: "FILTER",
			  	},
			  	es: {
			  		filtrar: "FILTRAR",
			  	}
			  },
		
			  {
			  	pt: {
			  		fazer_upgrade: "FAZER UPGRADE!",
			  	},
			  	en: {
			  		fazer_upgrade: " UPGRADE!",
			  	},
			  	es: {
			  		fazer_upgrade: "UPGRADE!",
			  	}
			  },
		
			  {
			  	pt: {
			  		texto_explicar_compartilhar: "Agora você pode compartilhar seus relatórios direto pelo whatsapp",
			  	},
			  	en: {
			  		texto_explicar_compartilhar: "Now you can share your reports directly via whatsapp",
			  	},
			  	es: {
			  		texto_explicar_compartilhar: "Ahora puedes compartir tus informes directamente a través de whatsapp",
			  	}
			  },
		
			  {
			  	pt: {
			  		novidade: "Já conhece a novidade?",
			  	},
			  	en: {
			  		novidade: "Already know the news?",
			  	},
			  	es: {
			  		novidade: "¿Ya conoces las novedades?",
			  	}
			  },
		
			  {
			  	pt: {
			  		muito_mais: "E muito mais",
			  	},
			  	en: {
			  		muito_mais: " And much more",
			  	},
			  	es: {
			  		muito_mais: "Es mucho más",
			  	}
			  },
		
			  {
			  	pt: {
			  		dez_administradores: "Tenha até 10 administradores",
			  	},
			  	en: {
			  		dez_administradores: "Have up to 10 administrators",
			  	},
			  	es: {
			  		dez_administradores: "Tener hasta 10 administradores",
			  	}
			  },
		
			  {
			  	pt: {
			  		gere_relatorios: "Gere relatórios",
			  	},
			  	en: {
			  		gere_relatorios: "Generate reports",
			  	},
			  	es: {
			  		gere_relatorios: "Generar informes",
			  	}
			  },
		
			  {
			  	pt: {
			  		pacientes_ilimitados: "Tenha pacientes e exercícios ilimitados",
			  	},
			  	en: {
			  		pacientes_ilimitados: "Have unlimited patients and exercise",
			  	},
			  	es: {
			  		pacientes_ilimitados: "Tener pacientes y ejercicio ilimitados",
			  	}
			  },
		
			  {
			  	pt: {
			  		conheça_plano_ouro: "Conheça o Plano Ouro!",
			  	},
			  	en: {
			  		conheça_plano_ouro: "Discover the Ouro Plan!",
			  	},
			  	es: {
			  		conheça_plano_ouro: "¡Descubra el Plan Ouro!",
			  	}
			  },
		
			  {
			  	pt: {
			  		problema_pagamento_assinatura: "Identificamos um problema com o pagamento da sua assinatura.",
			  	},
			  	en: {
					problema_pagamento_assinatura: "We have identified an issue with your subscription payment.",
			  	},
			  	es: {
					problema_pagamento_assinatura: "Hemos identificado un problema con el pago de su suscripción.",
			  	}
			  },
			  
			  {
			  	pt: {
			  		normalize_situacao: "Por favor normalize sua situação para continuar usando a plataforma e o dinamômetro Kinology.",
			  	},
			  	en: {
					normalize_situacao: "Please normalize your situation to continue using the Kinology platform and dynamometer.",
			  	},
			  	es: {
					normalize_situacao: "Por favor, normalice su situación para continuar usando la plataforma y el dinamómetro Kinology.",
			  	}
			  },
		
			  {
			  	pt: {
			  		editar_avaliador: "Editar avaliador",
			  	},
			  	en: {
			  		editar_avaliador: "Edit rater",
			  	},
			  	es: {
			  		editar_avaliador: "Editar evaluador",
			  	}
			  },

			  {
			  	pt: {
			  		limite_cadastro: "Limite de cadastros atingido",
			  	},
			  	en: {
			  		limite_cadastro: "Registration Limit Reached",
			  	},
			  	es: {
			  		limite_cadastro: "Límite de registro alcanzado",
			  	}
			  },
			  
			  {
			  	pt: {
			  		voce_chegou_ao_limite: "Você chegou ao limite máximo de cadastros. Para continuar aproveitando todas as funcionalidades, atualize o seu plano.",
			  	},
			  	en: {
			  		voce_chegou_ao_limite: "You have reached the maximum registration limit. To continue enjoying all the features, upgrade your plan.",
			  	},
			  	es: {
			  		voce_chegou_ao_limite: "Ha alcanzado el límite máximo de registro. Para seguir disfrutando de todas las funciones, actualiza tu plan.",
			  	}
			  },
		
			  {
			  	pt: {
			  		editar_paciente: "Editar paciente",
			  	},
			  	en: {
			  		editar_paciente: "Edit patient",
			  	},
			  	es: {
			  		editar_paciente: "Editar paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		data_invalida: "Data inválida",
			  	},
			  	en: {
			  		data_invalida: "Invalid date",
			  	},
			  	es: {
			  		data_invalida: "Fecha invalida",
			  	}
			  },
		
			  {
			  	pt: {
			  		apenas_numeros: "Apenas números",
			  	},
			  	en: {
			  		apenas_numeros: "Only numbers",
			  	},
			  	es: {
			  		apenas_numeros: "Sólo números",
			  	}
			  },
		
			  {
			  	pt: {
			  		lembre_login: "Lembre meu login",
			  	},
			  	en: {
			  		lembre_login: "Remember my login",
			  	},
			  	es: {
			  		lembre_login: "Recuerda mi nombre de usuario",
			  	}
			  },
		
			  {
			  	pt: {
			  		inicie_os_trabalhos: "Entre na sua conta e comece os trabalhos",
			  	},
			  	en: {
			  		inicie_os_trabalhos: "Log in to your account and start work",
			  	},
			  	es: {
			  		inicie_os_trabalhos: "Inicie sesión en su cuenta y comience a trabajar",
			  	}
			  },
		
			  {
			  	pt: {
			  		cpf_invalido: "CPF inválido",
			  	},
			  	en: {
			  		cpf_invalido: "Invalid RNE",
			  	},
			  	es: {
			  		cpf_invalido: "RNE no válida",
			  	}
			  },
		
			  {
			  	pt: {
			  		editar_protocolo: "Editar protocolo",
			  	},
			  	en: {
			  		editar_protocolo: " Edit protocol",
			  	},
			  	es: {
			  		editar_protocolo: "Editar protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolos_e_exercicios: "Protocolos e exercícios",
			  	},
			  	en: {
			  		protocolos_e_exercicios: "Protocols and exercises",
			  	},
			  	es: {
			  		protocolos_e_exercicios: "Protocolos y ejercicios",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_compartilhamento_sucesso: "Relatório enviado com sucesso para o e-mail informado",
			  	},
			  	en: {
			  		modal_compartilhamento_sucesso: " Report successfully sent to the email provided",
			  	},
			  	es: {
			  		modal_compartilhamento_sucesso: "Informe enviado correctamente al correo electrónico proporcionado",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_compartilhamento_baixar: "BAIXE EM PDF",
			  	},
			  	en: {
			  		modal_compartilhamento_baixar: "DOWNLOAD IN PDF",
			  	},
			  	es: {
			  		modal_compartilhamento_baixar: "DESCARGAR PDF",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_compartilhamento_ou: "ou",
			  	},
			  	en: {
			  		modal_compartilhamento_ou: "or",
			  	},
			  	es: {
			  		modal_compartilhamento_ou: "o",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_compartilhamento: "Escolha o email que será enviado",
			  	},
			  	en: {
			  		modal_compartilhamento: "Choose the email that will be sent",
			  	},
			  	es: {
			  		modal_compartilhamento: "Elija el correo electrónico que se enviará",
			  	}
			  },
		
			  {
			  	pt: {
			  		forca_tempo_explicacao: "Demonstra a relação da força-tempo de cada exercício executado",
			  	},
			  	en: {
			  		forca_tempo_explicacao: "Demonstrates the strength-time relationship of each exercise performed",
			  	},
			  	es: {
			  		forca_tempo_explicacao: "Demuestra la relación fuerza-tiempo de cada ejercicio realizado.",
			  	}
			  },
		
			  {
			  	pt: {
			  		assimetria_explicacao: "Demonstra visualmente a assimetria de cada agrupamento",
			  	},
			  	en: {
			  		assimetria_explicacao: "Visually demonstrates the asymmetry of each cluster",
			  	},
			  	es: {
			  		assimetria_explicacao: " Demuestra visualmente la asimetría de cada grupo.",
			  	}
			  },
		
			  {
			  	pt: {
			  		evolucao_explicacao: "Demonstra toda a evolução do paciente em um determinado período",
			  	},
			  	en: {
			  		evolucao_explicacao: "Demonstrates the entire evolution of the patient in a given period",
			  	},
			  	es: {
			  		evolucao_explicacao: " Demuestra toda la evolución del paciente en un período determinado.",
			  	}
			  },
		
			  {
			  	pt: {
			  		evolucao: "Evolução",
			  	},
			  	en: {
			  		evolucao: "Evolution",
			  	},
			  	es: {
			  		evolucao: "Evolución",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_compartilhar_relatorio3: "Período da avaliação",
			  	},
			  	en: {
			  		modal_compartilhar_relatorio3: "Evaluation period",
			  	},
			  	es: {
			  		modal_compartilhar_relatorio3: "Periodo de evaluacion",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_compartilhar_relatorio2: "Tipo de relatório",
			  	},
			  	en: {
			  		modal_compartilhar_relatorio2: "Report type",
			  	},
			  	es: {
			  		modal_compartilhar_relatorio2: "Tipo de informe",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_compartilhar_relatorio: "Escolha as informações a serem compartilhadas",
			  	},
			  	en: {
			  		modal_compartilhar_relatorio: "Choose the information to share",
			  	},
			  	es: {
			  		modal_compartilhar_relatorio: "Elige la información para compartir",
			  	}
			  },
			  {
			  	pt: {
			  		data_final_maior_que_atual: "A data final não pode ser maior que a atual.",
			  	},
			  	en: {
			  		data_final_maior_que_atual: "The end date cannot be greater than the current date.",
			  	},
			  	es: {
			  		data_final_maior_que_atual: "La fecha de finalización no puede ser mayor que la fecha actual.",
			  	}
			  },
		
			  {
			  	pt: {
			  		selecione_exercicios: "Selecione os exercícios",
			  	},
			  	en: {
			  		selecione_exercicios: "Select exercises",
			  	},
			  	es: {
			  		selecione_exercicios: "Seleccionar ejercicios",
			  	}
			  },
		
			  {
			  	pt: {
			  		continuar: "CONTINUAR",
			  	},
			  	en: {
			  		continuar: "CONTINUE",
			  	},
			  	es: {
			  		continuar: "SEGUIR",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_duvida_execucao_resultado3: "É a média obtida dos picos",
			  	},
			  	en: {
			  		modal_duvida_execucao_resultado3: "It is the average obtained from the peaks",
			  	},
			  	es: {
			  		modal_duvida_execucao_resultado3: "Es el promedio obtenido de los picos",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_duvida_execucao_resultado2: "Quantidade de vezes que o exercício foi executado",
			  	},
			  	en: {
			  		modal_duvida_execucao_resultado2: "Number of times the exercise was performed",
			  	},
			  	es: {
			  		modal_duvida_execucao_resultado2: "Número de veces que se realizó el ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_duvida_execucao_resultado: "É o maior valor obtido nos picos",
			  	},
			  	en: {
			  		modal_duvida_execucao_resultado: " It is the highest value obtained in the peaks",
			  	},
			  	es: {
			  		modal_duvida_execucao_resultado: "É o maior valor obtido nos picos",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_duvida_relatorio2: "Cadastro realizado com sucesso. Por favor, entre no seu e-mail para liberar o acesso do aplicativo",
			  	},
			  	en: {
			  		modal_duvida_relatorio2: "Registration successful. Please enter your email to grant access to the app",
			  	},
			  	es: {
			  		modal_duvida_relatorio2: "Registro exitoso. Ingrese su correo electrónico para otorgar acceso a la aplicación",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_duvida_relatorio: "Exercícios que faltam ser executados para gerar o Relatório de Assimetria",
			  	},
			  	en: {
			  		modal_duvida_relatorio: "Exercises remaining to be performed to generate the Asymmetry Report",
			  	},
			  	es: {
			  		modal_duvida_relatorio: "Ejercicios restantes por realizar para generar el Informe de Asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		relatorio_assimetria: "Relatório Assimetria",
			  	},
			  	en: {
			  		relatorio_assimetria: "Asymmetry Report",
			  	},
			  	es: {
			  		relatorio_assimetria: "Informe de Asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_configurar_execucao2: "Escolha o percentual de RM do exercício",
			  	},
			  	en: {
			  		modal_configurar_execucao2: "Choose the MR percentage of the exercise",
			  	},
			  	es: {
			  		modal_configurar_execucao2: "Elija el porcentaje de RM del ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_configurar_execucao: "Escolha as configurações que deseja para a execução",
			  	},
			  	en: {
			  		modal_configurar_execucao: "Choose the settings you want for the run",
			  	},
			  	es: {
			  		modal_configurar_execucao: "Elija la configuración que desee para la carrera",
			  	}
			  },
		
			  {
			  	pt: {
			  		comecar: "COMEÇAR",
			  	},
			  	en: {
			  		comecar: "START",
			  	},
			  	es: {
			  		comecar: "COMENZAR",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_funcional_explicacao: "Executa séries de repetições a partir de RM ou prescrição",
			  	},
			  	en: {
			  		modal_funcional_explicacao: "Perform sets of repetitions from MR or prescription",
			  	},
			  	es: {
			  		modal_funcional_explicacao: "Realizar series de repeticiones de RM o prescripción",
			  	}
			  },
		
			  {
			  	pt: {
			  		avaliacao_explicacao: "Avalia repetições máximas (RM)",
			  	},
			  	en: {
			  		avaliacao_explicacao: "Evaluates maximum repetitions (MR)",
			  	},
			  	es: {
			  		avaliacao_explicacao: "Evalúa repeticiones máximas (RM)",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_contagem_preparar: "Preparar",
			  	},
			  	en: {
			  		modal_contagem_preparar: "Prepare",
			  	},
			  	es: {
			  		modal_contagem_preparar: "Preparar",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_nova_etiqueta: "Nova etiqueta",
			  	},
			  	en: {
			  		modal_nova_etiqueta: "New tag",
			  	},
			  	es: {
			  		modal_nova_etiqueta: "Nueva etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		abrir_arquivo: "Abrir arquivo",
			  	},
			  	en: {
			  		abrir_arquivo: "Open file",
			  	},
			  	es: {
			  		abrir_arquivo: "Abrir documento",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_atribuir_etiquetas2: "Pesquisar etiquetas",
			  	},
			  	en: {
			  		modal_atribuir_etiquetas2: "Search tags",
			  	},
			  	es: {
			  		modal_atribuir_etiquetas2: "Buscar etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_atribuir_etiquetas: "Selecione as etiquetas a serem colocadas",
			  	},
			  	en: {
			  		modal_atribuir_etiquetas: "Select the tags to be placed",
			  	},
			  	es: {
			  		modal_atribuir_etiquetas: "Seleccione las etiquetas que se colocarán",
			  	}
			  },
		
			  {
			  	pt: {
			  		btn_atribuir: "ATRIBUIR",
			  	},
			  	en: {
			  		btn_atribuir: "TO ASSIGN",
			  	},
			  	es: {
			  		btn_atribuir: "ASIGNAR",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_atribuir_avaliadores: "Atribuir avaliadores aos pacientes",
			  	},
			  	en: {
			  		modal_atribuir_avaliadores: "Assign evaluators to patients",
			  	},
			  	es: {
			  		modal_atribuir_avaliadores: "Asignar evaluadores a pacientes",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_upgrade2: "Gerencie cadastro de pacientes existentes para liberar espaço ou faça upgrade",
			  	},
			  	en: {
			  		modal_upgrade2: "Manage existing patient records to free up space or upgrade",
			  	},
			  	es: {
			  		modal_upgrade2: " Administre los registros de pacientes existentes para liberar espacio o actualizar",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_upgrade: "Você atingiu o número limite de cadastro de pacientes incluso em seu plano.",
			  	},
			  	en: {
			  		modal_upgrade: "You have reached the maximum number of patient registrations included in your plan.",
			  	},
			  	es: {
			  		modal_upgrade: "Ha alcanzado el número máximo de registros de pacientes incluidos en su plan.",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_excluir: "EXCLUIR",
			  	},
			  	en: {
			  		modal_excluir: "DELETE",
			  	},
			  	es: {
			  		modal_excluir: "ELIMINAR",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_excluir_paciente: "O paciente ficará disponível por 60 dias para ser recuperado no sistema, logo após, será excluído permanentemente!",
			  	},
			  	en: {
			  		modal_excluir_paciente: "The patient will be available for 60 days to be recovered in the system, soon after, it will be permanently deleted!",
			  	},
			  	es: {
			  		modal_excluir_paciente: "El paciente estará disponible durante 60 días para ser recuperado en el sistema, poco después, será eliminado de forma permanente.",
			  	}
			  },
		
			  {
			  	pt: {
			  		desativar: "Desativar",
			  	},
			  	en: {
			  		desativar: "Disable",
			  	},
			  	es: {
			  		desativar: "¿Deshabilitar",
			  	}
			  },
		
			  {
			  	pt: {
			  		enviar: "ENVIAR",
			  	},
			  	en: {
			  		enviar: "SUBMIT",
			  	},
			  	es: {
			  		enviar: "ENVIAR",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_filtro_etiqueta2: "Suas etiquetas criadas:",
			  	},
			  	en: {
			  		modal_filtro_etiqueta2: "Your created tags:",
			  	},
			  	es: {
			  		modal_filtro_etiqueta2: "Tus etiquetas creadas:",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_filtro_etiqueta: "Escolha as etiquetas para filtrar os pacientes incluídos nelas:",
			  	},
			  	en: {
			  		modal_filtro_etiqueta: "Choose the tags to filter the patients included in them:",
			  	},
			  	es: {
			  		modal_filtro_etiqueta: " Elija las etiquetas para filtrar los pacientes incluidos en ellas:",
			  	}
			  },
		
			  {
			  	pt: {
			  		confirmar_button: "CONFIRMAR",
			  	},
			  	en: {
			  		confirmar_button: "CONFIRM",
			  	},
			  	es: {
			  		confirmar_button: "CONFIRMAR",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_conta_usada2: "Deseja desconectar do dispositivo anterior e fazer login?",
			  	},
			  	en: {
			  		modal_conta_usada2: "Do you want to disconnect from the previous device and login?",
			  	},
			  	es: {
			  		modal_conta_usada2: "¿Quieres desconectarte del dispositivo anterior e iniciar sesión?",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_conta_usada: "Essa conta já está sendo usada em outro dispositivo.",
			  	},
			  	en: {
			  		modal_conta_usada: "This account is already being used on another device.",
			  	},
			  	es: {
			  		modal_conta_usada: " Esta cuenta ya se está utilizando en otro dispositivo.",
			  	}
			  },
		
			  {
			  	pt: {
			  		config_unidade_medida: "Unidade de medida",
			  	},
			  	en: {
			  		config_unidade_medida: "Unit of measurement",
			  	},
			  	es: {
			  		config_unidade_medida: "Unidad de medida",
			  	}
			  },
		
			  {
			  	pt: {
			  		resultado: "Resultado",
			  	},
			  	en: {
			  		resultado: "Result",
			  	},
			  	es: {
			  		resultado: "Resultado",
			  	}
			  },
		
			  {
			  	pt: {
			  		parabens_protocolo_finalizado: "Parabéns! Protocolo finalizado!",
			  	},
			  	en: {
			  		parabens_protocolo_finalizado: "Congratulations! Protocol finished!",
			  	},
			  	es: {
			  		parabens_protocolo_finalizado: "¡Muy Bien! ¡Protocolo terminado!",
			  	}
			  },
		
			  {
			  	pt: {
			  		descanso: "Descanso",
			  	},
			  	en: {
			  		descanso: "Rest",
			  	},
			  	es: {
			  		descanso: "Descansar",
			  	}
			  },
		
			  {
			  	pt: {
			  		tempo: "Tempo",
			  	},
			  	en: {
			  		tempo: "Time",
			  	},
			  	es: {
			  		tempo: "Hora",
			  	}
			  },
		
			  {
			  	pt: {
			  		forca: "Força",
			  	},
			  	en: {
			  		forca: "Strength",
			  	},
			  	es: {
			  		forca: "Fuerza",
			  	}
			  },
		
			  {
			  	pt: {
			  		iniciar: "INICIAR",
			  	},
			  	en: {
			  		iniciar: "START",
			  	},
			  	es: {
			  		iniciar: "COMIENZO",
			  	}
			  },
		
			  {
			  	pt: {
			  		parar: "PARAR",
			  	},
			  	en: {
			  		parar: "STOP",
			  	},
			  	es: {
			  		parar: "DETENER",
			  	}
			  },
		
			  {
			  	pt: {
			  		refazer: "Refazer",
			  	},
			  	en: {
			  		refazer: "Remake",
			  	},
			  	es: {
			  		refazer: "Rehacer",
			  	}
			  },
		
			  {
			  	pt: {
			  		concluir: "CONCLUIR",
			  	},
			  	en: {
			  		concluir: "DONE",
			  	},
			  	es: {
			  		concluir: "CONCLUIR",
			  	}
			  },
		
			  {
			  	pt: {
			  		contracao_tempo: "Tempo de contração",
			  	},
			  	en: {
			  		contracao_tempo: "Contraction time",
			  	},
			  	es: {
			  		contracao_tempo: " Tiempo de contracción",
			  	}
			  },
		
			  {
			  	pt: {
			  		ver_tutorial: "Ver tutorial",
			  	},
			  	en: {
			  		ver_tutorial: "See tutorial",
			  	},
			  	es: {
			  		ver_tutorial: "Ver tutorial",
			  	}
			  },
		
			  {
			  	pt: {
			  		finalizar: "FINALIZAR",
			  	},
			  	en: {
			  		finalizar: "FINISH",
			  	},
			  	es: {
			  		finalizar: "TERMINAR",
			  	}
			  },
			  {
				pt: {
					muito_facil: "Muito fácil",
				},
				en: {
					muito_facil: "Very easy",
				},
				es: {
					muito_facil: "Muy fácil",
				}
			  },
			  {
				pt: {
					facil: "Fácil",
				},
				en: {
					facil: "Easy",
				},
				es: {
					facil: "Fácil",
				}
			 },
			 {
				pt: {
					neutro: "Neutro",
				},
				en: {
					neutro: "Neutral",
				},
				es: {
					neutro: "Neutral",
				}
			  },
			  {
				pt: {
					dificil: "Difícil",
				},
				en: {
					dificil: "Difficult",
				},
				es: {
					dificil: "Difícil",
				}
			  },
			  {
				pt: {
					esforco_primeiro_exame: "Qual foi seu esforço para realizar o primeiro exame Kinology ?",
				},
				en: {
					esforco_primeiro_exame: "What was your effort to take the first Kinology exam?",
				},
				es: {
					esforco_primeiro_exame: "Cuál fue tu esfuerzo para realizar el primer examen de Kinology?",
				}
			 },
			  {
				pt: {
					compartilhe_percepcoes: "Por favor, compartilhe suas percepções sobre o exame Kinology ?",
				},
				en: {
					compartilhe_percepcoes: "Please share your insights about the Kinology exam?",
				},
				es: {
					compartilhe_percepcoes: "Por favor, comparta sus ideas sobre el examen Kinology.",
				}
			  },
			  {
				pt: {
					facil_realizar_exame: "Até o momento, quão fácil tem sido realizar exames Kinology ?",
				},
				en: {
					facil_realizar_exame: "So far, how easy has it been to perform Kinology exams?",
				},
				es: {
					facil_realizar_exame: "Hasta la fecha, qué tan fácil ha sido realizar los exámenes Kinology?",
				}
			  },
			  {
				pt: {
					muito_dificil: "Muito Difícil",
				},
				en: {
					muito_dificil: "Very difficult",
				},
				es: {
					muito_dificil: "Muy difícil",
				}
			  },
		
			  {
			  	pt: {
			  		finalizar_execucao: "FINALIZAR EXECUÇÃO",
			  	},
			  	en: {
			  		finalizar_execucao: "EXECUTION FINISH",
			  	},
			  	es: {
			  		finalizar_execucao: "FIN DE LA EJECUCIÓN",
			  	}
			  },
		
			  {
			  	pt: {
			  		adicionar_anotacao_modal: "Anotações no relatório de Evolução do paciente:",
			  	},
			  	en: {
			  		adicionar_anotacao_modal: "Annotations in the Patient Evolution report:",
			  	},
			  	es: {
			  		adicionar_anotacao_modal: "Anotaciones en el informe de evolución del paciente:",
			  	}
			  },
		
			  {
			  	pt: {
			  		alterar_senha_confirme_senha: "Confirme a nova senha",
			  	},
			  	en: {
			  		alterar_senha_confirme_senha: "Confirm new password",
			  	},
			  	es: {
			  		alterar_senha_confirme_senha: "Confirmar nueva contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		confirmar_senha: "Senhas não conferem",
			  	},
			  	en: {
			  		confirmar_senha: " Passwords do not match",
			  	},
			  	es: {
			  		confirmar_senha: "Las contraseñas no coinciden",
			  	}
			  },
		
			  {
			  	pt: {
			  		alterar_senha_minimo: "Mínimo 8 dígitos",
			  	},
			  	en: {
			  		alterar_senha_minimo: "Minimum 8 digits",
			  	},
			  	es: {
			  		alterar_senha_minimo: "Mínimo 8 dígitos",
			  	}
			  },
		
			  {
			  	pt: {
			  		alterar_senha_nova_senha: "Nova senha",
			  	},
			  	en: {
			  		alterar_senha_nova_senha: "New Password",
			  	},
			  	es: {
			  		alterar_senha_nova_senha: "Nueva contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		alterar_senha_senha_atual: "Senha atual",
			  	},
			  	en: {
			  		alterar_senha_senha_atual: "Current password",
			  	},
			  	es: {
			  		alterar_senha_senha_atual: "Contraseña actual",
			  	}
			  },
		
			  {
			  	pt: {
			  		paciente: "Paciente",
			  	},
			  	en: {
			  		paciente: "Patient",
			  	},
			  	es: {
			  		paciente: "Paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		feminino: "Feminino",
			  	},
			  	en: {
			  		feminino: "Female",
			  	},
			  	es: {
			  		feminino: "Femenino",
			  	}
			  },
		
			  {
			  	pt: {
			  		masculino: "Masculino",
			  	},
			  	en: {
			  		masculino: "Male",
			  	},
			  	es: {
			  		masculino: "Masculino",
			  	}
			  },
		
			  {
			  	pt: {
			  		editar_caixa_alta: "EDITAR",
			  	},
			  	en: {
			  		editar_caixa_alta: "EDIT",
			  	},
			  	es: {
			  		editar_caixa_alta: "EDITAR",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_cancelar: "CANCELAR",
			  	},
			  	en: {
			  		pagamento_cancelar: "CANCEL",
			  	},
			  	es: {
			  		pagamento_cancelar: "CANCELAR",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuir_tags_criar: "Adicionar etiqueta",
			  	},
			  	en: {
			  		atribuir_tags_criar: "Create new tag",
			  	},
			  	es: {
			  		atribuir_tags_criar: "Crear nueva etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercise_nao_possui_divisao: "Exercícios não possuem divisão em lados direito e esquerdo",
			  	},
			  	en: {
			  		exercise_nao_possui_divisao: "Exercises have no division into right and left sides.",
			  	},
			  	es: {
			  		exercise_nao_possui_divisao: "Los ejercicios no se dividen en lados derecho e izquierdo.",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolher_nome_exercicio: "Escolha um nome para o exercício",
			  	},
			  	en: {
			  		escolher_nome_exercicio: "Choose a name for the exercise",
			  	},
			  	es: {
			  		escolher_nome_exercicio: "Elija un nombre para el ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		info_novo_protocolo: "Adicione as informações do novo protocolo ",
			  	},
			  	en: {
			  		info_novo_protocolo: "Add new protocol information",
			  	},
			  	es: {
			  		info_novo_protocolo: "Agregar nueva información de protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		novo_exercicio: "Novo exercício",
			  	},
			  	en: {
			  		novo_exercicio: "New exercise",
			  	},
			  	es: {
			  		novo_exercicio: "Nuevo ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_criado_em: "Criado em:",
			  	},
			  	en: {
			  		protocolo_criado_em: "Created in:",
			  	},
			  	es: {
			  		protocolo_criado_em: "Creado en:",
			  	}
			  },
		
			  {
			  	pt: {
			  		original_elastic: "Original Kinology",
			  	},
			  	en: {
			  		original_elastic: "Original Kinology",
			  	},
			  	es: {
			  		original_elastic: "Kinology original",
			  	}
			  },
		
			  {
			  	pt: {
			  		grupame_abdome: "Abdomen",
			  	},
			  	en: {
			  		grupame_abdome: "Abdomen",
			  	},
			  	es: {
			  		grupame_abdome: "Abdomen",
			  	}
			  },
		
			  {
			  	pt: {
			  		cep_invalido: "CEP inválido",
			  	},
			  	en: {
			  		cep_invalido: "invalid ZIP",
			  	},
			  	es: {
			  		cep_invalido: "Código postal no válido",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_selecionar_todos: "Selecionar todos",
			  	},
			  	en: {
			  		tag_selecionar_todos: "Select all",
			  	},
			  	es: {
			  		tag_selecionar_todos: "Seleccionar todo",
			  	}
			  },
		
			  {
			  	pt: {
			  		endereco_invalido: "Endereço inválido",
			  	},
			  	en: {
			  		endereco_invalido: "Invalid address",
			  	},
			  	es: {
			  		endereco_invalido: "Dirección inválida",
			  	}
			  },
		
			  {
			  	pt: {
			  		nome_empresa_invalido: "Nome da empresa inválido ",
			  	},
			  	en: {
			  		nome_empresa_invalido: "Invalid company name",
			  	},
			  	es: {
			  		nome_empresa_invalido: " Nombre de la empresa no válido",
			  	}
			  },
		
			  {
			  	pt: {
			  		nome_empresa: "Nome da empresa",
			  	},
			  	en: {
			  		nome_empresa: "Company Name",
			  	},
			  	es: {
			  		nome_empresa: "Nombre de la empresa",
			  	}
			  },
		
			  {
			  	pt: {
			  		numero_telefone: "Número de telefone",
			  	},
			  	en: {
			  		numero_telefone: "Phone number",
			  	},
			  	es: {
			  		numero_telefone: "Número de teléfono",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_salvar: "SALVAR",
			  	},
			  	en: {
			  		pagamento_salvar: "SAVE",
			  	},
			  	es: {
			  		pagamento_salvar: "CONCLUIR",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_info_cvv: "Código de segurança de 3 ou 4 dígitos, impresso no verso do cartão de crédito/débito.",
			  	},
			  	en: {
			  		pagamento_info_cvv: " Three or four digit security code, printed on back of credit/debit card.",
			  	},
			  	es: {
			  		pagamento_info_cvv: "Código de seguridad de tres o cuatro dígitos, impreso en el reverso de la tarjeta de crédito/débito.",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_tres_numeros: "Três ou quatro números",
			  	},
			  	en: {
			  		pagamento_tres_numeros: "Three or four numbers",
			  	},
			  	es: {
			  		pagamento_tres_numeros: "Tres o cuatro números",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_quatro_numeros: "Quatro números",
			  	},
			  	en: {
			  		pagamento_quatro_numeros: "Four numbers",
			  	},
			  	es: {
			  		pagamento_quatro_numeros: "Cuatro números",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_dois_numeros: "Dois números",
			  	},
			  	en: {
			  		pagamento_dois_numeros: "Two numbers",
			  	},
			  	es: {
			  		pagamento_dois_numeros: "Dos números",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_nome_invalido: "Nome inválido",
			  	},
			  	en: {
			  		pagamento_nome_invalido: "Invalid name",
			  	},
			  	es: {
			  		pagamento_nome_invalido: " Nombre inválido",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_somente_numero: "Somente números",
			  	},
			  	en: {
			  		pagamento_somente_numero: "Only numbers",
			  	},
			  	es: {
			  		pagamento_somente_numero: "Sólo números",
			  	}
			  },
		
			  {
			  	pt: {
			  		tags_n_usos: "Nº de usos",
			  	},
			  	en: {
			  		tags_n_usos: "Number of uses",
			  	},
			  	es: {
			  		tags_n_usos: "Numero de usos",
			  	}
			  },
		
			  {
			  	pt: {
			  		tags_nome_etiqueta: "Nome da etiqueta",
			  	},
			  	en: {
			  		tags_nome_etiqueta: "Tag name",
			  	},
			  	es: {
			  		tags_nome_etiqueta: "Nombre de etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_insira_informacoes: "Insira as informações como estão no cartão",
			  	},
			  	en: {
			  		pagamento_insira_informacoes: "Enter the information as it is on the card.",
			  	},
			  	es: {
			  		pagamento_insira_informacoes: "Ingrese la información tal como está en la tarjeta.",
			  	}
			  },
		
			  {
			  	pt: {
			  		tags_minha_etiquetas: "Minhas etiquetas",
			  	},
			  	en: {
			  		tags_minha_etiquetas: "My tags",
			  	},
			  	es: {
			  		tags_minha_etiquetas: "Mis etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_faturas_pendentes: "Não há faturas pendentes",
			  	},
			  	en: {
			  		menu_lateral_faturas_pendentes: "Any pending invoices available",
			  	},
			  	es: {
			  		menu_lateral_faturas_pendentes: "No tiene facturas pendientes",
			  	}
			  },
		
			  {
			  	pt: {
			  		credito: "Crédito",
			  	},
			  	en: {
			  		credito: "credit",
			  	},
			  	es: {
			  		credito: "crédito",
			  	}
			  },
		
			  {
			  	pt: {
			  		manu_calibrar: "Calibrar Dinamômetro",
			  	},
			  	en: {
			  		manu_calibrar: "Calibrate Dynamometer",
			  	},
			  	es: {
			  		manu_calibrar: "Calibrar el dinamómetro",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_demonstracao_legenda: "menu_lateral_demonstracao_legenda",
			  	},
			  	en: {
			  		menu_demonstracao_legenda: "Set application language",
			  	},
			  	es: {
			  		menu_demonstracao_legenda: "Establecer el idioma de la aplicación",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_linguagem_legenda: "Defina a linguagem do aplicativo",
			  	},
			  	en: {
			  		menu_linguagem_legenda: "Set application language",
			  	},
			  	es: {
			  		menu_linguagem_legenda: "Establecer el idioma de la aplicación",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_calibrar: "Calibrar el dinamómetro",
			  	},
			  	en: {
			  		menu_calibrar: "Calibrate Dynamometer",
			  	},
			  	es: {
			  		menu_calibrar: "Calibrar el dinamómetro",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_tempo_contracao_legenda: "Apresenta um contador regressivo e emite um alerta sonoro ao finalizar o tempo total da duração da contração",
			  	},
			  	en: {
			  		menu_tempo_contracao_legenda: "Features a countdown timer and sounds an audible alert at the end of the total duration of the contraction",
			  	},
			  	es: {
			  		menu_tempo_contracao_legenda: "Cuenta con un temporizador de cuenta regresiva y suena una alerta audible al final de la duración total de la contracción.",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_tempo_segundos: "Tempo em segundos: ",
			  	},
			  	en: {
			  		menu_tempo_segundos: "Time in seconds:",
			  	},
			  	es: {
			  		menu_tempo_segundos: "Tiempo en segundos:",
			  	}
			  },
		
			  {
			  	pt: {
			  		idioma: "Português",
			  	},
			  	en: {
			  		idioma: "English",
			  	},
			  	es: {
			  		idioma: "Español",
			  	}
			  },
		
			  {
			  	pt: {
			  		tempo_contracao: "Apresenta um contador regressivo e emite um alerta sonoro ao finalizar o tempo total da duração da contração",
			  	},
			  	en: {
			  		tempo_contracao: "Features a countdown timer and sounds an audible alert at the end of the total duration of the contraction",
			  	},
			  	es: {
			  		tempo_contracao: "Cuenta con un temporizador de cuenta regresiva y suena una alerta audible al final de la duración total de la contracción.",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_intervalo_legenda: "Ative o relógio com o intervalo de descanso entre exercícios",
			  	},
			  	en: {
			  		menu_lateral_exercicios_intervalo_legenda: "Activate the watch with the rest interval between exercises",
			  	},
			  	es: {
			  		menu_lateral_exercicios_intervalo_legenda: "Activar el reloj con el intervalo de descanso entre ejercicios.",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicio_contagem_legenda: "Prapara o usuário para efetuar o exercício com uma contagem regressiva de 3,2,1",
			  	},
			  	en: {
			  		menu_lateral_exercicio_contagem_legenda: "It prepares the user to perform the exercise with a countdown of 3.2.1",
			  	},
			  	es: {
			  		menu_lateral_exercicio_contagem_legenda: "Prepara al usuario para realizar el ejercicio con una cuenta atrás de 3.2.1",
			  	}
			  },
		
			  {
			  	pt: {
			  		alerta_sonoro_legenda: "Um alerta sonoro é emitido ao efetuar uma repetição com o Kinology",
			  	},
			  	en: {
			  		alerta_sonoro_legenda: "An audible alert sounds when you perform a repeat with Kinology",
			  	},
			  	es: {
			  		alerta_sonoro_legenda: "Suena una alerta audible cuando realiza una repetición con Kinology",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicios: "Exercícios",
			  	},
			  	en: {
			  		exercicios: "Exercises",
			  	},
			  	es: {
			  		exercicios: "Ejercicios",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperar_conta: "Recuperar conta",
			  	},
			  	en: {
			  		recuperar_conta: "Retrieve account",
			  	},
			  	es: {
			  		recuperar_conta: "Recuperar cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		pacientes_avaliadores: "Pacientes e avaliadores",
			  	},
			  	en: {
			  		pacientes_avaliadores: "Patients and Evaluators",
			  	},
			  	es: {
			  		pacientes_avaliadores: "Pacientes y evaluadores",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuir2: "Atribuir",
			  	},
			  	en: {
			  		atribuir2: "To assign",
			  	},
			  	es: {
			  		atribuir2: "Asignar",
			  	}
			  },
		
			  {
			  	pt: {
			  		ultima_execucao: "Última execução",
			  	},
			  	en: {
			  		ultima_execucao: "Last run",
			  	},
			  	es: {
			  		ultima_execucao: "Última carrera",
			  	}
			  },
		
			  {
			  	pt: {
			  		tutorial: "Tutorial",
			  	},
			  	en: {
			  		tutorial: "Tutorial",
			  	},
			  	es: {
			  		tutorial: "Tutorial",
			  	}
			  },
		
			  {
			  	pt: {
			  		condicao_fisica: "Condição física",
			  	},
			  	en: {
			  		condicao_fisica: "Physical condition",
			  	},
			  	es: {
			  		condicao_fisica: "Estado físico",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuir: "Atribuir",
			  	},
			  	en: {
			  		atribuir: "To assign",
			  	},
			  	es: {
			  		atribuir: "Asignar",
			  	}
			  },
		
			  {
			  	pt: {
			  		anotacao: "Anotação",
			  	},
			  	en: {
			  		anotacao: "Note",
			  	},
			  	es: {
			  		anotacao: "Anotación",
			  	}
			  },
		
			  {
			  	pt: {
			  		adicionar_anotacao: "Adicionar anotações",
			  	},
			  	en: {
			  		adicionar_anotacao: "Add annotations",
			  	},
			  	es: {
			  		adicionar_anotacao: "Agregar anotaciones",
			  	}
			  },
		
			  {
			  	pt: {
			  		todas_anotacoes: "Todas as anotações",
			  	},
			  	en: {
			  		todas_anotacoes: "All Notes",
			  	},
			  	es: {
			  		todas_anotacoes: "Todas las notas",
			  	}
			  },
		
			  {
			  	pt: {
			  		anotacoes: "Anotações",
			  	},
			  	en: {
			  		anotacoes: "Notes",
			  	},
			  	es: {
			  		anotacoes: "Notas",
			  	}
			  },
		
			  {
			  	pt: {
			  		relatorios: "Relatórios",
			  	},
			  	en: {
			  		relatorios: "Reports",
			  	},
			  	es: {
			  		relatorios: "Informes",
			  	}
			  },
		
			  {
			  	pt: {
			  		informacoes: "Informações",
			  	},
			  	en: {
			  		informacoes: "Information",
			  	},
			  	es: {
			  		informacoes: "Información",
			  	}
			  },
		
			  {
			  	pt: {
			  		executar_exercicios: "Executar exercícios",
			  	},
			  	en: {
			  		executar_exercicios: "Perform exercises",
			  	},
			  	es: {
			  		executar_exercicios: "Realizar ejercicios",
			  	}
			  },
		
			  {
			  	pt: {
			  		executar_protocolos: "Executar protocolos",
			  	},
			  	en: {
			  		executar_protocolos: "Run protocols",
			  	},
			  	es: {
			  		executar_protocolos: "Ejecutar protocolos",
			  	}
			  },
		
			  {
			  	pt: {
			  		loading: "Carregando",
			  	},
			  	en: {
			  		loading: "Loading",
			  	},
			  	es: {
			  		loading: "Cargando",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluir: "Excluir",
			  	},
			  	en: {
			  		excluir: "Delete",
			  	},
			  	es: {
			  		excluir: "Borrar",
			  	}
			  },
		
			  {
			  	pt: {
			  		editar: "Editar",
			  	},
			  	en: {
			  		editar: "Edit",
			  	},
			  	es: {
			  		editar: "Editar",
			  	}
			  },
		
			  {
			  	pt: {
			  		ultima_avaliacao: "Última avaliação",
			  	},
			  	en: {
			  		ultima_avaliacao: "Last evaluation",
			  	},
			  	es: {
			  		ultima_avaliacao: "Ultima evaluacion",
			  	}
			  },
		
			  {
			  	pt: {
			  		plano_free: "Plano Free",
			  	},
			  	en: {
			  		plano_free: "Free Plan",
			  	},
			  	es: {
			  		plano_free: "Plan Free",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_pesquisar_paciente: "Pesquisar pacientes",
			  	},
			  	en: {
			  		dash_pesquisar_paciente: "Search patients",
			  	},
			  	es: {
			  		dash_pesquisar_paciente: "buscar pacientes",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_avaliacao_iq: "Avaliação IQ ",
			  	},
			  	en: {
			  		perfil_avaliacao_iq: "IQ assessment",
			  	},
			  	es: {
			  		perfil_avaliacao_iq: "Evaluación IQ",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_logomarca_relatorio: "Logomarca nos relatórios",
			  	},
			  	en: {
			  		perfil_logomarca_relatorio: "Logo on reports",
			  	},
			  	es: {
			  		perfil_logomarca_relatorio: "Logotipo en informes",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_indicar: "INDICAR",
			  	},
			  	en: {
			  		menu_lateral_indicar: "INDICATE",
			  	},
			  	es: {
			  		menu_lateral_indicar: "INDICAR",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_configurar: "Configurar",
			  	},
			  	en: {
			  		menu_lateral_configurar: "Settings",
			  	},
			  	es: {
			  		menu_lateral_configurar: "Ajustes",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_protocolos: "Protocolos",
			  	},
			  	en: {
			  		menu_lateral_protocolos: "Protocols",
			  	},
			  	es: {
			  		menu_lateral_protocolos: "Protocolos",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_etiquetas: "Etiquetas",
			  	},
			  	en: {
			  		menu_lateral_etiquetas: "Tags",
			  	},
			  	es: {
			  		menu_lateral_etiquetas: "Etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_inicio: "Início",
			  	},
			  	en: {
			  		menu_lateral_inicio: "Home",
			  	},
			  	es: {
			  		menu_lateral_inicio: " Comienzo",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_ver_meu_perfil: "Ver meu perfil",
			  	},
			  	en: {
			  		menu_ver_meu_perfil: "My profile",
			  	},
			  	es: {
			  		menu_ver_meu_perfil: "Mi perfil",
			  	}
			  },
		
			  {
			  	pt: {
			  		voltar: "Voltar",
			  	},
			  	en: {
			  		voltar: "Back",
			  	},
			  	es: {
			  		voltar: "Vuelve",
			  	}
			  },
		
			  {
			  	pt: {
			  		cronometro: " Cronômetro",
			  	},
			  	en: {
			  		cronometro: "Stopwatch",
			  	},
			  	es: {
			  		cronometro: "Cronógrafo",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_protocol_config_rm: "Este paciente ainda não possui histórico de RM. A partir desta             avaliação será possível definir a porcentagem da repetição máxima.",
			  	},
			  	en: {
			  		modal_protocol_config_rm: "This patient has no history of MR. From this evaluation it will be possible to define the percentage of maximum repetition.",
			  	},
			  	es: {
			  		modal_protocol_config_rm: "Este paciente no tiene antecedentes de insuficiencia mitral. A partir de esta evaluación será posible definir el porcentaje de repetición máxima.",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_protocol_config: "Escolha as configurações que deseja para realizar o protocolo.",
			  	},
			  	en: {
			  		modal_protocol_config: "Choose the settings you want to perform the protocol.",
			  	},
			  	es: {
			  		modal_protocol_config: "Elija la configuración que desea realizar el protocolo.",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_execucao_iniciar_protocol: "Iniciar protocolo de:",
			  	},
			  	en: {
			  		modal_execucao_iniciar_protocol: "Start protocol of:",
			  	},
			  	es: {
			  		modal_execucao_iniciar_protocol: "Protocolo de inicio de:",
			  	}
			  },
		
			  {
			  	pt: {
			  		execucao_pause: "Pausar",
			  	},
			  	en: {
			  		execucao_pause: "Pause",
			  	},
			  	es: {
			  		execucao_pause: "Pausa",
			  	}
			  },
		
			  {
			  	pt: {
			  		execucao_finalizar: "Finalizar",
			  	},
			  	en: {
			  		execucao_finalizar: "Finish",
			  	},
			  	es: {
			  		execucao_finalizar: "Terminar",
			  	}
			  },
		
			  {
			  	pt: {
			  		execucao_force_min: "Força mínima",
			  	},
			  	en: {
			  		execucao_force_min: "Minimum force",
			  	},
			  	es: {
			  		execucao_force_min: "Fuerza mínima",
			  	}
			  },
		
			  {
			  	pt: {
			  		execucao_force_max: "Força máxima",
			  	},
			  	en: {
			  		execucao_force_max: "Maximum force",
			  	},
			  	es: {
			  		execucao_force_max: "Fuerza maxima",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_escolher_protocol: "Escolha o protocolo que deseja executar:",
			  	},
			  	en: {
			  		dash_escolher_protocol: "Choose the protocol you want to run:",
			  	},
			  	es: {
			  		dash_escolher_protocol: "Elija el protocolo que desea ejecutar:",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_escolher_exer: "Escolha o exercício que deseja executar:",
			  	},
			  	en: {
			  		dash_escolher_exer: "Choose the exercise you want to perform:",
			  	},
			  	es: {
			  		dash_escolher_exer: "Elija el ejercicio que desea realizar:",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_anos: "anos",
			  	},
			  	en: {
			  		dash_anos: "years old",
			  	},
			  	es: {
			  		dash_anos: "años",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_no_create_etiquetas: "Nenhuma etiqueta criada",
			  	},
			  	en: {
			  		dash_no_create_etiquetas: "No tags created",
			  	},
			  	es: {
			  		dash_no_create_etiquetas: "No se crearon etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_etiquetas: "Etiquetas",
			  	},
			  	en: {
			  		dash_etiquetas: "Tags",
			  	},
			  	es: {
			  		dash_etiquetas: "Etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_ficha_tec: "Ficha técnica",
			  	},
			  	en: {
			  		dash_ficha_tec: "Datasheet",
			  	},
			  	es: {
			  		dash_ficha_tec: "Ficha técnica",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_peso: "Peso",
			  	},
			  	en: {
			  		dash_peso: "Weight",
			  	},
			  	es: {
			  		dash_peso: "Peso",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_idade: "Idade",
			  	},
			  	en: {
			  		dash_idade: "Age",
			  	},
			  	es: {
			  		dash_idade: "Edad",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_Information: "Informações",
			  	},
			  	en: {
			  		dash_Information: "Information",
			  	},
			  	es: {
			  		dash_Information: "Información",
			  	}
			  },
		
			  {
			  	pt: {
			  		gerenciar_protocolos_excluir: "Excluir protocolo",
			  	},
			  	en: {
			  		gerenciar_protocolos_excluir: "Delete protocol",
			  	},
			  	es: {
			  		gerenciar_protocolos_excluir: "Eliminar protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		failure: "Falha",
			  	},
			  	en: {
			  		failure: "Failure",
			  	},
			  	es: {
			  		failure: "Falha",
			  	}
			  },
		
			  {
			  	pt: {
			  		no_division_part1: "Exercícios que ",
			  	},
			  	en: {
			  		no_division_part1: "Exercises that have",
			  	},
			  	es: {
			  		no_division_part1: "Ejercicios que",
			  	}
			  },
		
			  {
			  	pt: {
			  		no_division_part2: "têm divisão em lados direito e esquerdo",
			  	},
			  	en: {
			  		no_division_part2: "division into right and left sides",
			  	},
			  	es: {
			  		no_division_part2: "tienen división en lados derecho e izquierdo.",
			  	}
			  },
		
			  {
			  	pt: {
			  		nao: "não",
			  	},
			  	en: {
			  		nao: "no",
			  	},
			  	es: {
			  		nao: "no",
			  	}
			  },
		
			  {
			  	pt: {
			  		exemplo_2: "Abdominal...",
			  	},
			  	en: {
			  		exemplo_2: "Abdominal...",
			  	},
			  	es: {
			  		exemplo_2: "Abdominal...",
			  	}
			  },
		
			  {
			  	pt: {
			  		exemplo_1: "Extensão de cotovelo, Flexão de ombro...",
			  	},
			  	en: {
			  		exemplo_1: "Elbow Extension, Shoulder Flexion...",
			  	},
			  	es: {
			  		exemplo_1: "Extensión de codo, flexión de hombro...",
			  	}
			  },
		
			  {
			  	pt: {
			  		exemplos: "Exemplos:",
			  	},
			  	en: {
			  		exemplos: "Examples:",
			  	},
			  	es: {
			  		exemplos: "Ejemplos:",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercise_possui_divisao: "Exercícios que possuem divisão em lados direito e esquerdo",
			  	},
			  	en: {
			  		exercise_possui_divisao: "Exercises that have division into right and left sides",
			  	},
			  	es: {
			  		exercise_possui_divisao: "Ejercicios que tienen división en lados derecho e izquierdo.",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercise_group_muscular: " Esse exercício faz parte de qual agrupamento muscular?",
			  	},
			  	en: {
			  		exercise_group_muscular: "Which muscle grouping is this exercise part of?",
			  	},
			  	es: {
			  		exercise_group_muscular: "¿De qué grupo de músculos forma parte este ejercicio?",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercise_looks: "Este exercício se parece mais com qual opção abaixo:",
			  	},
			  	en: {
			  		exercise_looks: "This exercise looks more like which option below:",
			  	},
			  	es: {
			  		exercise_looks: "Este ejercicio se parece más a la siguiente opción:",
			  	}
			  },
		
			  {
			  	pt: {
			  		selected_grouping: "Agrupamento selecionado:",
			  	},
			  	en: {
			  		selected_grouping: "Selected grouping:",
			  	},
			  	es: {
			  		selected_grouping: "Agrupación seleccionada:",
			  	}
			  },
		
			  {
			  	pt: {
			  		nome_do_exercicio: "Nome do exercício",
			  	},
			  	en: {
			  		nome_do_exercicio: "Exercise name",
			  	},
			  	es: {
			  		nome_do_exercicio: "Nombre del ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		segure_arraste: "Segure e arraste o exercício para mudar a ordem",
			  	},
			  	en: {
			  		segure_arraste: "Hold and drag the exercise to change an order",
			  	},
			  	es: {
			  		segure_arraste: "Mantenga presionado y arrastre el ejercicio para cambiar un orden",
			  	}
			  },
		
			  {
			  	pt: {
			  		hemisferio_bilateral: "Bilateral",
			  	},
			  	en: {
			  		hemisferio_bilateral: "Bilateral",
			  	},
			  	es: {
			  		hemisferio_bilateral: "Bilateral",
			  	}
			  },
		
			  {
			  	pt: {
			  		grupameto_inferior: "Agrupamentos  Inferiores",
			  	},
			  	en: {
			  		grupameto_inferior: "Groupings lower",
			  	},
			  	es: {
			  		grupameto_inferior: "Agrupaciones más bajo",
			  	}
			  },
		
			  {
			  	pt: {
			  		grupameto_superior: "Agrupamentos  Superiores",
			  	},
			  	en: {
			  		grupameto_superior: "Groupings Superiors",
			  	},
			  	es: {
			  		grupameto_superior: "Agrupaciones Superiores",
			  	}
			  },
		
			  {
			  	pt: {
			  		tericeira_op_protocolo: "Não quero utilizar a lista de exercícios da Kinology, quero criar o meu próprio para adicionar ao protocolo",
			  	},
			  	en: {
			  		tericeira_op_protocolo: "I dont want to use an Kinology exercise list, I want to create my own to add to the protocol",
			  	},
			  	es: {
			  		tericeira_op_protocolo: "No quiero usar la lista de ejercicios de Kinology, quiero crear la mía propia para agregar al protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		nome_protocolo: "Criar protocolo",
			  	},
			  	en: {
			  		nome_protocolo: "Create protocol",
			  	},
			  	es: {
			  		nome_protocolo: "Crea protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocol_sugerido: "Quero usar um protocolo sugerido",
			  	},
			  	en: {
			  		protocol_sugerido: "I want to use a suggested protocol",
			  	},
			  	es: {
			  		protocol_sugerido: "Quiero usar un protocolo sugerido",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocol_not_found: "Protocolo não encontrado.",
			  	},
			  	en: {
			  		protocol_not_found: "Protocol not found.",
			  	},
			  	es: {
			  		protocol_not_found: "Protocolo no encontrado.",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocol_delete_success: "Protocolo(s) excluídos com sucesso!",
			  	},
			  	en: {
			  		protocol_delete_success: "Protocol(s) successfully deleted!",
			  	},
			  	es: {
			  		protocol_delete_success: "Protocolo (s) eliminado correctamente!",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocol_add_first: "Vamos adicionar o seu primeiro protocolo na Kinology?",
			  	},
			  	en: {
			  		protocol_add_first: "Lets add your first protocol at Kinology?",
			  	},
			  	es: {
			  		protocol_add_first: "Agreguemos tu primera protocolo en Kinology?",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_criado: "Protocolo criado",
			  	},
			  	en: {
			  		protocolo_criado: "Protocol created",
			  	},
			  	es: {
			  		protocolo_criado: "Protocolo creado",
			  	}
			  },
		
			  {
			  	pt: {
			  		protoc_gerenciar: "Gerenciar protocolos",
			  	},
			  	en: {
			  		protoc_gerenciar: "Manage protocols",
			  	},
			  	es: {
			  		protoc_gerenciar: "Administrar protocolos",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_paci_delete_etiqueta_success: "com sucesso",
			  	},
			  	en: {
			  		tag_paci_delete_etiqueta_success: "successfully",
			  	},
			  	es: {
			  		tag_paci_delete_etiqueta_success: "con éxito",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_paci_delete_etiqueta: "foi excluída da etiqueta",
			  	},
			  	en: {
			  		tag_paci_delete_etiqueta: "has been deleted from the tag",
			  	},
			  	es: {
			  		tag_paci_delete_etiqueta: "ha sido eliminado de la etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_rest_atencao: "A etiqueta com esse nome já foi criada",
			  	},
			  	en: {
			  		tag_rest_atencao: "The label with that name has already been created",
			  	},
			  	es: {
			  		tag_rest_atencao: "la etiqueta con ese nombre ya ha sido creada",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_atencao: "Atenção",
			  	},
			  	en: {
			  		tag_atencao: "attention",
			  	},
			  	es: {
			  		tag_atencao: "atención",
			  	}
			  },
		
			  {
			  	pt: {
			  		busca_aval_not_encontrado: "Opa, não achamos nenhum avaliador com esse nome!",
			  	},
			  	en: {
			  		busca_aval_not_encontrado: "Oops, we couldnt find any reviewer with that name!",
			  	},
			  	es: {
			  		busca_aval_not_encontrado: "¡Vaya! No pudimos encontrar ningún crítico con ese nombre.",
			  	}
			  },
		
			  {
			  	pt: {
			  		busca_paci_not_encontrado: "Opa, não achamos nenhum paciente com esse nome!",
			  	},
			  	en: {
			  		busca_paci_not_encontrado: "Oops, we didnt find any patient with that name!",
			  	},
			  	es: {
			  		busca_paci_not_encontrado: "¡Vaya, no encontramos ningún paciente con ese nombre!",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_confirmar: "Confirmar",
			  	},
			  	en: {
			  		modal_confirmar: "Confirm",
			  	},
			  	es: {
			  		modal_confirmar: "Confirmar",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_atribuida_com_sucesso: "As etiquetas selecionadas foram atribuídas aos pacientes com sucesso.",
			  	},
			  	en: {
			  		tag_atribuida_com_sucesso: "The selected labels have been successfully assigned to patients.",
			  	},
			  	es: {
			  		tag_atribuida_com_sucesso: "Las etiquetas seleccionadas se han asignado correctamente a los pacientes.",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_atribuir_ao_pacis: "atribuida aos pacientes selecionados.",
			  	},
			  	en: {
			  		tag_atribuir_ao_pacis: "assigned to selected patients.",
			  	},
			  	es: {
			  		tag_atribuir_ao_pacis: "asignado a pacientes seleccionados.",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_atribuir_ao_paci: "atribuida ao paciente selecionado.",
			  	},
			  	en: {
			  		tag_atribuir_ao_paci: "assigned to the selected patient.",
			  	},
			  	es: {
			  		tag_atribuir_ao_paci: "asignado al paciente seleccionado.",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag: "Etiqueta",
			  	},
			  	en: {
			  		tag: "Tag",
			  	},
			  	es: {
			  		tag: "Etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		teg_paciente_excluir: "Excluir o paciente",
			  	},
			  	en: {
			  		teg_paciente_excluir: "Delete the patient",
			  	},
			  	es: {
			  		teg_paciente_excluir: "Eliminar al paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_da: "da etiqueta",
			  	},
			  	en: {
			  		tag_da: "of the label",
			  	},
			  	es: {
			  		tag_da: "de la etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_excluir_a: "Excluir a etiqueta",
			  	},
			  	en: {
			  		tag_excluir_a: "Delete the tag",
			  	},
			  	es: {
			  		tag_excluir_a: "Eliminar la etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_apenas_excluir: "Selecionando um paciente ele apenas será excluído da etiqueta ",
			  	},
			  	en: {
			  		tag_apenas_excluir: "Selecting a patient will only be excluded from the label",
			  	},
			  	es: {
			  		tag_apenas_excluir: "La selección de un paciente solo se excluirá de la etiqueta.",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_atribuir: "Atribuir",
			  	},
			  	en: {
			  		tag_atribuir: "To assign",
			  	},
			  	es: {
			  		tag_atribuir: "Asignar",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_selecionar: "Selecione a etiqueta para visualizar os pacientes ou editar:",
			  	},
			  	en: {
			  		tag_selecionar: "Select the tag to view patients or edit:",
			  	},
			  	es: {
			  		tag_selecionar: "Seleccione la etiqueta para ver pacientes o editar:",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_criadas2: "Suas etiquetas criadas, selecione para acrescentar aos pacientes:",
			  	},
			  	en: {
			  		tag_criadas2: "Your labels created, select to add to the patients:",
			  	},
			  	es: {
			  		tag_criadas2: "Sus etiquetas creadas, seleccione para agregar a los pacientes:",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_criadas1: "Suas etiquetas criadas:",
			  	},
			  	en: {
			  		tag_criadas1: "Your created tags:",
			  	},
			  	es: {
			  		tag_criadas1: "Tus etiquetas creadas:",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_selecionados: "Selecionados",
			  	},
			  	en: {
			  		tag_selecionados: "Selected",
			  	},
			  	es: {
			  		tag_selecionados: "seleccionado",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_selecionado: "Selecionado",
			  	},
			  	en: {
			  		tag_selecionado: "Selected",
			  	},
			  	es: {
			  		tag_selecionado: "Seleccionado",
			  	}
			  },
		
			  {
			  	pt: {
			  		tag_empty: "Você ainda não possui nenhuma tag criada.",
			  	},
			  	en: {
			  		tag_empty: "You dont have any tags created yet.",
			  	},
			  	es: {
			  		tag_empty: "Aún no ha creado ninguna etiqueta.",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_select_eval: "Selecionar avaliador",
			  	},
			  	en: {
			  		dash_select_eval: "Select reviewer",
			  	},
			  	es: {
			  		dash_select_eval: "Seleccionar revisor",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_data_obrigatoria: "Data de nascimento é obrigatória",
			  	},
			  	en: {
			  		dash_data_obrigatoria: "Date of birth is mandatory",
			  	},
			  	es: {
			  		dash_data_obrigatoria: "La fecha de nacimiento es obligatoria.",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_altura_obrigatoria: "Altura é obrigatória",
			  	},
			  	en: {
			  		dash_altura_obrigatoria: "Height is required",
			  	},
			  	es: {
			  		dash_altura_obrigatoria: "Se requiere altura",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_mass_obrigatoria: "Massa é obrigatória",
			  	},
			  	en: {
			  		dash_mass_obrigatoria: "Pasta is mandatory",
			  	},
			  	es: {
			  		dash_mass_obrigatoria: "La pasta es obligatoria",
			  	}
			  },
		
			  {
			  	pt: {
			  		gerenciar_pacientes_condi: "Nova condição física",
			  	},
			  	en: {
			  		gerenciar_pacientes_condi: "New physical condition",
			  	},
			  	es: {
			  		gerenciar_pacientes_condi: "Nueva condición física",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_gerenciar_paci: "Gerenciar pacientes",
			  	},
			  	en: {
			  		dash_gerenciar_paci: "Manage patients",
			  	},
			  	es: {
			  		dash_gerenciar_paci: "Manejar pacientes",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_voce: "Você",
			  	},
			  	en: {
			  		dash_voce: "You",
			  	},
			  	es: {
			  		dash_voce: "Tú",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_add_first_paci: "Vamos adicionar o seu primeiro paciente na Kinology?",
			  	},
			  	en: {
			  		dash_add_first_paci: "Are we going to add your first patient to Kinology?",
			  	},
			  	es: {
			  		dash_add_first_paci: "¿Vamos a agregar a su primer paciente a Kinology?",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_pesquisar_evalu: "Pesquisar avaliadores",
			  	},
			  	en: {
			  		dash_pesquisar_evalu: "Search reviewers",
			  	},
			  	es: {
			  		dash_pesquisar_evalu: "Revisores de búsqueda",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_busca: "Pesquisar pacientes",
			  	},
			  	en: {
			  		pag_inicial_busca: "Search patients",
			  	},
			  	es: {
			  		pag_inicial_busca: "Buscar pacientes",
			  	}
			  },
		
			  {
			  	pt: {
			  		dash_paciente: "Paciente",
			  	},
			  	en: {
			  		dash_paciente: "Patient",
			  	},
			  	es: {
			  		dash_paciente: "Paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		pac_aval_desativacao: "O usuário foi desativado com sucesso.",
			  	},
			  	en: {
			  		pac_aval_desativacao: "The user has been successfully deactivated.",
			  	},
			  	es: {
			  		pac_aval_desativacao: "El usuario se ha desactivado con éxito.",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_menu_inferior_add: "Adicionar",
			  	},
			  	en: {
			  		pag_inicial_menu_inferior_add: "Add",
			  	},
			  	es: {
			  		pag_inicial_menu_inferior_add: "Agregar",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_menu_inferior_proto: "Protocolos",
			  	},
			  	en: {
			  		pag_inicial_menu_inferior_proto: "Protocols",
			  	},
			  	es: {
			  		pag_inicial_menu_inferior_proto: "Protocolos",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_relatorio_forca_tempo: "Relatório força/tempo",
			  	},
			  	en: {
			  		menu_lateral_relatorio_forca_tempo: "Strength/time report",
			  	},
			  	es: {
			  		menu_lateral_relatorio_forca_tempo: "Informe fuerza/tiempo",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_rela_avaliacao: "Relatório avaliação",
			  	},
			  	en: {
			  		menu_lateral_rela_avaliacao: "Evaluation report",
			  	},
			  	es: {
			  		menu_lateral_rela_avaliacao: "Reporte de evaluacion",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_rela_asimetri: "Relatório assimetria",
			  	},
			  	en: {
			  		menu_lateral_rela_asimetri: "Asymmetry report",
			  	},
			  	es: {
			  		menu_lateral_rela_asimetri: "Informe de asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_dias: "dias",
			  	},
			  	en: {
			  		menu_lateral_dias: "days",
			  	},
			  	es: {
			  		menu_lateral_dias: "dias",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_paciente: "Paciente",
			  	},
			  	en: {
			  		menu_lateral_paciente: "Patient",
			  	},
			  	es: {
			  		menu_lateral_paciente: "Paciente",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_paci_aval: "Pacientes/Avaliadores",
			  	},
			  	en: {
			  		menu_lateral_paci_aval: "Patients / Assessors",
			  	},
			  	es: {
			  		menu_lateral_paci_aval: "Pacientes / Evaluadores",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_recuperar: "Recuperar excluídos",
			  	},
			  	en: {
			  		menu_lateral_recuperar: "Recover deleted",
			  	},
			  	es: {
			  		menu_lateral_recuperar: "Recuperar eliminado",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_language: "Linguagem",
			  	},
			  	en: {
			  		menu_lateral_language: "Language",
			  	},
			  	es: {
			  		menu_lateral_language: "Idioma",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_calibre_dino_txt: "A calibragem do Dinamômetro é efetuada para regular o peso executado.",
			  	},
			  	en: {
			  		menu_lateral_calibre_dino_txt: "The dynamometer is calibrated to adjust the weight performed.",
			  	},
			  	es: {
			  		menu_lateral_calibre_dino_txt: "La calibración del dinamómetro se realiza para regular el peso realizado.",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_number: "Número",
			  	},
			  	en: {
			  		menu_lateral_number: "Number",
			  	},
			  	es: {
			  		menu_lateral_number: "Número",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_custom_company_empty: "O nome da empresa é obrigatório",
			  	},
			  	en: {
			  		menu_lateral_custom_company_empty: "Company name is required",
			  	},
			  	es: {
			  		menu_lateral_custom_company_empty: "O nome da empresa é obrigatório",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_cvv_aviso: "Código de segurança de 3 dígitos, impresso no verso do cartão de crédito.",
			  	},
			  	en: {
			  		menu_lateral_cvv_aviso: "3-digit security code, printed on the back of the credit card.",
			  	},
			  	es: {
			  		menu_lateral_cvv_aviso: "Código de seguridad de 3 dígitos, impreso en el reverso de la tarjeta de crédito.",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_nome_cartao: "Nome impresso no cartão",
			  	},
			  	en: {
			  		pagamento_nome_cartao: "Name printed on the card",
			  	},
			  	es: {
			  		pagamento_nome_cartao: "Nombre impreso en la tarjeta",
			  	}
			  },
		
			  {
			  	pt: {
			  		faturas_loading: "Carregando Faturas",
			  	},
			  	en: {
			  		faturas_loading: "Loading Invoices",
			  	},
			  	es: {
			  		faturas_loading: "Cargando facturas",
			  	}
			  },
		
			  {
			  	pt: {
			  		pass_error_alter: "Houve um erro ao tentar alterar sua senha.",
			  	},
			  	en: {
			  		pass_error_alter: "There was an error when trying to change your password.",
			  	},
			  	es: {
			  		pass_error_alter: "Hubo un error al intentar cambiar su contraseña.",
			  	}
			  },
		
			  {
			  	pt: {
			  		pass_confirm_alter: "Sua senha foi alterada com sucesso.",
			  	},
			  	en: {
			  		pass_confirm_alter: "Your password has been changed successfully.",
			  	},
			  	es: {
			  		pass_confirm_alter: "Tu contraseña ha sido cambiada exitosamente.",
			  	}
			  },
		
			  {
			  	pt: {
			  		input_new_confirm_obrig: "A nova senha é obrigatória",
			  	},
			  	en: {
			  		input_new_confirm_obrig: "The new password is mandatory",
			  	},
			  	es: {
			  		input_new_confirm_obrig: "La nueva contraseña es obligatoria",
			  	}
			  },
		
			  {
			  	pt: {
			  		input_pass_confirm_obrig: "Confirmação obrigatória",
			  	},
			  	en: {
			  		input_pass_confirm_obrig: "Confirmation required",
			  	},
			  	es: {
			  		input_pass_confirm_obrig: "Confirmación requerida",
			  	}
			  },
		
			  {
			  	pt: {
			  		input_pass_confirm: "Confirmar sua senha",
			  	},
			  	en: {
			  		input_pass_confirm: "Confirm your password",
			  	},
			  	es: {
			  		input_pass_confirm: "Confirmar la contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		input_pass_atual_obrigatoria: "A senha atual é obrigatória",
			  	},
			  	en: {
			  		input_pass_atual_obrigatoria: "Current password is required",
			  	},
			  	es: {
			  		input_pass_atual_obrigatoria: "Se requiere contraseña actual",
			  	}
			  },
		
			  {
			  	pt: {
			  		options_sexo_mas: "Masculino",
			  	},
			  	en: {
			  		options_sexo_mas: "Male",
			  	},
			  	es: {
			  		options_sexo_mas: "Masculino",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_config_exercicios: "Configurar exercícios",
			  	},
			  	en: {
			  		menu_lateral_config_exercicios: "Configure exercises",
			  	},
			  	es: {
			  		menu_lateral_config_exercicios: "Configurar ejercicios",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_plano: "Plano",
			  	},
			  	en: {
			  		menu_lateral_plano: "Plan",
			  	},
			  	es: {
			  		menu_lateral_plano: "Plan",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_aviso_nacionalidade: "Nacionalidade é obrigatória",
			  	},
			  	en: {
			  		register_aviso_nacionalidade: "Nationality is mandatory",
			  	},
			  	es: {
			  		register_aviso_nacionalidade: "La nacionalidad es obligatoria",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeiro_acesso_confirm_pass: "Confirmar a senha",
			  	},
			  	en: {
			  		primeiro_acesso_confirm_pass: "Confirm the password",
			  	},
			  	es: {
			  		primeiro_acesso_confirm_pass: "Confirma la contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeiro_acesso_senha_6: "Senha (6 a 20 dígitos)",
			  	},
			  	en: {
			  		primeiro_acesso_senha_6: "Password (6 to 20 digits)",
			  	},
			  	es: {
			  		primeiro_acesso_senha_6: "Contraseña (de 6 a 20 dígitos)",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_email_valido: "Digite um e-mail válido",
			  	},
			  	en: {
			  		register_email_valido: "Enter a valid email address",
			  	},
			  	es: {
			  		register_email_valido: "Introduzca una dirección de correo electrónico válida",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_aviso_min_6: "No mínimo 6 dígitos",
			  	},
			  	en: {
			  		register_aviso_min_6: "At least 6 digits",
			  	},
			  	es: {
			  		register_aviso_min_6: "Al menos 6 dígitos",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_aviso_corresponder_passs: "As senhas devem corresponder",
			  	},
			  	en: {
			  		register_aviso_corresponder_passs: "Passwords must match",
			  	},
			  	es: {
			  		register_aviso_corresponder_passs: "As senhas devem corresponder",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeiro_acesso_no_net: "Não é possível concluir um cadastro pois o dispositivo não está conectado na internet.",
			  	},
			  	en: {
			  		primeiro_acesso_no_net: "It is not possible to complete a registration because the device is not connected to the internet.",
			  	},
			  	es: {
			  		primeiro_acesso_no_net: "No es posible concluir que un registro de datos o un dispositivo no esté conectado a Internet.",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_aviso_cpf: "CPF é obrigatório",
			  	},
			  	en: {
			  		register_aviso_cpf: "CPF is mandatory",
			  	},
			  	es: {
			  		register_aviso_cpf: "CPF es obligatorio",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_aviso_rne: "RNE é obrigatório",
			  	},
			  	en: {
			  		register_aviso_rne: "RNE is mandatory",
			  	},
			  	es: {
			  		register_aviso_rne: "RNE es obligatorio",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_aviso_pass_confirm: "Confirmar senha é obrigatório",
			  	},
			  	en: {
			  		register_aviso_pass_confirm: "Confirm password is required",
			  	},
			  	es: {
			  		register_aviso_pass_confirm: "Se requiere confirmación de contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_aviso_telefone: "Telefone é obrigatório",
			  	},
			  	en: {
			  		register_aviso_telefone: "Phone is required",
			  	},
			  	es: {
			  		register_aviso_telefone: "Se requiere teléfono",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_aviso_name: "Nome é obrigatório",
			  	},
			  	en: {
			  		register_aviso_name: "Name is required",
			  	},
			  	es: {
			  		register_aviso_name: "Se requiere el nombre",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_aviso_pass: "Senha é obrigatória",
			  	},
			  	en: {
			  		login_aviso_pass: "Password is required",
			  	},
			  	es: {
			  		login_aviso_pass: "se requiere contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_aviso_email: "E-mail é obrigatório",
			  	},
			  	en: {
			  		login_aviso_email: "E-mail is required",
			  	},
			  	es: {
			  		login_aviso_email: "Correo electronico es requerido",
			  	}
			  },
		
			  {
			  	pt: {
			  		movimento_fisio: "Colabore com o movimento  ‘’Por mais dados na fisioterapia’’",
			  	},
			  	en: {
			  		movimento_fisio: " Collaborate with the movement ‘’ For more data in physiotherapy ’’",
			  	},
			  	es: {
			  		movimento_fisio: "Colabora con el movimiento ‘’Para obtener más datos sobre fisioterapia‘’",
			  	}
			  },
		
			  {
			  	pt: {
			  		adicionar_paciente_avalaidor_request_sucesss: "Paciente cadastrado com sucesso.",
			  	},
			  	en: {
			  		adicionar_paciente_avalaidor_request_sucesss: "Patient successfully registered.",
			  	},
			  	es: {
			  		adicionar_paciente_avalaidor_request_sucesss: "Paciente registrado correctamente.",
			  	}
			  },
		
			  {
			  	pt: {
			  		adicionar_paciente_avaliador: "Quero adicionar um:",
			  	},
			  	en: {
			  		adicionar_paciente_avaliador: "I want to add one:",
			  	},
			  	es: {
			  		adicionar_paciente_avaliador: "Quiero agregar uno:",
			  	}
			  },
		
			  {
			  	pt: {
			  		conexoes_desconectado: "Desconectado",
			  	},
			  	en: {
			  		conexoes_desconectado: "Disconnected",
			  	},
			  	es: {
			  		conexoes_desconectado: "Desconectado",
			  	}
			  },
		
			  {
			  	pt: {
			  		conexoes_conectado: "Conectado",
			  	},
			  	en: {
			  		conexoes_conectado: "Connected",
			  	},
			  	es: {
			  		conexoes_conectado: "Conectado",
			  	}
			  },
		
			  {
			  	pt: {
			  		conexoes_dinamo: "Dinamô",
			  	},
			  	en: {
			  		conexoes_dinamo: "Dinamô",
			  	},
			  	es: {
			  		conexoes_dinamo: "Dinamô",
			  	}
			  },
		
			  {
			  	pt: {
			  		conexoes_gps: "GPS",
			  	},
			  	en: {
			  		conexoes_gps: "GPS",
			  	},
			  	es: {
			  		conexoes_gps: "GPS",
			  	}
			  },
		
			  {
			  	pt: {
			  		conexoes_bluetooth: "Bluetooth",
			  	},
			  	en: {
			  		conexoes_bluetooth: "Bluetooth",
			  	},
			  	es: {
			  		conexoes_bluetooth: "Bluetooth",
			  	}
			  },
		
			  {
			  	pt: {
			  		conexoes_wifi: "Wi-fi",
			  	},
			  	en: {
			  		conexoes_wifi: "Wi-fi",
			  	},
			  	es: {
			  		conexoes_wifi: "Wifi",
			  	}
			  },
		
			  {
			  	pt: {
			  		conexoes: "Conexões",
			  	},
			  	en: {
			  		conexoes: "Connections",
			  	},
			  	es: {
			  		conexoes: " Conexiones",
			  	}
			  },
		
			  {
			  	pt: {
			  		ultimo_avaliador: "Último avaliador: ",
			  	},
			  	en: {
			  		ultimo_avaliador: "Last reviewer:",
			  	},
			  	es: {
			  		ultimo_avaliador: "Último revisor:",
			  	}
			  },
		
			  {
			  	pt: {
			  		codigo_seguranca_cartao: "Código de segurança de 3 dígitos, impresso no verso do cartão de crédito/débito.",
			  	},
			  	en: {
			  		codigo_seguranca_cartao: "3-digit security code, printed on the back of the credit / debit card.",
			  	},
			  	es: {
			  		codigo_seguranca_cartao: "Código de seguridad de 3 dígitos, impreso en el reverso de la tarjeta de crédito / débito.",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_ano_expiracao: "Ano de expiração",
			  	},
			  	en: {
			  		pagamento_ano_expiracao: "Expiration year",
			  	},
			  	es: {
			  		pagamento_ano_expiracao: "Año de vencimiento",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_mes_expiracao: "Mês de expiração",
			  	},
			  	en: {
			  		pagamento_mes_expiracao: "Expiration month",
			  	},
			  	es: {
			  		pagamento_mes_expiracao: " Mes de expiración",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_numero_cartao: "Número do cartão",
			  	},
			  	en: {
			  		pagamento_numero_cartao: "Card number",
			  	},
			  	es: {
			  		pagamento_numero_cartao: "Numero de tarjeta",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_remover_imagem: "Remover imagem",
			  	},
			  	en: {
			  		menu_remover_imagem: "Remove image",
			  	},
			  	es: {
			  		menu_remover_imagem: "Quita la imagen",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_selecione_imagem: "Selecione uma imagem:",
			  	},
			  	en: {
			  		perfil_selecione_imagem: "Select an image:",
			  	},
			  	es: {
			  		perfil_selecione_imagem: "Seleccione una imagen:",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_vencimento: "Vencimento dia",
			  	},
			  	en: {
			  		pagamento_vencimento: "Expiration day",
			  	},
			  	es: {
			  		pagamento_vencimento: "Día de expiración",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_editar_pagamento: "Editar método de pagamento",
			  	},
			  	en: {
			  		pagamento_editar_pagamento: "Edit payment method",
			  	},
			  	es: {
			  		pagamento_editar_pagamento: "Editar método de pago",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_sem_pendencia: "Você não possui nenhuma fatura pendente. ",
			  	},
			  	en: {
			  		pagamento_sem_pendencia: " You do not have any pending invoices.",
			  	},
			  	es: {
			  		pagamento_sem_pendencia: " No tiene facturas pendientes.",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_fatura_pendente: "Faturas pendentes",
			  	},
			  	en: {
			  		pagamento_fatura_pendente: "Pending invoices",
			  	},
			  	es: {
			  		pagamento_fatura_pendente: "Facturas pendientes",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_faturas: "Visualizar faturas",
			  	},
			  	en: {
			  		pagamento_faturas: "View invoices",
			  	},
			  	es: {
			  		pagamento_faturas: " Ver facturas",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_adicionar_cartao: "Cartão de crédito / débito",
			  	},
			  	en: {
			  		pagamento_adicionar_cartao: "Credit / debit card",
			  	},
			  	es: {
			  		pagamento_adicionar_cartao: "Tarjeta de crédito / débito",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_adicionar_metodo: "Adicionar método de pagamento",
			  	},
			  	en: {
			  		pagamento_adicionar_metodo: "Add payment method",
			  	},
			  	es: {
			  		pagamento_adicionar_metodo: "Añadir método de pago",
			  	}
			  },
		
			  {
			  	pt: {
			  		pagamento_primeiro_cartao: "Método de pagamento",
			  	},
			  	en: {
			  		pagamento_primeiro_cartao: "Payment method",
			  	},
			  	es: {
			  		pagamento_primeiro_cartao: "Método de pago",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicios_gerais_config: "Ativar modo demonstração",
			  	},
			  	en: {
			  		exercicios_gerais_config: " Enable demo mode",
			  	},
			  	es: {
			  		exercicios_gerais_config: "Habilitar el modo de demostración",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuperacao_disponivel_excluidos: "Recuperação disponível: ",
			  	},
			  	en: {
			  		recuperacao_disponivel_excluidos: "Recovery available:",
			  	},
			  	es: {
			  		recuperacao_disponivel_excluidos: "Recuperación disponible:",
			  	}
			  },
		
			  {
			  	pt: {
			  		itens_disponiveis_excluidos: "Os itens da lista ficam disponiveís por 60 dias.",
			  	},
			  	en: {
			  		itens_disponiveis_excluidos: "List items are available for 60 days.",
			  	},
			  	es: {
			  		itens_disponiveis_excluidos: "Los elementos de la lista están disponibles durante 60 días.",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_exercícios: "Tempo em segundos: ",
			  	},
			  	en: {
			  		menu_exercícios: "Time in seconds:",
			  	},
			  	es: {
			  		menu_exercícios: "Tiempo en segundos:",
			  	}
			  },
		
			  {
			  	pt: {
			  		meu_plano_capacidade: "Capacidade",
			  	},
			  	en: {
			  		meu_plano_capacidade: "Capacity",
			  	},
			  	es: {
			  		meu_plano_capacidade: " Capacidad",
			  	}
			  },
		
			  {
			  	pt: {
			  		indicar: "INDICAR",
			  	},
			  	en: {
			  		indicar: "INDICATE",
			  	},
			  	es: {
			  		indicar: "INDICAR",
			  	}
			  },
		
			  {
			  	pt: {
			  		indique_um_fisio: "Indique um fisio dos bons para conhecer a Kinology:",
			  	},
			  	en: {
			  		indique_um_fisio: "Indicate a good physio to get to know Kinology:",
			  	},
			  	es: {
			  		indique_um_fisio: "Indica un buen fisioterapeuta para conocer Kinology:",
			  	}
			  },
		
			  {
			  	pt: {
			  		email_invalido: "E-mail inválido",
			  	},
			  	en: {
			  		email_invalido: " Invalid email",
			  	},
			  	es: {
			  		email_invalido: " Email inválido",
			  	}
			  },
		
			  {
			  	pt: {
			  		conta_inadimplente: "A sua conta está inadimplente. Por favor, realize o pagamento pendente para continuar a usar o aplicativo",
			  	},
			  	en: {
			  		conta_inadimplente: "Your account is in default. Please make the pending payment to continue using the app",
			  	},
			  	es: {
			  		conta_inadimplente: "Tu cuenta está predeterminada. Realice el pago pendiente para seguir usando la aplicación",
			  	}
			  },
		
			  {
			  	pt: {
			  		conta_ja_usada: "Essa conta já está sendo usada em outro dispositivo.  Deseja desconectar do dispositivo anterior e fazer login?",
			  	},
			  	en: {
			  		conta_ja_usada: "This account is already being used on another device. Do you want to disconnect from the previous device and log in?",
			  	},
			  	es: {
			  		conta_ja_usada: "Esta cuenta ya se está utilizando en otro dispositivo. ¿Quieres desconectarte del dispositivo anterior e iniciar sesión?",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_indisponivel: "Não foi possível concluir o login pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		login_indisponivel: "We were unable to complete the login because the device is not connected to the internet",
			  	},
			  	es: {
			  		login_indisponivel: "No pudimos completar el inicio de sesión porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		email_senha_invalidos: "E-mail ou senha inválidos",
			  	},
			  	en: {
			  		email_senha_invalidos: "Invalid email or password",
			  	},
			  	es: {
			  		email_senha_invalidos: "Correo electrónico o contraseña no válidos",
			  	}
			  },
		
			  {
			  	pt: {
			  		liberar_acesso_app: "Por favor, entre no e-mail cadastrado para liberar o acesso do aplicativo",
			  	},
			  	en: {
			  		liberar_acesso_app: "Ingrese el correo electrónico registrado para permitir el acceso a la aplicación",
			  	},
			  	es: {
			  		liberar_acesso_app: "Please enter the registered email to allow access to the application",
			  	}
			  },
		
			  {
			  	pt: {
			  		cadastro_indisponivel: "Não é possível realizar o cadastro pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		cadastro_indisponivel: "It is not possible to register because the device is not connected to the internet",
			  	},
			  	es: {
			  		cadastro_indisponivel: "No es posible registrarse porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		recuparacao_senha_indisponivel: "Recuperação de senha indisponível pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		recuparacao_senha_indisponivel: "Password recovery unavailable because the device is not connected to the internet",
			  	},
			  	es: {
			  		recuparacao_senha_indisponivel: " La recuperación de contraseña no está disponible porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		cadastro_nao_realizado: "Não foi possível concluir o cadastro pois o dispositivo não está conectado na internet",
			  	},
			  	en: {
			  		cadastro_nao_realizado: "It was not possible to complete the registration because the device is not connected to the internet",
			  	},
			  	es: {
			  		cadastro_nao_realizado: "No fue posible completar el registro porque el dispositivo no está conectado a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		email_ja_cadastrado_erro: "Esse e-mail já está sendo usado em outro cadastro",
			  	},
			  	en: {
			  		email_ja_cadastrado_erro: "This email is already being used in another registration",
			  	},
			  	es: {
			  		email_ja_cadastrado_erro: "Este correo electrónico ya se está utilizando en otro registro",
			  	}
			  },
		
			  {
			  	pt: {
			  		cadastro_app: "Cadastro realizado com sucesso. Por favor, entre no seu e-mail para liberar o acesso do aplicativo",
			  	},
			  	en: {
			  		cadastro_app: "Successful registration. Please enter your email address to allow access to the application",
			  	},
			  	es: {
			  		cadastro_app: "Registro exitoso. Ingrese su dirección de correo electrónico para permitir el acceso a la aplicación",
			  	}
			  },
		
			  {
			  	pt: {
			  		redefinicao_senha: "Enviamos para o seu e-mail cadastrado a redefinição de senha, verifique por favor",
			  	},
			  	en: {
			  		redefinicao_senha: "We sent the password reset to your registered email, please check",
			  	},
			  	es: {
			  		redefinicao_senha: "Enviamos el restablecimiento de contraseña a su correo electrónico registrado, verifique",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_request_error_other_acesss: "Essa conta já está sendo usada em outro dispositivo.  Deseja desconectar do dispositivo anterior e fazer login?",
			  	},
			  	en: {
			  		login_request_error_other_acesss: "This account is already being used on another device. Do you want to disconnect the previous device and log in?",
			  	},
			  	es: {
			  		login_request_error_other_acesss: "Esta cuenta ya se está utilizando en otro dispositivo. ¿Quieres desconectar el dispositivo anterior e iniciar sesión?",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_elastic_brasil: "Kinology Brasil",
			  	},
			  	en: {
			  		menu_lateral_elastic_brasil: "Kinology Brazil",
			  	},
			  	es: {
			  		menu_lateral_elastic_brasil: "Kinology Brasil",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_paciente_metros: "m",
			  	},
			  	en: {
			  		perfil_paciente_metros: "m",
			  	},
			  	es: {
			  		perfil_paciente_metros: "m",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_request_success: "Cadastro realizado com sucesso. Por favor, entre no seu e-mail para liberar o acesso do aplicativo.",
			  	},
			  	en: {
			  		register_request_success: "Successful registration. Please enter your email address to allow access to the application.",
			  	},
			  	es: {
			  		register_request_success: "Registro exitoso. Ingrese su dirección de correo electrónico para permitir el acceso a la aplicación.",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_popup_email_desbloqueio: "Enviamos um e-mail de desbloqueio, verifique por favor.",
			  	},
			  	en: {
			  		login_popup_email_desbloqueio: "We sent an unlock email, please check.",
			  	},
			  	es: {
			  		login_popup_email_desbloqueio: "Enviamos un correo electrónico de desbloqueo, verifique.",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_request_error_validade: "Por favor, entre no e-mail cadastrado para liberar o acesso do aplicativo.",
			  	},
			  	en: {
			  		login_request_error_validade: "Please enter the registered email to allow access to the application.",
			  	},
			  	es: {
			  		login_request_error_validade: "Ingrese el correo electrónico registrado para permitir el acceso a la aplicación.",
			  	}
			  },
		
			  {
			  	pt: {
			  		btn_enviar: "Enviar",
			  	},
			  	en: {
			  		btn_enviar: "Submit",
			  	},
			  	es: {
			  		btn_enviar: "Enviar",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_linguagens: "Português",
			  	},
			  	en: {
			  		login_linguagens: "English",
			  	},
			  	es: {
			  		login_linguagens: "Español",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeiro_acesso_celular: "Celular",
			  	},
			  	en: {
			  		primeiro_acesso_celular: "Cell phone",
			  	},
			  	es: {
			  		primeiro_acesso_celular: "Celular",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeiro_acesso_concluir: "Concluir",
			  	},
			  	en: {
			  		primeiro_acesso_concluir: "Done",
			  	},
			  	es: {
			  		primeiro_acesso_concluir: " Concluir",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeiro_acesso_doc_estrangeiro: "Clientes estrangeiros podem usar o número RNE",
			  	},
			  	en: {
			  		primeiro_acesso_doc_estrangeiro: "Foreigner customers can use RNE number",
			  	},
			  	es: {
			  		primeiro_acesso_doc_estrangeiro: "Los clientes extranjeros pueden utilizar el número RNE",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeiro_acesso_CPF: "CPF",
			  	},
			  	en: {
			  		primeiro_acesso_CPF: "RNE",
			  	},
			  	es: {
			  		primeiro_acesso_CPF: "RNE",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeiro_acesso_confirme_senha: "Confirme a senha",
			  	},
			  	en: {
			  		primeiro_acesso_confirme_senha: "Confirm the password",
			  	},
			  	es: {
			  		primeiro_acesso_confirme_senha: "Confirmar la contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeiro_acesso_nome: "Nome",
			  	},
			  	en: {
			  		primeiro_acesso_nome: "Name",
			  	},
			  	es: {
			  		primeiro_acesso_nome: "Nombre",
			  	}
			  },
		
			  {
			  	pt: {
			  		valores_rm_30: "30%",
			  	},
			  	en: {
			  		valores_rm_30: "30%",
			  	},
			  	es: {
			  		valores_rm_30: "30%",
			  	}
			  },
		
			  {
			  	pt: {
			  		valores_rm_40: "40%",
			  	},
			  	en: {
			  		valores_rm_40: "40%",
			  	},
			  	es: {
			  		valores_rm_40: "40%",
			  	}
			  },
		
			  {
			  	pt: {
			  		valores_rm_50: "50%",
			  	},
			  	en: {
			  		valores_rm_50: "50%",
			  	},
			  	es: {
			  		valores_rm_50: "50%",
			  	}
			  },
		
			  {
			  	pt: {
			  		valores_rm_60: "60%",
			  	},
			  	en: {
			  		valores_rm_60: "60%",
			  	},
			  	es: {
			  		valores_rm_60: "60%",
			  	}
			  },
		
			  {
			  	pt: {
			  		valores_rm_80: "80%",
			  	},
			  	en: {
			  		valores_rm_80: "80%",
			  	},
			  	es: {
			  		valores_rm_80: "80%",
			  	}
			  },
		
			  {
			  	pt: {
			  		valores_rm_70: "70%",
			  	},
			  	en: {
			  		valores_rm_70: "70%",
			  	},
			  	es: {
			  		valores_rm_70: "70%",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_request_error_inadim: "Conta inadimplente.",
			  	},
			  	en: {
			  		login_request_error_inadim: "Delinquent account.",
			  	},
			  	es: {
			  		login_request_error_inadim: "Cuenta delinquiva.",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_request_error_email: "Email ou senha inválidos.",
			  	},
			  	en: {
			  		login_request_error_email: "Invalid email or password.",
			  	},
			  	es: {
			  		login_request_error_email: "Correo electrónico o contraseña no válidos.",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_request_error: "Login falhou. Tentar novamente.",
			  	},
			  	en: {
			  		login_request_error: "Login failed. Try again.",
			  	},
			  	es: {
			  		login_request_error: "Error de inicio de sesion. Intentar nuevamente.",
			  	}
			  },
		
			  {
			  	pt: {
			  		forgot_request_error_email: "E-mail inválido.",
			  	},
			  	en: {
			  		forgot_request_error_email: "Invalid email.",
			  	},
			  	es: {
			  		forgot_request_error_email: "Email inválido.",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_request_error: "Cadastro falhou. Tentar novamente.",
			  	},
			  	en: {
			  		register_request_error: "Registration failed. Try again.",
			  	},
			  	es: {
			  		register_request_error: "Registro fallido. Intentar nuevamente.",
			  	}
			  },
		
			  {
			  	pt: {
			  		register_request_error_email: "Email utilizado em outro cadastro.",
			  	},
			  	en: {
			  		register_request_error_email: "Email used in another registration.",
			  	},
			  	es: {
			  		register_request_error_email: "Correo electrónico utilizado en otro registro.",
			  	}
			  },
		
			  {
			  	pt: {
			  		info_doc_cpf: "Os clientes brasileiros podem usar o número de CPF",
			  	},
			  	en: {
			  		info_doc_cpf: "Brazilian customers can use the CPF number",
			  	},
			  	es: {
			  		info_doc_cpf: "Los clientes brasileños pueden utilizar el número CPF",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_52: "Adução de ombro direito",
			  	},
			  	en: {
			  		exercicio_52: "Right shoulder adduction",
			  	},
			  	es: {
			  		exercicio_52: "Aducción del hombro derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_loc_mensagem: "Ative a localização para poder ativar o bluetooth",
			  	},
			  	en: {
			  		menu_lateral_loc_mensagem: "Turn on the location so you can turn on the bluetooth",
			  	},
			  	es: {
			  		menu_lateral_loc_mensagem: "Activa la ubicación para poder activar el bluetooth",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_busca_contatos: "Ative a busca nos contatos para indicar um amigo fisioterapeuta para conhecer o Kinology!",
			  	},
			  	en: {
			  		menu_lateral_busca_contatos: "Activate the search in the contacts to indicate a physical therapist friend to know the Kinology!",
			  	},
			  	es: {
			  		menu_lateral_busca_contatos: "¡Activa la búsqueda en los contactos para indicarle a un amigo fisioterapeuta que conozca el Kinology!",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_botao_avancar: "Avançar",
			  	},
			  	en: {
			  		menu_lateral_botao_avancar: "Advance",
			  	},
			  	es: {
			  		menu_lateral_botao_avancar: "Avance",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_alerta_mensg: "Um alerta sonoro é emitido ao efetuar uma repetição com o Kinology",
			  	},
			  	en: {
			  		menu_lateral_exercicios_alerta_mensg: "An audible alert is issued when doing a repeating with Kinology",
			  	},
			  	es: {
			  		menu_lateral_exercicios_alerta_mensg: "Se emite una alerta sonora al hacer una repetición con Kinology",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicio_alerta_mensg: "Um alerta vibratório é emitido ao efetuar uma repetição com o Kinology",
			  	},
			  	en: {
			  		menu_lateral_exercicio_alerta_mensg: "A vibrating alert is issued when performing a repeat with Kinology",
			  	},
			  	es: {
			  		menu_lateral_exercicio_alerta_mensg: "Se emite un alerta vibratoria al realizar una repetición con Kinology",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_38: "Flexão lateral de tronco direito",
			  	},
			  	en: {
			  		exercicio_38: "Lateral flexion of the right trunk",
			  	},
			  	es: {
			  		exercicio_38: " Flexión lateral del tronco derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_contagem_mensg: "Prepara o usuário para efetuar o exercício com uma contagem regressiva de 3,2,1",
			  	},
			  	en: {
			  		menu_lateral_exercicios_contagem_mensg: "Prepares the user to perform the exercise with a countdown of 3,2,1",
			  	},
			  	es: {
			  		menu_lateral_exercicios_contagem_mensg: "Prepara al usuario para realizar el ejercicio con una cuenta atrás de 3,2,1",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_25: "Remada alta esquerda",
			  	},
			  	en: {
			  		exercicio_25: "High left row",
			  	},
			  	es: {
			  		exercicio_25: "Fila alta izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_24: "Remada alta direita",
			  	},
			  	en: {
			  		exercicio_24: "Right high row",
			  	},
			  	es: {
			  		exercicio_24: "Fila alta derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_27: "Remada direita",
			  	},
			  	en: {
			  		exercicio_27: "Right row",
			  	},
			  	es: {
			  		exercicio_27: "Fila derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_person_estado: "Estado",
			  	},
			  	en: {
			  		menu_lateral_person_estado: "State",
			  	},
			  	es: {
			  		menu_lateral_person_estado: "Departamento",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_person_rua: "Rua",
			  	},
			  	en: {
			  		menu_lateral_person_rua: "Street",
			  	},
			  	es: {
			  		menu_lateral_person_rua: "Calle",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_fale_conos_problemas_app: "Problemas com o app ou dinamô",
			  	},
			  	en: {
			  		menu_lateral_fale_conos_problemas_app: "Problems with the app or dinamô",
			  	},
			  	es: {
			  		menu_lateral_fale_conos_problemas_app: "Problemas con la aplicación o dinamô",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_duvidas_guia: "Guia de avaliação",
			  	},
			  	en: {
			  		menu_lateral_duvidas_guia: " Evaluation guide",
			  	},
			  	es: {
			  		menu_lateral_duvidas_guia: " Guía de evaluación",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_nova_senha: "Nova senha",
			  	},
			  	en: {
			  		menu_lateral_nova_senha: "New password",
			  	},
			  	es: {
			  		menu_lateral_nova_senha: "Nueva contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_excluir_tags: "Excluir as tags selecionadas?",
			  	},
			  	en: {
			  		modal_excluir_tags: "Delete selected tags?",
			  	},
			  	es: {
			  		modal_excluir_tags: "¿Eliminar las etiquetas seleccionadas?",
			  	}
			  },
			  {
				pt: {
					questionario_respondido_failure: "Não foi possível responder ao questionário",
				},
				en: {
					questionario_respondido_failure: "Unable to respond to the questionnaire",
				},
				es: {
					questionario_respondido_failure: "No se puede responder al cuestionario.",
			 	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_botao_salvar: "Salvar",
			  	},
			  	en: {
			  		menu_lateral_botao_salvar: "Save",
			  	},
			  	es: {
			  		menu_lateral_botao_salvar: "Salvar",
			  	}
			  },
			  {
				pt: {
					jump: "Pular",
				},
				en: {
					jump: "To jump",
				},
				es: {
					jump: "Saltar",
				}
			  },
			  {
			  	pt: {
			  		voce_esta_satisfeito: "Você está satisfeito com este protocolo para o paciente",
			  	},
			  	en: {
			  		voce_esta_satisfeito: "Are you satisfied with this patient protocol",
			  	},
			  	es: {
			  		voce_esta_satisfeito: "Está satisfecho con este protocolo para pacientes?",
			  	}
			  },
			  {
				pt: {
					Criado_por_voce: "Criado por você",
				},
				en: {
					Criado_por_voce: "Created by you",
				},
				es: {
					Criado_por_voce: "Creado por ti",
				}
			 },
			  {
				pt: {
					tempo_estimado: "Tempo estimado",
				},
				en: {
					tempo_estimado: "Estimated time",
				},
				es: {
					tempo_estimado: "Tiempo estimado",
				}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_meu_plano_treino: "Treino livre",
			  	},
			  	en: {
			  		menu_lateral_meu_plano_treino: "Free workout",
			  	},
			  	es: {
			  		menu_lateral_meu_plano_treino: "Entrenamiento libre",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_5: "Natação freestyle",
			  	},
			  	en: {
			  		exercicio_5: "Swimming freestyle",
			  	},
			  	es: {
			  		exercicio_5: "Natación estilo libre",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_4: "Patada direita",
			  	},
			  	en: {
			  		exercicio_4: "Right kick",
			  	},
			  	es: {
			  		exercicio_4: "Patada derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_3: "Nadar",
			  	},
			  	en: {
			  		exercicio_3: " Swim",
			  	},
			  	es: {
			  		exercicio_3: " Nadar",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_2: "Remada direita sentado",
			  	},
			  	en: {
			  		exercicio_2: "Right seated row",
			  	},
			  	es: {
			  		exercicio_2: " Remo derecho sentado ",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_modo_tv: "Modo TV",
			  	},
			  	en: {
			  		menu_lateral_modo_tv: "TV mode",
			  	},
			  	es: {
			  		menu_lateral_modo_tv: "Modo TV",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_avaliadores: "Avaliadores",
			  	},
			  	en: {
			  		menu_lateral_avaliadores: "Evaluators",
			  	},
			  	es: {
			  		menu_lateral_avaliadores: "Evaluadores",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_paciente_treino: "Treino Livre",
			  	},
			  	en: {
			  		perfil_paciente_treino: "Free training",
			  	},
			  	es: {
			  		perfil_paciente_treino: "Capacitación libre",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_paciente_editar: "Editar",
			  	},
			  	en: {
			  		perfil_paciente_editar: "Edit",
			  	},
			  	es: {
			  		perfil_paciente_editar: "Editar",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_validade: "Validade",
			  	},
			  	en: {
			  		menu_lateral_validade: "Expiration date",
			  	},
			  	es: {
			  		menu_lateral_validade: "Validez",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_paciente: "Relatórios",
			  	},
			  	en: {
			  		perfil_paciente: "Reports",
			  	},
			  	es: {
			  		perfil_paciente: "Informes",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_pacientes: "Pacientes",
			  	},
			  	en: {
			  		pag_inicial_pacientes: "Patients",
			  	},
			  	es: {
			  		pag_inicial_pacientes: "Pacientes",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_popup_email_erro_nao_encontrado: "E-mail não encontrado ",
			  	},
			  	en: {
			  		login_popup_email_erro_nao_encontrado: "Email not found",
			  	},
			  	es: {
			  		login_popup_email_erro_nao_encontrado: "Correo electrónico no encontrado",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_popup_email: "Enviamos um e-mail com a redefinição de senha, verifique por favor",
			  	},
			  	en: {
			  		login_popup_email: "We sent an email with a password reset, please check it out",
			  	},
			  	es: {
			  		login_popup_email: "Enviamos un correo electrónico con un restablecimiento de contraseña, verifique por favor",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_excluidos_texto: "Excluídos",
			  	},
			  	en: {
			  		menu_lateral_excluidos_texto: "Excluded",
			  	},
			  	es: {
			  		menu_lateral_excluidos_texto: "Eliminado",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuir_tags_busca: "Busque ou digite uma nova",
			  	},
			  	en: {
			  		atribuir_tags_busca: "Search or type a new one",
			  	},
			  	es: {
			  		atribuir_tags_busca: "Busca o escribe un nuevo",
			  	}
			  },
		
			  {
			  	pt: {
			  		atribuir_tags_paciente: "Tags deste(s) paciente(s)",
			  	},
			  	en: {
			  		atribuir_tags_paciente: "Tags of this/these patient(s)",
			  	},
			  	es: {
			  		atribuir_tags_paciente: "Etiquetas de este/estos paciente(s)",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluir_protocolos: "Excluir o(s) protocolo(s) selecionado(s)?",
			  	},
			  	en: {
			  		excluir_protocolos: "Delete the selected protocol(s)?",
			  	},
			  	es: {
			  		excluir_protocolos: "¿Eliminar el/los protocolo(s) seleccionado(s)?",
			  	}
			  },
		
			  {
			  	pt: {
			  		opcao_dois_protocolo: "Importar protocolo",
			  	},
			  	en: {
			  		opcao_dois_protocolo: " Import protocol",
			  	},
			  	es: {
			  		opcao_dois_protocolo: "Importar protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		opcoes_protocolos: "Criar o meu protocolo",
			  	},
			  	en: {
			  		opcoes_protocolos: "Create my protocol",
			  	},
			  	es: {
			  		opcoes_protocolos: "Crear mi protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		primeira_opcao_protocolo: "Quero criar um protocolo escolhendo os exercícios na lista da Kinology e as configurações de carga que mais se adequam ao meu uso",
			  	},
			  	en: {
			  		primeira_opcao_protocolo: "I want to create a protocol choosing the exercises from the Kinology list and the load settings that best suit my use",
			  	},
			  	es: {
			  		primeira_opcao_protocolo: "Quiero crear un protocolo eligiendo los ejercicios de la lista de Kinology y los ajustes de carga que mejor se adapten a mi uso",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_proto_aval: "Avaliação (Contração Máxima) ",
			  	},
			  	en: {
			  		escolha_proto_aval: "Evaluation (Maximum Contraction)",
			  	},
			  	es: {
			  		escolha_proto_aval: "Evaluación (Contracción Máxima)",
			  	}
			  },
		
			  {
			  	pt: {
			  		funcional_explicacao: "Quero gerar um protocolo funcional definindo o número de repetições, carga mínima/máxima e percentual de RM. ",
			  	},
			  	en: {
			  		funcional_explicacao: "I want to generate a functional protocol defining the number of repetitions, minimum/maximum load and percentage of MR.",
			  	},
			  	es: {
			  		funcional_explicacao: "Quiero generar un protocolo funcional que defina el número de repeticiones, carga mínima/máxima y porcentaje de RM.",
			  	}
			  },
		
			  {
			  	pt: {
			  		ambos_explicacao: "Quero gerar um protocolo definindo as configurações de avaliação e funcional para usar conforme minha necessidade.",
			  	},
			  	en: {
			  		ambos_explicacao: "I want to generate a protocol defining the evaluation and functional settings to use as needed.",
			  	},
			  	es: {
			  		ambos_explicacao: "Quiero generar un protocolo que defina la evaluación y la configuración funcional para usar según sea necesario.",
			  	}
			  },
		
			  {
			  	pt: {
			  		lista_de_exercicios_config: "Configurações",
			  	},
			  	en: {
			  		lista_de_exercicios_config: "Settings",
			  	},
			  	es: {
			  		lista_de_exercicios_config: "Configuraciones",
			  	}
			  },
		
			  {
			  	pt: {
			  		config_funcional_rm: "Percentual de RM",
			  	},
			  	en: {
			  		config_funcional_rm: "Percentage of MR",
			  	},
			  	es: {
			  		config_funcional_rm: "Porcentaje de RM",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_65: "Afundo direito",
			  	},
			  	en: {
			  		exercicio_65: "Right lunge",
			  	},
			  	es: {
			  		exercicio_65: "Estocada derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_66: "Afundo esquerdo",
			  	},
			  	en: {
			  		exercicio_66: "Left lunge",
			  	},
			  	es: {
			  		exercicio_66: "Estocada izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		redefinir_rm: "Redefinir as configurações de RM?",
			  	},
			  	en: {
			  		redefinir_rm: "Reset RM settings?",
			  	},
			  	es: {
			  		redefinir_rm: "¿Restablecer la configuración de RM?",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_75: "Extensão de quadril esquerdo",
			  	},
			  	en: {
			  		exercicio_75: "Left hip extension",
			  	},
			  	es: {
			  		exercicio_75: "Extensión de cadera izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_74: "Extensão de quadril direito",
			  	},
			  	en: {
			  		exercicio_74: "Right hip extension",
			  	},
			  	es: {
			  		exercicio_74: "Extensión de cadera derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_73: "Flexão de quadril esquerdo",
			  	},
			  	en: {
			  		exercicio_73: "Left hip flexion",
			  	},
			  	es: {
			  		exercicio_73: "Flexión de cadera izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_72: "Flexão de quadril direito",
			  	},
			  	en: {
			  		exercicio_72: "Right hip flexion",
			  	},
			  	es: {
			  		exercicio_72: "Flexión de cadera derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_71: "Extensão de joelho esquerdo",
			  	},
			  	en: {
			  		exercicio_71: "Left knee extension",
			  	},
			  	es: {
			  		exercicio_71: "Extensión de rodilla izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_70: "Extensão de joelho direito",
			  	},
			  	en: {
			  		exercicio_70: "Right knee extension",
			  	},
			  	es: {
			  		exercicio_70: "Extensión de rodilla derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_69: "Flexão de joelho esquerdo",
			  	},
			  	en: {
			  		exercicio_69: "Left knee flexion",
			  	},
			  	es: {
			  		exercicio_69: " Flexión de rodilla izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_68: "Flexão de joelho direito",
			  	},
			  	en: {
			  		exercicio_68: " Right knee flexion",
			  	},
			  	es: {
			  		exercicio_68: "Flexión de rodilla derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_67: "Agachamento",
			  	},
			  	en: {
			  		exercicio_67: "Squat",
			  	},
			  	es: {
			  		exercicio_67: "Ponerse en cuclillas",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_64: "Adução de quadril esquerdo",
			  	},
			  	en: {
			  		exercicio_64: "Left hip adduction",
			  	},
			  	es: {
			  		exercicio_64: "Aducción de cadera izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_63: "Adução de quadril direito",
			  	},
			  	en: {
			  		exercicio_63: "Right hip adduction",
			  	},
			  	es: {
			  		exercicio_63: "Aducción de cadera derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_62: "Abdução de quadril esquerdo",
			  	},
			  	en: {
			  		exercicio_62: "Left hip abduction",
			  	},
			  	es: {
			  		exercicio_62: "Abducción de cadera izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_61: "Abdução de quadril direito",
			  	},
			  	en: {
			  		exercicio_61: "Right hip abduction",
			  	},
			  	es: {
			  		exercicio_61: "Abducción de cadera derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_60: "Dorsiflexão esquerda",
			  	},
			  	en: {
			  		exercicio_60: " Left dorsiflexion",
			  	},
			  	es: {
			  		exercicio_60: "Dorsiflexión izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_59: "Dorsiflexão direita",
			  	},
			  	en: {
			  		exercicio_59: "Right dorsiflexion",
			  	},
			  	es: {
			  		exercicio_59: "Dorsiflexión derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_58: "Flexão plantar esquerda",
			  	},
			  	en: {
			  		exercicio_58: " Left plantar flexion",
			  	},
			  	es: {
			  		exercicio_58: "Flexión plantar izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_57: "Flexão plantar direita",
			  	},
			  	en: {
			  		exercicio_57: "Right plantar flexion",
			  	},
			  	es: {
			  		exercicio_57: " Flexión plantar derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_56: "Desenvolvimento de ombros esquerdo",
			  	},
			  	en: {
			  		exercicio_56: "Left shoulder development",
			  	},
			  	es: {
			  		exercicio_56: "Desarrollo del hombro izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_55: "Desenvolvimento de ombros direito",
			  	},
			  	en: {
			  		exercicio_55: "Development of right shoulders",
			  	},
			  	es: {
			  		exercicio_55: " Desarrollo de hombros derechos",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_54: "Desenvolvimento de ombros",
			  	},
			  	en: {
			  		exercicio_54: " Shoulder development",
			  	},
			  	es: {
			  		exercicio_54: "Desarrollo del hombro",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_53: "Adução de ombro esquerdo",
			  	},
			  	en: {
			  		exercicio_53: " Left shoulder adduction",
			  	},
			  	es: {
			  		exercicio_53: "Aducción del hombro izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_51: "Abdução de ombro esquerdo",
			  	},
			  	en: {
			  		exercicio_51: "Left shoulder abduction",
			  	},
			  	es: {
			  		exercicio_51: " Abducción del hombro izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_50: "Abdução de ombro direito",
			  	},
			  	en: {
			  		exercicio_50: "Right shoulder abduction",
			  	},
			  	es: {
			  		exercicio_50: "Abducción del hombro derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_49: "Extensão de ombro esquerdo",
			  	},
			  	en: {
			  		exercicio_49: "Left shoulder extension",
			  	},
			  	es: {
			  		exercicio_49: " Extensión del hombro izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_48: "Extensão de ombro direito",
			  	},
			  	en: {
			  		exercicio_48: "Right shoulder extension",
			  	},
			  	es: {
			  		exercicio_48: "Extensión del hombro derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_47: "Flexão de ombro esquerdo",
			  	},
			  	en: {
			  		exercicio_47: " Left Shoulder Flexion",
			  	},
			  	es: {
			  		exercicio_47: " Flexión del hombro izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_46: "Flexão de ombro direito",
			  	},
			  	en: {
			  		exercicio_46: "Right shoulder flexion",
			  	},
			  	es: {
			  		exercicio_46: " Flexión del hombro derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_45: "Flexão de ombros",
			  	},
			  	en: {
			  		exercicio_45: "Shoulder flexion",
			  	},
			  	es: {
			  		exercicio_45: "Flexión del hombro",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_44: "Rotação externa esquerda",
			  	},
			  	en: {
			  		exercicio_44: " Left external rotation",
			  	},
			  	es: {
			  		exercicio_44: "Rotación externa izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_43: "Rotação externa direita",
			  	},
			  	en: {
			  		exercicio_43: " Right external rotation",
			  	},
			  	es: {
			  		exercicio_43: "Rotación externa derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_42: "Rotação interna esquerda",
			  	},
			  	en: {
			  		exercicio_42: "Left internal rotation",
			  	},
			  	es: {
			  		exercicio_42: " Rotación interna izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_41: "Rotação interna direita",
			  	},
			  	en: {
			  		exercicio_41: " Right internal rotation",
			  	},
			  	es: {
			  		exercicio_41: "Rotación interna derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_40: "Abdominal",
			  	},
			  	en: {
			  		exercicio_40: " Abdominal",
			  	},
			  	es: {
			  		exercicio_40: "Abdominal",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_39: "Flexão lateral de tronco esquerdo",
			  	},
			  	en: {
			  		exercicio_39: "Lateral flexion of the left trunk",
			  	},
			  	es: {
			  		exercicio_39: " Flexión lateral del tronco izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_37: "Crucifixo esquerdo",
			  	},
			  	en: {
			  		exercicio_37: " Left crucifix",
			  	},
			  	es: {
			  		exercicio_37: "Crucifijo izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_36: "Crucifixo direito",
			  	},
			  	en: {
			  		exercicio_36: "Right Crucifix",
			  	},
			  	es: {
			  		exercicio_36: "Crucifijo derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_35: "Crucifixo",
			  	},
			  	en: {
			  		exercicio_35: "Crucifix",
			  	},
			  	es: {
			  		exercicio_35: "Crucifijo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_34: "Supino reto esquerdo",
			  	},
			  	en: {
			  		exercicio_34: "Left bench press",
			  	},
			  	es: {
			  		exercicio_34: " Press de banca izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_33: "Supino reto direito",
			  	},
			  	en: {
			  		exercicio_33: "Right bench press",
			  	},
			  	es: {
			  		exercicio_33: " Press de banca derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_32: "Supino reto",
			  	},
			  	en: {
			  		exercicio_32: "Bench press",
			  	},
			  	es: {
			  		exercicio_32: "Press de banca",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_31: "Remada aberta esquerda",
			  	},
			  	en: {
			  		exercicio_31: "Left open row",
			  	},
			  	es: {
			  		exercicio_31: " Fila abierta izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_30: "Remada aberta direita",
			  	},
			  	en: {
			  		exercicio_30: "Right open row",
			  	},
			  	es: {
			  		exercicio_30: "Fila abierta a la derecha",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_29: "Remada aberta",
			  	},
			  	en: {
			  		exercicio_29: "Open row",
			  	},
			  	es: {
			  		exercicio_29: " Fila abierta",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_28: "Remada esquerda",
			  	},
			  	en: {
			  		exercicio_28: "Left row",
			  	},
			  	es: {
			  		exercicio_28: "Fila izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_26: "Remada bilateral",
			  	},
			  	en: {
			  		exercicio_26: "Bilateral rowing",
			  	},
			  	es: {
			  		exercicio_26: "Remo bilateral",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_23: "Remada alta bilateral ",
			  	},
			  	en: {
			  		exercicio_23: "Bilateral high row",
			  	},
			  	es: {
			  		exercicio_23: "Fila alta bilateral",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_21: "Extensão de cotovelo testa direito",
			  	},
			  	en: {
			  		exercicio_21: "Right forehead elbow extension",
			  	},
			  	es: {
			  		exercicio_21: " Pruebas de extensión del codo derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_22: "Extensão de cotovelo testa esquerda",
			  	},
			  	en: {
			  		exercicio_22: "Left forehead elbow extension",
			  	},
			  	es: {
			  		exercicio_22: "Extensión del codo de la frente izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_20: "Extensão de cotovelo testa sem sagital",
			  	},
			  	en: {
			  		exercicio_20: " Elbow extension forehead without sagittal",
			  	},
			  	es: {
			  		exercicio_20: "Extensión de codo frente sin sagital",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_18: "Extensão de cotovelo esquerdo",
			  	},
			  	en: {
			  		exercicio_18: "Left elbow extension",
			  	},
			  	es: {
			  		exercicio_18: "Extensión del codo izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_17: "Extensão de cotovelo francês direito",
			  	},
			  	en: {
			  		exercicio_17: "Right French elbow extension",
			  	},
			  	es: {
			  		exercicio_17: " Extensión de codo francés derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercício_16: "Extensão de cotovelo francês bilateral",
			  	},
			  	en: {
			  		exercício_16: "Bilateral French elbow extension",
			  	},
			  	es: {
			  		exercício_16: "Extensión de codo francesa bilateral",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_15: "Extensão de cotovelo direito",
			  	},
			  	en: {
			  		exercicio_15: "Right elbow extension",
			  	},
			  	es: {
			  		exercicio_15: "Extensión del codo derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_14: "Extensão de cotovelo bilateral",
			  	},
			  	en: {
			  		exercicio_14: "Bilateral elbow extension",
			  	},
			  	es: {
			  		exercicio_14: " Extensión de codo bilateral",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_13: "Flexão de cotovelo esquerdo",
			  	},
			  	en: {
			  		exercicio_13: " Left elbow flexion",
			  	},
			  	es: {
			  		exercicio_13: "Flexión del codo izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_11: "Flexão de cotovelo bilateral",
			  	},
			  	en: {
			  		exercicio_11: "Bilateral elbow flexion",
			  	},
			  	es: {
			  		exercicio_11: "Flexión bilateral del codo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_12: "Flexão de cotovelo direito",
			  	},
			  	en: {
			  		exercicio_12: "Right elbow flexion",
			  	},
			  	es: {
			  		exercicio_12: "Flexión del codo derecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_10: "Flexão de joelho esquerdo",
			  	},
			  	en: {
			  		exercicio_10: "Left knee flexion",
			  	},
			  	es: {
			  		exercicio_10: "Flexión de rodilla izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_9: "Extensão de cotovelo esquerdo",
			  	},
			  	en: {
			  		exercicio_9: "Left elbow extension",
			  	},
			  	es: {
			  		exercicio_9: "Extensión del codo izquierdo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_8: "Extensão de joelho esquerdo",
			  	},
			  	en: {
			  		exercicio_8: "Left knee extension",
			  	},
			  	es: {
			  		exercicio_8: "Extensión de rodilla izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_7: "Saque",
			  	},
			  	en: {
			  		exercicio_7: "Withdraw",
			  	},
			  	es: {
			  		exercicio_7: "Retirar",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_6: "Soco",
			  	},
			  	en: {
			  		exercicio_6: "Punch",
			  	},
			  	es: {
			  		exercicio_6: "Puñetazo",
			  	}
			  },
		
			  {
			  	pt: {
			  		exercicio_1: "Remada sentado esquerdo",
			  	},
			  	en: {
			  		exercicio_1: "Left seated paddle",
			  	},
			  	es: {
			  		exercicio_1: "Remo sentado a la izquierda",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_paciente_modal_relatorios: "Este protocolo gera dados para o relatório de assimetria",
			  	},
			  	en: {
			  		perfil_paciente_modal_relatorios: "This protocol generates data for the asymmetry report",
			  	},
			  	es: {
			  		perfil_paciente_modal_relatorios: " Este protocolo genera datos para el informe de asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		excluir_protocolo: "Excluir protocolo?",
			  	},
			  	en: {
			  		excluir_protocolo: "Delete protocol?",
			  	},
			  	es: {
			  		excluir_protocolo: " ¿Eliminar protocolo?",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_paciente_novo: "Novo",
			  	},
			  	en: {
			  		perfil_paciente_novo: "New",
			  	},
			  	es: {
			  		perfil_paciente_novo: "Nuevo",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_paciente_ver_menos: "Ver menos",
			  	},
			  	en: {
			  		perfil_paciente_ver_menos: "See less",
			  	},
			  	es: {
			  		perfil_paciente_ver_menos: " Ver menos",
			  	}
			  },
		
			  {
			  	pt: {
			  		botao_iniciar_protocolo: "Iniciar",
			  	},
			  	en: {
			  		botao_iniciar_protocolo: "Start",
			  	},
			  	es: {
			  		botao_iniciar_protocolo: "Comienzo",
			  	}
			  },
		
			  {
			  	pt: {
			  		perfil_paciente_ver_mais: "Ver mais ",
			  	},
			  	en: {
			  		perfil_paciente_ver_mais: "See more",
			  	},
			  	es: {
			  		perfil_paciente_ver_mais: "Ver más",
			  	}
			  },
		
			  {
			  	pt: {
			  		editar_exercicio: "Editar exercício",
			  	},
			  	en: {
			  		editar_exercicio: "Edit exercise",
			  	},
			  	es: {
			  		editar_exercicio: "Editar ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		hemisferios_titulo: "Hemisfério sagital",
			  	},
			  	en: {
			  		hemisferios_titulo: "Sagittal Hemisphere",
			  	},
			  	es: {
			  		hemisferios_titulo: "Hemisferio sagital",
			  	}
			  },
		
			  {
			  	pt: {
			  		criar_exercicio_opcoes: "Selecione as opções desejadas:",
			  	},
			  	en: {
			  		criar_exercicio_opcoes: " Select the options you want:",
			  	},
			  	es: {
			  		criar_exercicio_opcoes: "Seleccione las opciones que desee:",
			  	}
			  },
		
			  {
			  	pt: {
			  		grupame_coxas: "Coxas",
			  	},
			  	en: {
			  		grupame_coxas: "Thighs",
			  	},
			  	es: {
			  		grupame_coxas: " Muslos",
			  	}
			  },
		
			  {
			  	pt: {
			  		grupame_peito: "Peito",
			  	},
			  	en: {
			  		grupame_peito: "Chest",
			  	},
			  	es: {
			  		grupame_peito: "Pecho",
			  	}
			  },
		
			  {
			  	pt: {
			  		grupame_costas: "Costas",
			  	},
			  	en: {
			  		grupame_costas: "Back",
			  	},
			  	es: {
			  		grupame_costas: "Atrás",
			  	}
			  },
		
			  {
			  	pt: {
			  		grupame_pernas: "Pernas",
			  	},
			  	en: {
			  		grupame_pernas: "Legs",
			  	},
			  	es: {
			  		grupame_pernas: "Piernas",
			  	}
			  },
		
			  {
			  	pt: {
			  		grupame_ombros: "Ombros",
			  	},
			  	en: {
			  		grupame_ombros: "Shoulders",
			  	},
			  	es: {
			  		grupame_ombros: " Espaldas",
			  	}
			  },
		
			  {
			  	pt: {
			  		grupame_bracos: "Braços",
			  	},
			  	en: {
			  		grupame_bracos: "Arms",
			  	},
			  	es: {
			  		grupame_bracos: "Brazos",
			  	}
			  },
		
			  {
			  	pt: {
			  		selecionar_grupamento: "Selecione o grupamento desejado:",
			  	},
			  	en: {
			  		selecionar_grupamento: "Select the desired group:",
			  	},
			  	es: {
			  		selecionar_grupamento: "Seleccione el grupo deseado:",
			  	}
			  },
		
			  {
			  	pt: {
			  		criar_exercicio: "Criar exercício",
			  	},
			  	en: {
			  		criar_exercicio: "Create exercise",
			  	},
			  	es: {
			  		criar_exercicio: "Crear ejercicio",
			  	}
			  },
		
			  {
			  	pt: {
			  		modal_percentual_mensagem: "Para isso é necessário já ter realizado alguma avalição com esta pessoa.",
			  	},
			  	en: {
			  		modal_percentual_mensagem: "For that it is necessary to have already carried out some evaluation with this person.",
			  	},
			  	es: {
			  		modal_percentual_mensagem: "Para eso es necesario haber realizado ya alguna evaluación con esta persona.",
			  	}
			  },
		
			  {
			  	pt: {
			  		config_funcional_historico_rm: "Sem histórico de RM",
			  	},
			  	en: {
			  		config_funcional_historico_rm: "No history of MR",
			  	},
			  	es: {
			  		config_funcional_historico_rm: "Sin antecedentes de RM",
			  	}
			  },
		
			  {
			  	pt: {
			  		config_funcional_carga_max: "Carga máx (Kg)",
			  	},
			  	en: {
			  		config_funcional_carga_max: "Max load (Kg)",
			  	},
			  	es: {
			  		config_funcional_carga_max: "Carga máx (Kg)",
			  	}
			  },
		
			  {
			  	pt: {
			  		config_funcional: "Funcional",
			  	},
			  	en: {
			  		config_funcional: " Functional",
			  	},
			  	es: {
			  		config_funcional: "Funcional",
			  	}
			  },
		
			  {
			  	pt: {
			  		config_aval_carg_min: "Carga mín (Kg)",
			  	},
			  	en: {
			  		config_aval_carg_min: "Min load (Kg)",
			  	},
			  	es: {
			  		config_aval_carg_min: "Carga mín (Kg)",
			  	}
			  },
		
			  {
			  	pt: {
			  		config_aval_repeticoes: "Repetições",
			  	},
			  	en: {
			  		config_aval_repeticoes: "Repetitions",
			  	},
			  	es: {
			  		config_aval_repeticoes: " Repeticiones",
			  	}
			  },
		
			  {
			  	pt: {
			  		config_avaliacao: "Avaliação",
			  	},
			  	en: {
			  		config_avaliacao: "Evaluation",
			  	},
			  	es: {
			  		config_avaliacao: " Evaluación",
			  	}
			  },
		
			  {
			  	pt: {
			  		lista_de_exercicio_exercicio: "Exercícios",
			  	},
			  	en: {
			  		lista_de_exercicio_exercicio: "Exercises",
			  	},
			  	es: {
			  		lista_de_exercicio_exercicio: " Ejercicios",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_ambos: "Ambos",
			  	},
			  	en: {
			  		escolha_ambos: "Both",
			  	},
			  	es: {
			  		escolha_ambos: " Ambas",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_funcional: "Funcional (Treino funcional)",
			  	},
			  	en: {
			  		escolha_funcional: " Functional (Functional training)",
			  	},
			  	es: {
			  		escolha_funcional: " Funcional (entrenamiento funcional)",
			  	}
			  },
		
			  {
			  	pt: {
			  		escolha_avali: "Quero gerar um protocolo de avaliação definindo o número de repetições e carga mínima",
			  	},
			  	en: {
			  		escolha_avali: "I want to generate an evaluation protocol defining the number of repetitions and minimum load",
			  	},
			  	es: {
			  		escolha_avali: "Quiero generar un protocolo de evaluación que defina el número de repeticiones y carga mínima",
			  	}
			  },
		
			  {
			  	pt: {
			  		opcao_tres_protocolo: "Criar exercício personalizado",
			  	},
			  	en: {
			  		opcao_tres_protocolo: "Create custom exercise",
			  	},
			  	es: {
			  		opcao_tres_protocolo: "Crear ejercicio personalizado",
			  	}
			  },
		
			  {
			  	pt: {
			  		segunda_opcao_protocolo: "Quero criar um protocolo a partir de outro já existente",
			  	},
			  	en: {
			  		segunda_opcao_protocolo: "I want to create a protocol from another one that already exists",
			  	},
			  	es: {
			  		segunda_opcao_protocolo: "Quiero crear un protocolo a partir de uno existente",
			  	}
			  },
		
			  {
			  	pt: {
			  		mensag_nome_inválido: "Nome muito curto",
			  	},
			  	en: {
			  		mensag_nome_inválido: "Name too short",
			  	},
			  	es: {
			  		mensag_nome_inválido: "Nombre muy corto",
			  	}
			  },
		
			  {
			  	pt: {
			  		criacao_proto: "Nome do protocolo",
			  	},
			  	en: {
			  		criacao_proto: "Protocol name",
			  	},
			  	es: {
			  		criacao_proto: "Nombre del protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		protoc_arquivados: "Protocolos arquivados",
			  	},
			  	en: {
			  		protoc_arquivados: "Archived protocols",
			  	},
			  	es: {
			  		protoc_arquivados: "Protocolos archivados",
			  	}
			  },
		
			  {
			  	pt: {
			  		arquivar_protocolos: "Arquivados",
			  	},
			  	en: {
			  		arquivar_protocolos: "Archived",
			  	},
			  	es: {
			  		arquivar_protocolos: "Archivado",
			  	}
			  },
		
			  {
			  	pt: {
			  		protocolo_excluido: "Protocolos excluídos",
			  	},
			  	en: {
			  		protocolo_excluido: "Protocols excluded",
			  	},
			  	es: {
			  		protocolo_excluido: " Protocolos excluidos",
			  	}
			  },
		
			  {
			  	pt: {
			  		botao_cancelar: "Cancelar",
			  	},
			  	en: {
			  		botao_cancelar: "Cancel",
			  	},
			  	es: {
			  		botao_cancelar: " Cancelar",
			  	}
			  },
		
			  {
			  	pt: {
			  		gerenciar_proto_arquivar: "Arquivar",
			  	},
			  	en: {
			  		gerenciar_proto_arquivar: "File",
			  	},
			  	es: {
			  		gerenciar_proto_arquivar: "Archivar",
			  	}
			  },
		
			  {
			  	pt: {
			  		erro_video: "Sem conexão com a internet",
			  	},
			  	en: {
			  		erro_video: "No internet connection",
			  	},
			  	es: {
			  		erro_video: " Sin conexión a Internet",
			  	}
			  },
		
			  {
			  	pt: {
			  		gerenciar_pacientes_tags: "Atribuir tags",
			  	},
			  	en: {
			  		gerenciar_pacientes_tags: "Assign tags",
			  	},
			  	es: {
			  		gerenciar_pacientes_tags: "Asignar etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		gerenciar_pacientes_aval: "Atribuir avaliador",
			  	},
			  	en: {
			  		gerenciar_pacientes_aval: "Assign  evaluator",
			  	},
			  	es: {
			  		gerenciar_pacientes_aval: "Asignar tasador",
			  	}
			  },
		
			  {
			  	pt: {
			  		add_avaliador: "Administrador",
			  	},
			  	en: {
			  		add_avaliador: "Administrator",
			  	},
			  	es: {
			  		add_avaliador: "Administrador",
			  	}
			  },
		
			  {
			  	pt: {
			  		add_paciente: "Adicionar tag",
			  	},
			  	en: {
			  		add_paciente: "Add tag",
			  	},
			  	es: {
			  		add_paciente: "Agregar etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_dom: "Domingo",
			  	},
			  	en: {
			  		pag_inicial_dom: "Sunday",
			  	},
			  	es: {
			  		pag_inicial_dom: "Domingo",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_sex: "Sexta-feira",
			  	},
			  	en: {
			  		pag_inicial_sex: "Friday",
			  	},
			  	es: {
			  		pag_inicial_sex: "Viernes",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_sab: "Sábado",
			  	},
			  	en: {
			  		pag_inicial_sab: "Saturday",
			  	},
			  	es: {
			  		pag_inicial_sab: "Sábado",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_qui: "Quinta-feira",
			  	},
			  	en: {
			  		pag_inicial_qui: "Thursday",
			  	},
			  	es: {
			  		pag_inicial_qui: "Jueves",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_qua: "Quarta-feira",
			  	},
			  	en: {
			  		pag_inicial_qua: "Wednesday",
			  	},
			  	es: {
			  		pag_inicial_qua: "Miércoles",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_ter: "Terça-feira",
			  	},
			  	en: {
			  		pag_inicial_ter: "Tuesday",
			  	},
			  	es: {
			  		pag_inicial_ter: "Martes",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_seg: "Segunda-feira",
			  	},
			  	en: {
			  		pag_inicial_seg: "Monday",
			  	},
			  	es: {
			  		pag_inicial_seg: "Lunes",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_datas: "Ontem",
			  	},
			  	en: {
			  		pag_inicial_datas: "Yesterday",
			  	},
			  	es: {
			  		pag_inicial_datas: "Ayer",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_busca_pac_tags: "Pesquisar pacientes ou tags",
			  	},
			  	en: {
			  		pag_inicial_busca_pac_tags: "Search for patients or tags",
			  	},
			  	es: {
			  		pag_inicial_busca_pac_tags: "Buscar pacientes o etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_excluidos_dropdown: "Treinos",
			  	},
			  	en: {
			  		menu_lateral_excluidos_dropdown: "Training",
			  	},
			  	es: {
			  		menu_lateral_excluidos_dropdown: " Capacitación",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_redefinir_senha: "Inserir o email do cadastro para redefinir a senha",
			  	},
			  	en: {
			  		login_redefinir_senha: "Enter the registration email to reset the password",
			  	},
			  	es: {
			  		login_redefinir_senha: "Ingrese el correo electrónico de registro para restablecer la contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_ajuda: "Ajuda",
			  	},
			  	en: {
			  		login_ajuda: "Help",
			  	},
			  	es: {
			  		login_ajuda: "Ayuda",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_idioma: "Idioma",
			  	},
			  	en: {
			  		login_idioma: "Language",
			  	},
			  	es: {
			  		login_idioma: "Idioma",
			  	}
			  },
		
			  {
			  	pt: {
			  		ajuda_site: "Site",
			  	},
			  	en: {
			  		ajuda_site: "Site",
			  	},
			  	es: {
			  		ajuda_site: "Sitio",
			  	}
			  },
		
			  {
			  	pt: {
			  		ajuda_iniciar_chat: "Iniciar chat",
			  	},
			  	en: {
			  		ajuda_iniciar_chat: "Start chat",
			  	},
			  	es: {
			  		ajuda_iniciar_chat: "Comenzar chat",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_esqueci_senha: "Esqueceu sua senha?",
			  	},
			  	en: {
			  		login_esqueci_senha: "Forgot your password?",
			  	},
			  	es: {
			  		login_esqueci_senha: " ¿Olvidaste tu contraseña?",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_entrar_botao: "Entrar",
			  	},
			  	en: {
			  		login_entrar_botao: "Enter",
			  	},
			  	es: {
			  		login_entrar_botao: "Entra",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_popup_senha_erro_invalida: "Senha inválida",
			  	},
			  	en: {
			  		login_popup_senha_erro_invalida: "Invalid password",
			  	},
			  	es: {
			  		login_popup_senha_erro_invalida: "Contraseña invalida",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_input_senha: "Campo obrigatório",
			  	},
			  	en: {
			  		login_input_senha: "Required field",
			  	},
			  	es: {
			  		login_input_senha: "Campo obligatorio",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_senha: "Senha",
			  	},
			  	en: {
			  		login_senha: "Password",
			  	},
			  	es: {
			  		login_senha: "Contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_input_email: "E-mail inválido ou não preenchido",
			  	},
			  	en: {
			  		login_input_email: "Invalid or unfilled email",
			  	},
			  	es: {
			  		login_input_email: "Correo electrónico no válido o sin completar",
			  	}
			  },
		
			  {
			  	pt: {
			  		idioma_linguagem: "Linguagem do aplicativo",
			  	},
			  	en: {
			  		idioma_linguagem: "App language",
			  	},
			  	es: {
			  		idioma_linguagem: "Idioma del app",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_menu_inferior_protocolo: "Protocolo",
			  	},
			  	en: {
			  		pag_inicial_menu_inferior_protocolo: "Protocol",
			  	},
			  	es: {
			  		pag_inicial_menu_inferior_protocolo: "Protocolo",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_menu_inferior_home: "Início ",
			  	},
			  	en: {
			  		pag_inicial_menu_inferior_home: "Home",
			  	},
			  	es: {
			  		pag_inicial_menu_inferior_home: "Comienzo",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_avaliador: "Avaliador",
			  	},
			  	en: {
			  		pag_inicial_avaliador: "Evaluator",
			  	},
			  	es: {
			  		pag_inicial_avaliador: "Evaluador",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_config_permissoes: "Permissões",
			  	},
			  	en: {
			  		menu_lateral_config_permissoes: "Permissions",
			  	},
			  	es: {
			  		menu_lateral_config_permissoes: "Permisos",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_geral: "Gerais",
			  	},
			  	en: {
			  		menu_lateral_geral: "General",
			  	},
			  	es: {
			  		menu_lateral_geral: "General",
			  	}
			  },
		
			  {
			  	pt: {
			  		pag_inicial_menu_inferior_mais: "Mais",
			  	},
			  	en: {
			  		pag_inicial_menu_inferior_mais: "More",
			  	},
			  	es: {
			  		pag_inicial_menu_inferior_mais: "Más",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_email: "E-mail",
			  	},
			  	en: {
			  		login_email: "E-mail",
			  	},
			  	es: {
			  		login_email: "E-mail",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_faturas_pendentes_exibir: "Exibir",
			  	},
			  	en: {
			  		menu_lateral_faturas_pendentes_exibir: "Show",
			  	},
			  	es: {
			  		menu_lateral_faturas_pendentes_exibir: "Mostrar",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_fatura_pendente: "Fatura pendente",
			  	},
			  	en: {
			  		menu_lateral_fatura_pendente: " Pending invoice",
			  	},
			  	es: {
			  		menu_lateral_fatura_pendente: "Factura pendiente",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_ativo: "Ativo",
			  	},
			  	en: {
			  		menu_lateral_ativo: "Active",
			  	},
			  	es: {
			  		menu_lateral_ativo: "Activo",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_relatorio_assimetria: "Relatório de assimetria ",
			  	},
			  	en: {
			  		menu_lateral_relatorio_assimetria: "Asymmetry report",
			  	},
			  	es: {
			  		menu_lateral_relatorio_assimetria: "Informe de asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_nome_cartao: "Nome impresso no cartão",
			  	},
			  	en: {
			  		menu_lateral_nome_cartao: "Name printed on the card",
			  	},
			  	es: {
			  		menu_lateral_nome_cartao: "Nombre impreso en la tarjeta",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_numero_cartao: "Número do cartão",
			  	},
			  	en: {
			  		menu_lateral_numero_cartao: "Card number",
			  	},
			  	es: {
			  		menu_lateral_numero_cartao: "Numero de tarjeta",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_pagament: "Pagamento",
			  	},
			  	en: {
			  		menu_lateral_pagament: "Payment",
			  	},
			  	es: {
			  		menu_lateral_pagament: " Pago",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_relatorio_avaliacao: "Relatório de avaliação",
			  	},
			  	en: {
			  		menu_lateral_relatorio_avaliacao: "Evaluation report",
			  	},
			  	es: {
			  		menu_lateral_relatorio_avaliacao: "Reporte de evaluacion",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_idoso: "Idoso",
			  	},
			  	en: {
			  		menu_lateral_idoso: "Elderly",
			  	},
			  	es: {
			  		menu_lateral_idoso: "Anciano",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_forca_dinamo: "Força Dinamô",
			  	},
			  	en: {
			  		menu_lateral_forca_dinamo: "Dynamo Force",
			  	},
			  	es: {
			  		menu_lateral_forca_dinamo: "Dinamo de Fuerza",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_modo_web: "Modo web",
			  	},
			  	en: {
			  		menu_lateral_modo_web: "Web mode",
			  	},
			  	es: {
			  		menu_lateral_modo_web: "Modo web",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_percentual_rm: "Percentual de RM",
			  	},
			  	en: {
			  		menu_lateral_percentual_rm: "Percentage of MR",
			  	},
			  	es: {
			  		menu_lateral_percentual_rm: "Porcentaje de RM",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_pos_operatorio: "Pós-operatório",
			  	},
			  	en: {
			  		menu_lateral_pos_operatorio: "Postoperative",
			  	},
			  	es: {
			  		menu_lateral_pos_operatorio: "Postoperatorio",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_personalizar_relatorio: "Personalizar relatório",
			  	},
			  	en: {
			  		menu_lateral_personalizar_relatorio: "Customize report",
			  	},
			  	es: {
			  		menu_lateral_personalizar_relatorio: "Personalizar informe",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_meu_plano_até: "Até",
			  	},
			  	en: {
			  		menu_lateral_meu_plano_até: "Until",
			  	},
			  	es: {
			  		menu_lateral_meu_plano_até: "Hasta",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_elastic_trial: "Kinology trial",
			  	},
			  	en: {
			  		menu_lateral_elastic_trial: "Kinology trial",
			  	},
			  	es: {
			  		menu_lateral_elastic_trial: "Kinology trial",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_meu_plano: "Meu plano",
			  	},
			  	en: {
			  		menu_lateral_meu_plano: "My plan",
			  	},
			  	es: {
			  		menu_lateral_meu_plano: "Mi plan",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_upgrade: "Upgrade",
			  	},
			  	en: {
			  		menu_lateral_upgrade: "Upgrade",
			  	},
			  	es: {
			  		menu_lateral_upgrade: "Upgrade",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_sedentario: "Sedentário",
			  	},
			  	en: {
			  		menu_lateral_sedentario: "Sedentary",
			  	},
			  	es: {
			  		menu_lateral_sedentario: "Sedentario",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_senha_atual: "Senha atual",
			  	},
			  	en: {
			  		menu_lateral_senha_atual: "Current password",
			  	},
			  	es: {
			  		menu_lateral_senha_atual: "Contraseña actual",
			  	}
			  },
		
			  {
			  	pt: {
			  		pacientes_arrasta_para_o_lado_editar_tags: "Editar tags",
			  	},
			  	en: {
			  		pacientes_arrasta_para_o_lado_editar_tags: "Edit tags",
			  	},
			  	es: {
			  		pacientes_arrasta_para_o_lado_editar_tags: "Editar etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		pacientes_arrasta_para_o_lado_desativar: "Desativar",
			  	},
			  	en: {
			  		pacientes_arrasta_para_o_lado_desativar: "Disable",
			  	},
			  	es: {
			  		pacientes_arrasta_para_o_lado_desativar: "Desactivar",
			  	}
			  },
		
			  {
			  	pt: {
			  		pacientes_arrasta_para_o_lado_editar: "Editar perfil",
			  	},
			  	en: {
			  		pacientes_arrasta_para_o_lado_editar: "Edit profile",
			  	},
			  	es: {
			  		pacientes_arrasta_para_o_lado_editar: "Editar perfil",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_inferior_inicio: "Ínicio",
			  	},
			  	en: {
			  		menu_inferior_inicio: "Home",
			  	},
			  	es: {
			  		menu_inferior_inicio: "Comienzo",
			  	}
			  },
		
			  {
			  	pt: {
			  		tela_inicial_busca: "Pesquisar",
			  	},
			  	en: {
			  		tela_inicial_busca: "Search",
			  	},
			  	es: {
			  		tela_inicial_busca: "Buscar",
			  	}
			  },
		
			  {
			  	pt: {
			  		tags_digitar: "Criar nova tag",
			  	},
			  	en: {
			  		tags_digitar: "Create new tag",
			  	},
			  	es: {
			  		tags_digitar: "Crear nueva etiqueta",
			  	}
			  },
		
			  {
			  	pt: {
			  		tags_individual_botao_cancelar: "Cancelar",
			  	},
			  	en: {
			  		tags_individual_botao_cancelar: "Cancel",
			  	},
			  	es: {
			  		tags_individual_botao_cancelar: "Cancelar",
			  	}
			  },
			  {
				pt: {
					responda_uma_pergunta: "Responda apenas uma pergunta e concorra a um mês sem mensalidade!",
				},
				en: {
					responda_uma_pergunta: "Answer just one question and win a month without a monthly fee!",
				},
				es: {
					responda_uma_pergunta: "Responde solo una pregunta y gana un mes sin cuota mensual!",
				}
			  },
		
			  {
			  	pt: {
			  		tags_individual_botao_excluir: "Excluir",
			  	},
			  	en: {
			  		tags_individual_botao_excluir: "Delete",
			  	},
			  	es: {
			  		tags_individual_botao_excluir: "Eliminar",
			  	}
			  },
		
			  {
			  	pt: {
			  		tags_individual_confirmacao: "Desativar o paciente Wellington?",
			  	},
			  	en: {
			  		tags_individual_confirmacao: "Deactivate patient Wellington?",
			  	},
			  	es: {
			  		tags_individual_confirmacao: " ¿Desactivar al paciente Wellington?",
			  	}
			  },
		
			  {
			  	pt: {
			  		tags_individual_botao: "Excluir tags",
			  	},
			  	en: {
			  		tags_individual_botao: "Delete tags",
			  	},
			  	es: {
			  		tags_individual_botao: "Eliminar etiquetas",
			  	}
			  },
		
			  {
			  	pt: {
			  		tags_individual_busca: "Busque ou digite uma nova",
			  	},
			  	en: {
			  		tags_individual_busca: "Search or type a new one",
			  	},
			  	es: {
			  		tags_individual_busca: "Busca o escribe uno nuevo",
			  	}
			  },
		
			  {
			  	pt: {
			  		tags_individual_texto: "Tags do paciente",
			  	},
			  	en: {
			  		tags_individual_texto: "Patient tags",
			  	},
			  	es: {
			  		tags_individual_texto: "Etiquetas de pacientes",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_duvidas_politicas: "Políticas de privacidade",
			  	},
			  	en: {
			  		menu_lateral_duvidas_politicas: "Privacy policy",
			  	},
			  	es: {
			  		menu_lateral_duvidas_politicas: "Política de privacidad",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_duvidas_manual: "Manual do dinamômetro",
			  	},
			  	en: {
			  		menu_lateral_duvidas_manual: "Dynamometer manual",
			  	},
			  	es: {
			  		menu_lateral_duvidas_manual: "Manual de dinamómetro",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_duvidas_tutoriais: "Tutoriais",
			  	},
			  	en: {
			  		menu_lateral_duvidas_tutoriais: "Tutorials",
			  	},
			  	es: {
			  		menu_lateral_duvidas_tutoriais: "Tutoriales",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_duvidas_peguntas: "Perguntas frequentes",
			  	},
			  	en: {
			  		menu_lateral_duvidas_peguntas: "Common questions",
			  	},
			  	es: {
			  		menu_lateral_duvidas_peguntas: "Preguntas frecuentes",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_fale_conos_sugestoes: "Sugestões",
			  	},
			  	en: {
			  		menu_lateral_fale_conos_sugestoes: "Suggestions",
			  	},
			  	es: {
			  		menu_lateral_fale_conos_sugestoes: "Sugerencias",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_fale_conos_troca: "Troca de plano",
			  	},
			  	en: {
			  		menu_lateral_fale_conos_troca: " Plan exchange",
			  	},
			  	es: {
			  		menu_lateral_fale_conos_troca: "Intercambio de planes",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_fale_conosc_cancelamento: "Cancelamento de assinatura",
			  	},
			  	en: {
			  		menu_lateral_fale_conosc_cancelamento: "Subscription cancellation",
			  	},
			  	es: {
			  		menu_lateral_fale_conosc_cancelamento: "Cancelación de suscripción",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_fale_conos_cobranca: "Cobrança indevida",
			  	},
			  	en: {
			  		menu_lateral_fale_conos_cobranca: "Improper billing",
			  	},
			  	es: {
			  		menu_lateral_fale_conos_cobranca: "Facturación incorrecta",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_fale_conos_problemas_pagamento: "Problemas no pagamento",
			  	},
			  	en: {
			  		menu_lateral_fale_conos_problemas_pagamento: "Payment issues",
			  	},
			  	es: {
			  		menu_lateral_fale_conos_problemas_pagamento: "Problemas de pago",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_fale_conosco: "Fale conosco",
			  	},
			  	en: {
			  		menu_lateral_fale_conosco: "Contact us",
			  	},
			  	es: {
			  		menu_lateral_fale_conosco: "Hable con nosotros",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_excluidos_titulo: "Recuperar pacientes e avaliadores",
			  	},
			  	en: {
			  		menu_lateral_excluidos_titulo: "Recovering patients and evaluators",
			  	},
			  	es: {
			  		menu_lateral_excluidos_titulo: "Recuperación de pacientes y evaluadores",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_mensg_botao2: "Galeria",
			  	},
			  	en: {
			  		menu_lateral_mensg_botao2: "Gallery",
			  	},
			  	es: {
			  		menu_lateral_mensg_botao2: "Galería",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_mensg_botao1: "Câmera ",
			  	},
			  	en: {
			  		menu_lateral_mensg_botao1: "Camera",
			  	},
			  	es: {
			  		menu_lateral_mensg_botao1: "Cámara",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_person_mensg: "Selecione uma imagem",
			  	},
			  	en: {
			  		menu_lateral_person_mensg: "Select an image",
			  	},
			  	es: {
			  		menu_lateral_person_mensg: "Seleccionar una imagen",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_person_cidade: "Cidade",
			  	},
			  	en: {
			  		menu_lateral_person_cidade: "City",
			  	},
			  	es: {
			  		menu_lateral_person_cidade: " Ciudad",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_person_bairro: "Bairro",
			  	},
			  	en: {
			  		menu_lateral_person_bairro: "Neighborhood",
			  	},
			  	es: {
			  		menu_lateral_person_bairro: "Barrio",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_person__cep: "CEP",
			  	},
			  	en: {
			  		menu_lateral_person__cep: "Post code",
			  	},
			  	es: {
			  		menu_lateral_person__cep: "Código postal",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_person_empresa: "Nome da empresa",
			  	},
			  	en: {
			  		menu_lateral_person_empresa: "Company Name",
			  	},
			  	es: {
			  		menu_lateral_person_empresa: "Nombre de la empresa",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_person_numero: "Número de telefone",
			  	},
			  	en: {
			  		menu_lateral_person_numero: "Phone number",
			  	},
			  	es: {
			  		menu_lateral_person_numero: "Número de teléfono",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_person_logo: "Adicione sua logomarca",
			  	},
			  	en: {
			  		menu_lateral_person_logo: "Add your logo",
			  	},
			  	es: {
			  		menu_lateral_person_logo: "Agrega tu logo",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_personalizar: "Personalizar relatório",
			  	},
			  	en: {
			  		menu_lateral_personalizar: "Customize report",
			  	},
			  	es: {
			  		menu_lateral_personalizar: "Personalizar informe",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_intervalo_mensg: "Ative o relógio com o intervalo de descanso entre exercícios",
			  	},
			  	en: {
			  		menu_lateral_exercicios_intervalo_mensg: "Activate the watch with the rest interval between exercises",
			  	},
			  	es: {
			  		menu_lateral_exercicios_intervalo_mensg: "Activar el reloj con el intervalo de descanso entre ejercicios",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_disposi_mensg: "Dinamômetros associados a esta conta",
			  	},
			  	en: {
			  		menu_lateral_exercicios_disposi_mensg: " Dynamometers associated with this account",
			  	},
			  	es: {
			  		menu_lateral_exercicios_disposi_mensg: "Dinamómetros asociados a esta cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_dispositivos: "Dispositivos encontrados",
			  	},
			  	en: {
			  		menu_lateral_exercicios_dispositivos: "Found devices",
			  	},
			  	es: {
			  		menu_lateral_exercicios_dispositivos: "Dispositivos encontrados",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicio_contagem: "Contagem regressiva",
			  	},
			  	en: {
			  		menu_lateral_exercicio_contagem: "Countdown",
			  	},
			  	es: {
			  		menu_lateral_exercicio_contagem: "Cuenta regresiva",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_alerta: "Alerta vibratório",
			  	},
			  	en: {
			  		menu_lateral_alerta: "Vibrating alert",
			  	},
			  	es: {
			  		menu_lateral_alerta: "Alerta con vibración",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_alerta: "Alerta sonoro",
			  	},
			  	en: {
			  		menu_lateral_exercicios_alerta: "Audible alert",
			  	},
			  	es: {
			  		menu_lateral_exercicios_alerta: "Alerta audible",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_tempo_mensg: "Apresenta um contador regressivo e emite um alerta sonoro ao finalizar o tempo total da duração da contração",
			  	},
			  	en: {
			  		menu_lateral_exercicios_tempo_mensg: "Features a countdown timer and beeps when the total duration of the contraction has ended",
			  	},
			  	es: {
			  		menu_lateral_exercicios_tempo_mensg: " Cuenta con un temporizador de cuenta regresiva y emite un pitido cuando la duración total de la contracción ha terminado",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exercicios_tempo: "Tempo de contração",
			  	},
			  	en: {
			  		menu_lateral_exercicios_tempo: "Contraction time",
			  	},
			  	es: {
			  		menu_lateral_exercicios_tempo: "Tiempo de contracción",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_permissoes_contatos: "Permitir buscar contatos",
			  	},
			  	en: {
			  		menu_lateral_permissoes_contatos: "Allow to search for contacts",
			  	},
			  	es: {
			  		menu_lateral_permissoes_contatos: "Permitir buscar contactos",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_permissoes_loc: "Ativar localização",
			  	},
			  	en: {
			  		menu_lateral_permissoes_loc: "Enable location",
			  	},
			  	es: {
			  		menu_lateral_permissoes_loc: "Habilitar ubicación",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_permissoes_bluetooth_mensg: "Ative o bluetooth para conectar ao dinamômetro",
			  	},
			  	en: {
			  		menu_lateral_permissoes_bluetooth_mensg: " Activate bluetooth to connect to the dynamometer",
			  	},
			  	es: {
			  		menu_lateral_permissoes_bluetooth_mensg: "Activar bluetooth para conectarse al dinamómetro",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_permissoes_bluetooth: "Ativar bluetooth",
			  	},
			  	en: {
			  		menu_lateral_permissoes_bluetooth: "Enable bluetooth",
			  	},
			  	es: {
			  		menu_lateral_permissoes_bluetooth: "Habilitar bluetooth",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_demonstracao: "Demonstração",
			  	},
			  	en: {
			  		menu_lateral_demonstracao: "Demonstration",
			  	},
			  	es: {
			  		menu_lateral_demonstracao: " Demostración",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_reiniciar_mensagem: "Reinicie e sincronize todos os dados do usuário",
			  	},
			  	en: {
			  		menu_lateral_reiniciar_mensagem: "Restart and synchronize all user data",
			  	},
			  	es: {
			  		menu_lateral_reiniciar_mensagem: "Reiniciar y sincronizar todos los datos del usuario",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_reiniciar: "Reiniciar aplicativo",
			  	},
			  	en: {
			  		menu_lateral_reiniciar: "Restart app",
			  	},
			  	es: {
			  		menu_lateral_reiniciar: "Reiniciar aplicación",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_unida_texto: "Defina a unidade de medida que será utilizada nos relatórios de avaliação e assimetria",
			  	},
			  	en: {
			  		menu_lateral_unida_texto: " Define the unit of measurement that will be used in the assessment and asymmetry reports",
			  	},
			  	es: {
			  		menu_lateral_unida_texto: "Definir la unidad de medida que se utilizará en los informes de evaluación y asimetría",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_unidade: "Unidade de medida",
			  	},
			  	en: {
			  		menu_lateral_unidade: "Unidad de medida",
			  	},
			  	es: {
			  		menu_lateral_unidade: "Unit of measurement",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_gerais_linguagem: "Defina a linguagem do aplicativo",
			  	},
			  	en: {
			  		menu_lateral_gerais_linguagem: "Define the application language",
			  	},
			  	es: {
			  		menu_lateral_gerais_linguagem: "Establecer el idioma de la aplicación",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_config_person_relatorio: "Personalizar relatório",
			  	},
			  	en: {
			  		menu_lateral_config_person_relatorio: "Customize report",
			  	},
			  	es: {
			  		menu_lateral_config_person_relatorio: " Personalizar informe",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_mensagem_cvv: "Código de segurança de 3 dígitos, impresso no verso do cartão de crédito",
			  	},
			  	en: {
			  		menu_lateral_mensagem_cvv: "3-digit security code, printed on the back of the credit card",
			  	},
			  	es: {
			  		menu_lateral_mensagem_cvv: "Código de seguridad de 3 dígitos, impreso en el reverso de la tarjeta de crédito",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_pagamento_ano: "Ano de expiração",
			  	},
			  	en: {
			  		menu_lateral_pagamento_ano: "Expiration year",
			  	},
			  	es: {
			  		menu_lateral_pagamento_ano: "Año de vencimiento",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_pagamento_mes: "Mês de expiração",
			  	},
			  	en: {
			  		menu_lateral_pagamento_mes: "Expiration month",
			  	},
			  	es: {
			  		menu_lateral_pagamento_mes: "Mes de expiración",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_pagamento_cvv: "CVV",
			  	},
			  	en: {
			  		menu_lateral_pagamento_cvv: "CVV",
			  	},
			  	es: {
			  		menu_lateral_pagamento_cvv: "CVV",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_cvv: "CVV",
			  	},
			  	en: {
			  		menu_lateral_cvv: "CVV",
			  	},
			  	es: {
			  		menu_lateral_cvv: "CVV",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_logo: "Logomarca nos relatórios",
			  	},
			  	en: {
			  		menu_lateral_logo: "Logo in reports",
			  	},
			  	es: {
			  		menu_lateral_logo: "Logotipo en informes",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_confirmacao: "Confirme sua senha",
			  	},
			  	en: {
			  		menu_lateral_confirmacao: "Confirm your password",
			  	},
			  	es: {
			  		menu_lateral_confirmacao: "Confirmar la contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_sexo: "Sexo",
			  	},
			  	en: {
			  		menu_lateral_sexo: "Gender",
			  	},
			  	es: {
			  		menu_lateral_sexo: "género",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_data_nascimento: "Data de nascimento",
			  	},
			  	en: {
			  		menu_lateral_data_nascimento: "Date of birth",
			  	},
			  	es: {
			  		menu_lateral_data_nascimento: "Fecha de nacimiento",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_condicionamento: "Nível condicionamento",
			  	},
			  	en: {
			  		menu_lateral_condicionamento: "Conditioning level",
			  	},
			  	es: {
			  		menu_lateral_condicionamento: "Nivel de acondicionamiento",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_altura: "Altura",
			  	},
			  	en: {
			  		menu_lateral_altura: "Height",
			  	},
			  	es: {
			  		menu_lateral_altura: "Altura",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_massa: "Massa",
			  	},
			  	en: {
			  		menu_lateral_massa: "Mass",
			  	},
			  	es: {
			  		menu_lateral_massa: "Masa",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exportar_mensagem: "Gerar um arquivo CSV com todas as informações de avaliações realizadas nesta conta",
			  	},
			  	en: {
			  		menu_lateral_exportar_mensagem: "Generate a CSV file with all the information of evaluations carried out on this account",
			  	},
			  	es: {
			  		menu_lateral_exportar_mensagem: "Generar un archivo CSV con toda la información de las evaluaciones realizadas en esta cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_exportar: "Exportar dados",
			  	},
			  	en: {
			  		menu_lateral_exportar: "Export data",
			  	},
			  	es: {
			  		menu_lateral_exportar: "Exportar datos",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_sair_conta: "Sair da conta",
			  	},
			  	en: {
			  		menu_lateral_sair_conta: "Sign out",
			  	},
			  	es: {
			  		menu_lateral_sair_conta: "Desconectar",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_faturas: "Faturas",
			  	},
			  	en: {
			  		menu_lateral_faturas: "Invoices",
			  	},
			  	es: {
			  		menu_lateral_faturas: "Facturas",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_pagamento: "Pagamento",
			  	},
			  	en: {
			  		menu_lateral_pagamento: "Payment ",
			  	},
			  	es: {
			  		menu_lateral_pagamento: "Pago",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_alterar_senha: "Alterar minha senha",
			  	},
			  	en: {
			  		menu_lateral_alterar_senha: "Change my password",
			  	},
			  	es: {
			  		menu_lateral_alterar_senha: "Cambiar mi contraseña",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_perfil: "Perfil",
			  	},
			  	en: {
			  		menu_lateral_perfil: "Profile",
			  	},
			  	es: {
			  		menu_lateral_perfil: "Perfil",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_copiado: "Copiado",
			  	},
			  	en: {
			  		menu_lateral_copiado: "Copied",
			  	},
			  	es: {
			  		menu_lateral_copiado: "Copiado",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_saiba_mais: "Saiba Mais",
			  	},
			  	en: {
			  		menu_lateral_saiba_mais: "More",
			  	},
			  	es: {
			  		menu_lateral_saiba_mais: "Sepa mas",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_explicação: "Use seu link exclusivo abaixo para indicar",
			  	},
			  	en: {
			  		menu_lateral_explicação: "Use your unique link below to indicate",
			  	},
			  	es: {
			  		menu_lateral_explicação: "Utilice su enlace único a continuación para indicar",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_indicação: "Indique amigos e ganhe descontos!",
			  	},
			  	en: {
			  		menu_lateral_indicação: "Indicate friends and get discounts!",
			  	},
			  	es: {
			  		menu_lateral_indicação: " ¡Recomiende amigos y obtenga descuentos!",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_config: "Configurações",
			  	},
			  	en: {
			  		menu_lateral_config: "Settings",
			  	},
			  	es: {
			  		menu_lateral_config: "Ajustes",
			  	}
			  },
		
			  {
			  	pt: {
			  		menu_lateral_minha_conta: "Minha conta",
			  	},
			  	en: {
			  		menu_lateral_minha_conta: "My account",
			  	},
			  	es: {
			  		menu_lateral_minha_conta: "Mi cuenta",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_1acesso: "1° acesso",
			  	},
			  	en: {
			  		login_1acesso: "1st access",
			  	},
			  	es: {
			  		login_1acesso: "1er acceso",
			  	}
			  },
		
			  {
			  	pt: {
			  		login_popup_erro_acesso: "Erro ao cadastrar. Por favor, verifique os campos obrigatórios (*)",
			  	},
			  	en: {
			  		login_popup_erro_acesso: "Error registering. Please check the required fields (*)",
			  	},
			  	es: {
			  		login_popup_erro_acesso: "Error al registrarse. Por favor, marca los campos requeridos (*)",
			  	}
			  },

			  {
				pt: {
					protocolo_elastic: "Protocolo Kinology",
				},
				en: {
					protocolo_elastic: "Kinology protocol",
				},
				es: {
					protocolo_elastic: "Protocolo Kinology",
				}
			},

			{
				pt: {
					protocolo_personalizado: "Protocolo personalizado",
				},
				en: {
					protocolo_personalizado: "Custom protocol",
				},
				es: {
					protocolo_personalizado: "Protocolo personalizado",
				}
			}, 

			{
				pt: {
					problema_pagamento: "Identificamos um problema com o pagamento da assinatura associada a este dinamômetro",
				},
				en: {
					problema_pagamento: "We have identified an issue with the payment of the subscription associated with this dynamometer",
				},
				es: {
					problema_pagamento: "Hemos identificado un problema con el pago de la suscripción asociada a este dinamómetro",
				}
			},

			{
				pt: {
					desbloqueio_regularizacao: "O desbloqueio será realizado após a regularização da pendência",
				},
				en: {
					desbloqueio_regularizacao: "We have identified an issue with the payment of the subscription associated with this dynamometer",
				},
				es: {
					desbloqueio_regularizacao: "Hemos identificado un problema con el pago de la suscripción asociada a este dinamómetro",
				}
			},
			 {
			  	pt: {
			  		login_request_error_new_password: "Para aprimorar a segurança da sua conta, recomendamos a renovação da senha no nosso novo padrão. Agradecemos pela sua colaboração!",
			  	},
			  	en: {
			  		login_request_error_new_password: "To improve the security of your account, we recommend renewing your password to our new standard. Thank you for your collaboration!",
			  	},
			  	es: {
			  		login_request_error_new_password: "Para mejorar la seguridad de su cuenta, le recomendamos renovar su contraseña según nuestro nuevo estándar. ¡Gracias por su colaboración!",
			  	}
			  },
			 {
			  	pt: {
			  		password_requiriments: "Senha: letras (maiúsc./minúsc.), números e símbolos obrigatórios",
			  	},
			  	en: {
			  		password_requiriments: "Password: letters (upper/lower case), numbers and mandatory symbols",
			  	},
			  	es: {
			  		password_requiriments: "Contraseña: letras (mayúsculas/minúsculas), números y símbolos obligatorios",
			  	}
			  },
			  {
				pt: {
					min_6_characters: "Mínimo 6 dígitos",
				},
				en: {
					min_6_characters: "Minimum 6 digits",
				},
				es: {
					min_6_characters: "Mínimo 6 dígitos",
				}
			},
			  {
				pt: {
					min_4_characters: "Mínimo 4 dígitos",
				},
				en: {
					min_4_characters: "Minimum 4 digits",
				},
				es: {
					min_4_characters: "Mínimo 4 dígitos",
				}
			},
			{
				pt: {
					aviso_min_8: "No mínimo 8 dígitos",
				},
				en: {
					aviso_min_8: "At least 8 digits",
				},
				es: {
					aviso_min_8: "Al menos 8 dígitos",
				}
			},
			{
				pt: {
					deleted_account: "Não identificamos seu cadastro. Para assistência, entre em contato conosco.",
				},
				en: {
					deleted_account: "We do not identify your registration. For assistance, please contact us.",
				},
				es: {
					deleted_account: "No identificamos su registro. Para obtener ayuda, comuníquese con nosotros.",
				}
			},
			{
				pt: {
					tutorial_video: "Tutorial em Vídeo",
				},
				en: {
					tutorial_video: "Video Tutorial",
				},
				es: {
					tutorial_video: "Vídeotutorial",
				}
			},
			{
				pt: {
					tutorial_imagens: "Tutorial em Imagens",
				},
				en: {
					tutorial_imagens: "Tutorial in Images",
				},
				es: {
					tutorial_imagens: "Tutorial en Imágenes",
				}
			},
			{
				pt: {
					sem_historico_assimetria_grupamento: "Você não possui histórico de assimetria para esse grupamento muscular",
				},
				en: {
					sem_historico_assimetria_grupamento: "You have no history of asymmetry for this muscle group",
				},
				es: {
					sem_historico_assimetria_grupamento: "No tienes antecedentes de asimetría para este grupo de músculos.",
				}
		    },
			 {
				pt: {
					limite_cadastros_mensal: "Você chegou ao limite máximo de cadastros mensal. Para continuar aproveitando todas as funcionalidades, atualize o seu plano.",
				},
				en: {
					limite_cadastros_mensal: "You have reached the maximum monthly registration limit. To continue enjoying all the features, update your plan.",
				},
				es: {
					limite_cadastros_mensal: "Has alcanzado el límite máximo de registro mensual. Para seguir disfrutando de todas las funciones, actualiza tu plan.",
				}
			 },
			 {
				pt: {
					limite_pacientes_avaliados_atingido: "Limite de pacientes avaliados atingido",
				},
				en: {
					limite_pacientes_avaliados_atingido: "Limit of evaluated patients reached",
				},
				es: {
					limite_pacientes_avaliados_atingido: "Límite de pacientes evaluados alcanzado",
				}
			 },
			 {
				pt: {
					limite_pacientes_avaliados_mes: "Você chegou ao limite máximo de pacientes avaliados no mês. Para continuar aproveitando todas as funcionalidades, atualize o seu plano",
				},
				en: {
					limite_pacientes_avaliados_mes: "You have reached the maximum number of patients evaluated in the month. To continue enjoying all the features, update your plan.",
				},
				es: {
					limite_pacientes_avaliados_mes: "Has alcanzado el número máximo de pacientes evaluados en el mes. Para seguir disfrutando de todas las funciones, actualiza tu plan.",
				}
			 },
			 {
				pt: {
					cadastros_mensais: "Cadastros mensais",
				},
				en: {
					cadastros_mensais: "Monthly registrations",
				},
				es: {
					cadastros_mensais: "Inscripciones mensuales",
				}
			 },
			 {
				pt: {
					falar_com_especialista: "Falar com especialista",
				},
				en: {
					falar_com_especialista: "Speak to an expert",
				},
				es: {
					falar_com_especialista: "Habla con un experto",
				}
			 },
			 {
				pt: {
					cadastrar_cartao: "Cadastrar Cartão",
				},
				en: {
					cadastrar_cartao: "Register Card",
				},
				es: {
					cadastrar_cartao: "Tarjeta de registro",
				},
			 },
			 {
				pt: {
					deseja_remover: "Deseja remover",
				},
				en: {
					deseja_remover: "Do you want to remove",
				},
				es: {
					deseja_remover: "Quieres eliminar",
				}
			 },
			 {
				pt: {
					precisamos_de_voce: "PRECISAMOS DE VOCÊ",
				},
				en: {
					precisamos_de_voce: "WE NEED YOU",
				},
				es: {
					precisamos_de_voce: "TE NECESITAMOS",
				},
			  },
			  {
				pt: {
					se_desejar_voce_pode_recuperar: "Se desejar, você poderá recuperá-los a partir da lixeira em até 60 dias após removê-los.",
				},
				en: {
					se_desejar_voce_pode_recuperar: "If you wish, you can retrieve them from the trash within 60 days of removing them.",
				},
				es: {
					se_desejar_voce_pode_recuperar: "Si lo deseas, puedes recuperarlos de la basura dentro de los 60 días posteriores a su eliminación.",
				}
			  },
			 {
				pt: {
					desativacao_concluida_desfazer: "Desativação concluída - DESFAZER",
				},
				en: {
					desativacao_concluida_desfazer: "Deactivation complete - Undo",
				},
				es: {
					desativacao_concluida_desfazer: "Desactivación completa - Deshacer",
				}
			  },
			 {
				pt: {
					deseja_ativar: "Deseja recuperar",
				},
				en: {
					deseja_ativar: "Do you want to recover",
				},
				es: {
					deseja_ativar: "Quieres recuperarte?",
				}
			  },
			  {
				pt: {
					cadastre_seu_cartao: "Estamos mudando nosso sistema financeiro para melhor atendê-lo. Por isso solicitamos que atualize, antes do próximo vencimento, seu cartão de crédito no sistema para manter seus pagamentos em dias.",
				},
				en: {
					cadastre_seu_cartao: "We are changing our financial system to better serve you. Therefore, we ask that you update your credit card in the system before the next due date to keep your payments up to date.",
				},
				es: {
					cadastre_seu_cartao: "Estamos cambiando nuestro sistema financiero para servirle mejor. Por lo tanto, le solicitamos que actualice su tarjeta de crédito en el sistema antes de la próxima fecha de vencimiento para mantener sus pagos al día.",
				}
			  },
			  {
				pt: {
					cadastrar: "Cadastrar",
				},
				en: {
					cadastrar: "Register",
				},
				es: {
					cadastrar: "Registrar",
				}
			  },
			  {
				pt: {
					entendi: "Ok, entendi",
				},
				en: {
					entendi: "Ok, I got it",
				},
				es: {
					entendi: "OK entendí",
				}
			  },
			  {
					pt: {
						pacientes_avaliados_mes: "Pacientes avaliados no mês",
					},
					en: {
						pacientes_avaliados_mes: "Patients evaluated in the month",
					},
					es: {
						pacientes_avaliados_mes: "Pacientes evaluados en el mes",
					}
			  },{
					pt: {
						erro_gerar_relatorio: "Erro ao gerar o relatório:",
					},
					en: {
						erro_gerar_relatorio: "Error generating the report:",
					},
					es: {
						erro_gerar_relatorio: "Error al generar el informe:",
					}
			  },
				{
					pt: {
						dino_bateria: "Bateria do dinamômetro",
					},
					en: {
						dino_bateria: "Dynamometer Battery",
					},
					es: {
						dino_bateria: "Batería del dinamómetro",
					}
			  },
				{
					pt: {
						posicionamento_inicial: 'Posição inicial',
					},
					en: {
						posicionamento_inicial: 'Initial position',
					},
					es: {
						posicionamento_inicial: 'Posición inicial',
					},
				},
				{
					pt: {
						posicionamento_final: 'Posição final',
					},
					en: {
						posicionamento_final: 'Final position',
					},
					es: {
						posicionamento_final: 'Posición final',
					},
				},
				{
					pt: {
						nao_mostrar_novamente: 'Não visualizar novamente',
					},
					en: {
						nao_mostrar_novamente: 'Do not view again',
					},
					es: {
						nao_mostrar_novamente: 'No volver a ver',
					},
				},
				{
			  	pt: {
			  		modal_conecta_hand_grip_passos: "Para conectar ao dinamômetro palmar e realizar o exercício, por favor ative:",
			  	},
			  	en: {
			  		modal_conecta_hand_grip_passos: "To connect to the palm dynamometer and perform the exercise, please activate:",
			  	},
			  	es: {
			  		modal_conecta_hand_grip_passos: "Para conectarse al dinamómetro de palma y realizar el ejercicio, active:",
			  	}
			  },
				{
					pt: {
						luz_piscando_verde: 'Luz verde piscando: procurando',
					},
					en: {
						luz_piscando_verde: 'Blinking green light: searching',
					},
					es: {
						luz_piscando_verde: 'Luz verde intermitente: búsqueda',
					},
				},
				{
					pt: {
						luz_piscando_azul: 'Luz azul piscando: conectado',
					},
					en: {
						luz_piscando_azul: 'Blinking blue light: connected',
					},
					es: {
						luz_piscando_azul: 'Luz azul intermitente: conectada',
					},
				},
				{
			  	pt: {
			  		menu_lateral_permissoes_bluetooth_mensg_hg: "Ative o bluetooth para conectar ao dinamômetro palmar",
			  	},
			  	en: {
			  		menu_lateral_permissoes_bluetooth_mensg_hg: " Activate bluetooth to connect to the palm dynamometer",
			  	},
			  	es: {
			  		menu_lateral_permissoes_bluetooth_mensg_hg: "Activar bluetooth para conectarse al dinamómetro de palma",
			  	}
			  },
				{
					pt: {
						canhoto_destro: 'O paciente é destro ou canhoto?',
					},
					en: {
						canhoto_destro: 'Is the patient right-handed or left-handed?',
					},
					es: {
						canhoto_destro: '¿El paciente es diestro o zurdo?',
					},
				},
				{
					pt: {
						sem_informacao_dominante: 'Sem essa informação não é possível prosseguir com a avaliação de preensão manual.',
					},
					en: {
						sem_informacao_dominante: 'Without this information, it is not possible to proceed with the handgrip assessment.',
					},
					es: {
						sem_informacao_dominante: 'Sin esta información, no es posible proceder con la evaluación del agarre.',
					},
				},
				{
					pt: {
						canhoto: 'Canhoto',
					},
					en: {
						canhoto: 'Left-handed',
					},
					es: {
						canhoto: 'Zurdo',
					},
				},
				{
					pt: {
						destro: 'Destro',
					},
					en: {
						destro: 'Right-handed',
					},
					es: {
						destro: 'Diestro',
					},
				},
				{
					pt: {
						lado_dominante: 'Lado dominante',
					},
					en: {
						lado_dominante: 'Dominant side',
					},
					es: {
						lado_dominante: 'Lado dominante',
					},
				},
				{
					pt: {
						hand_grip_conectado: 'Dinamômetro palmar conectado',
					},
					en: {
						hand_grip_conectado: 'Palm dynamometer dynamo',
					},
					es: {
						hand_grip_conectado: 'Dinamómetro de palma conectado',
					},
				},
				{
					pt: {
						anamnese_aviso: "As Anamneses vão mudar!",
					},
					en: {
						anamnese_aviso: "The Anamnesis will change!",
					},
					es: {
						anamnese_aviso: "¡La Anamnesis cambiará!",
					}
				},
				{
					pt: {
						anamnese_aviso_explicacao_pt1: "Sugerimos que complete os questionários em progresso até o dia ",
					},
					en: {
						anamnese_aviso_explicacao_pt1: "We suggest that you complete the questionnaires in progress by ",
					},
					es: {
						anamnese_aviso_explicacao_pt1: "Le sugerimos que complete los cuestionarios en curso antes del ",
					}
				},
				{
					pt: {
						anamnese_aviso_explicacao_pt2: ", depois, não será possível continua-las.",
					},
					en: {
						anamnese_aviso_explicacao_pt2: ", after which you will not be able to continue them.",
					},
					es: {
						anamnese_aviso_explicacao_pt2: ", después del cual no podrá continuar con ellos.",
					}
				},
				{
					pt: {
						anamnese_aviso_explicacao_pt3: "Mas fique tranquilo, caso não conclua até lá, as informações serão salvas de onde você parou.",
					},
					en: {
						anamnese_aviso_explicacao_pt3: "But don't worry, if you don't complete it by then, the information will be saved where you left off.",
					},
					es: {
						anamnese_aviso_explicacao_pt3: "Pero no te preocupes, si no lo completas para entonces, la información se guardará donde la dejaste.",
					}
				},
]
		

		