import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { ExerGrupoHemiPros } from "../../../interfaces/exercicio_protocolo";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { TypesDivision, TypesLanguage } from "../../../utils/types";
import logoElastic from "../../../assets/kinologyIcons/svg/logo.svg";

interface RenderItemGroupProps {
	row: Exercicios2Props;
	index: any;
}

const RenderItemGroup: React.FC<RenderItemGroupProps> = ({ row }: any) => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const language = useSelector((state: any) => state.configs.language);

	const seletec_hemisferios = useSelector(
		(state: any) => state.manage_protocol.seletec_hemisferios
	);

	const exercices = useSelector(
		(state: any) => state.manage_protocol.exercices
	);

	const is_open_protocolo = useSelector(
		(state: any) => state.app.is_open_protocolo
	);

	const [exers, setExers] = useState<ExerGrupoHemiPros[]>([]);

	function isPresentInListSelecteds(exercise: ExerGrupoHemiPros) {
		const find = seletec_hemisferios?.find(
			(e: any) => e.ID_EXERCICIO == exercise?.exercice_id
		);
		const exist_type = find?.PROTOCOLO_EXERCICIO_TIPO.find(
			(e: any) => e == `${exercise.value}${exercise?.exercice_id}`
		);

		if (exist_type) {
			return true;
		}
		return false;
	}

	const arrayExercicios = exercices;
	const addTipo = (e: any) => {
		const exercicioAdicionado = arrayExercicios?.find(
			(i: any) => i == e?.exercice_id
		);
		if (exercicioAdicionado == undefined) {
			arrayExercicios.push(e?.exercice_id);		
			dispatch(ManageProtocolActions.set_exercices(arrayExercicios));
		}
		
		dispatch(ManageProtocolActions.set_selected_exercises(e));
	};

	useEffect(() => {
		dispatch(ManageProtocolActions.set_protocolo_selected(seletec_hemisferios));
	}, [seletec_hemisferios]);

	useEffect(() => {
		setExers(
			row.EXERCICIO_TIPO == TypesDivision.DEB && row.EXERCICIO_TITULO_PT !== 'Preensão Palmar' &&
					[
						{
							title: current_language?.hemisferio_direito,
							value: "D",
							grupo: row?.ID_AGRUPAMENTO,
							exercice_id: row?.ID_EXERCICIO,
							name: row?.EXERCICIO_TITULO_PT,
						},
						{
							title: current_language?.hemisferio_esquerdo,
							value: "E",
							grupo: row?.ID_AGRUPAMENTO,
							exercice_id: row?.ID_EXERCICIO,
							name: row?.EXERCICIO_TITULO_PT,
						},
						{
							title: current_language?.hemisferio_bilateral,
							value: "B",
							grupo: row?.ID_AGRUPAMENTO,
							exercice_id: row?.ID_EXERCICIO,
							name: row?.EXERCICIO_TITULO_PT,
						},
				  ]
				|| row.EXERCICIO_TIPO == TypesDivision.DEB && row.EXERCICIO_TITULO_PT === 'Preensão Palmar' &&
				[
					{
						title: current_language?.hemisferio_direito,
						value: "D",
						grupo: row?.ID_AGRUPAMENTO,
						exercice_id: row?.ID_EXERCICIO,
						name: row?.EXERCICIO_TITULO_PT,
					},
					{
						title: current_language?.hemisferio_esquerdo,
						value: "E",
						grupo: row?.ID_AGRUPAMENTO,
						exercice_id: row?.ID_EXERCICIO,
						name: row?.EXERCICIO_TITULO_PT,
					},
				]
				|| [
						{
							title: current_language?.sem_hemisferio,
							value: "S",
							grupo: row?.ID_AGRUPAMENTO,
							exercice_id: row?.ID_EXERCICIO,
							name: row?.EXERCICIO_TITULO_PT,
						},
				  ]
		);
	}, [current_language]);

	const editProtocolo = () => {
		const id_exer = is_open_protocolo?.status?.PROTOCOLO_EXERCICIO.find(
			(id: any) => id?.ID_EXERCICIO == row?.ID_EXERCICIO
		);

		if (
			id_exer !== undefined &&
			id_exer?.ID_EXERCICIO == row?.ID_EXERCICIO &&
			!arrayExercicios.includes(row?.ID_EXERCICIO)
		) {
			if (id_exer?.PROTOCOLO_EXERCICIO_TIPO?.includes("S")) {
				addTipo(exers.find((e: any) => e.value == "S"));
			} else {
				if (id_exer?.PROTOCOLO_EXERCICIO_TIPO?.includes("D")) {
					addTipo(exers.find((e: any) => e.value == "D"));
				}
				if (id_exer?.PROTOCOLO_EXERCICIO_TIPO?.includes("E")) {
					addTipo(exers.find((e: any) => e.value == "E"));
				}
				if (id_exer?.PROTOCOLO_EXERCICIO_TIPO?.includes("B")) {
					addTipo(exers.find((e: any) => e.value == "B"));
				}
			}
		}
	};
	useEffect(() => {
		if (is_open_protocolo.edit && exers.length != 0) {
			editProtocolo();
		}
	}, [is_open_protocolo?.status, exers]);

	return (
		<div className="mid">
			<div className="midSup">
			   <img src={ logoElastic}/>
				<p className="exercicio">
					{language == TypesLanguage.PT
						? row.EXERCICIO_TITULO_PT
						: language == TypesLanguage.EN
						? row.EXERCICIO_TITULO_EN
						: row.EXERCICIO_TITULO_ES}
				</p>
			</div>
			<div className="midInf">
				{exers.map((exer, index) => (
					<span
						key={index}
						onClick={() => {
							addTipo(exer);
						}}
						className={
							isPresentInListSelecteds(exer) ? "sagitalSelected" : "sagital"
						}
					>
						{exer.title}
					</span>
				))}
			</div>
		</div>
	);
};

export default RenderItemGroup;
