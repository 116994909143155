import styled from "styled-components";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	padding: 2rem;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 70vh;
	width: 50vw;
	@media only screen and (min-width: 1024px) {
		height: 60vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1280px) {
		height: 64vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 70vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 75vh;
		width: 48vw;
	}

	@media only screen and (min-width: 1920px) {
		height: 65vh;
		width: 42vw;
	}

	@media only screen and (min-width: 2342px) {
		height: 65vh;
		width: 45vw;
	}

`;

export const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	padding: 2rem;
	flex-direction: column;

	@media only screen and (min-width: 2342px) {
		height: 60vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1920px) {
		height:75vh;
		width: 38vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 75vh;
		width: 38vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 80vh;
		width: 42vw;
		padding: 5rem;
	}

	@media only screen and (min-width: 1024px) {
		height: 75vh;
		width: 48vw;
		padding: 0rem;
	}
`;

export const Close = styled.img`
	width: 1.738rem;
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;
	cursor: pointer;
`;

export const WarningIcon = styled.img`
	width: 72%;
	top: 1.5rem;
	margin-left: auto;
	margin-right: auto;
`;

export const Title = styled.h1`
	text-align: center;
	margin-bottom: 5rem;
	color: black;

	@media only screen and (min-width: 922px) {
		margin-bottom:2rem;
	}
	@media only screen and (min-width: 1015px) {
		font-size:30px;
		margin-top: 3rem;
		margin-bottom:0.5rem;
	}
	@media only screen and (min-width: 1200px) {
		margin-bottom: 1rem;
		margin-top: 3rem;
	}
	@media only screen and (min-width: 1350px) {
		margin-bottom: 2rem;
	}
	@media only screen and (min-width: 1440px) {
		margin-top: 8%;
	}
	@media only screen and (min-width: 2342px) {
		margin-top: 8%;
	}
`;

export const TextArea = styled.text`
	text-align: center;
	font-size: 25px;
	line-height: 2.5rem;
	color: black;

	@media only screen and (min-width: 1015px) {
		font-size: 15px;
		line-height: 1rem;
	}
	@media only screen and (min-width: 1024px) {
		font-size: 20px;
		line-height: 1.8rem;
	}
	@media only screen and (min-width: 1200px) {
		font-size: 22px;
		line-height: 1.5rem;
	}
	@media only screen and (min-width: 1350px) {
		font-size: 20px;
		line-height: 1.8rem;
	}
	@media only screen and (min-width: 1440px) {
		font-size: 25px;
		line-height: 2.2rem;
	}
	@media only screen and (min-width: 1680px) {
		font-size: 25px;
		margin-left: 32px;
		margin-right: 32px;
		margin-top: 20px;
		line-height: 1.8rem;
	}
	@media only screen and (min-width: 1920px) {
		padding-left: 32px;
		padding-right: 32px;
	}
	
	@media only screen and (min-width: 2342px) {
		font-size: 35px;
		line-height: 2.3rem;
	}
`;
export const Phrase = styled.p`
	color: #5C5B5B;
`;
