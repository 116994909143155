import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BtnInformacao from "../../../../../components/BtnInformacao";
import { TextsProps } from "../../../../../database/txt.interface";
import { ServicosProps } from "../../../../../interfaces/config.interface";
import { PacientesProps } from "../../../../../interfaces/pacientes.interface";
import { AppActions } from "../../../../../store/ducks/app";
import { PacAvalActions } from "../../../../../store/ducks/manage_pac_aval";
import { getPermission } from "../../../../../utils/funcs";
import {
	TypesBtnInfo,
	TypesMenuInfoProps,
	TypesPermission,
	TypesQuestionnaire,
} from "../../../../../utils/types";
import { Cards, Container, Historico, Title, List, ItemList } from "./styles";
import { AmplitudeLog } from "../../../../../utils/amplitude";

interface BtnQuestProps {
	Questionario: string;
}

const Questionarios: React.FC<BtnQuestProps> = ({ Questionario }) => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos,
	);

	const historicos_questionarios = useSelector(
		(state: any) => state.pac_aval.perfil_anamnese_array
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);


	function compareForExecutada(a: any, b: any) {
		return (
			new Date(b.QUESTIONARIO_RESPOSTA_CRIADO).getTime() -
			new Date(a.QUESTIONARIO_RESPOSTA_CRIADO).getTime()
		);
	}

	const [TipoQuest, setTipo] = useState("");
	const permissionAssessmentPhysicalByPatientMostVisited: boolean = useSelector(
		(state: any) => state.configs.verification_by_patient_most_visited,
	)

	const continuarQuestionario = (questionario: string) => {
		if (
			questionario === TypesQuestionnaire.ANAMNESE 
			||
			questionario === TypesQuestionnaire.ANAMNESE_FISICA
		) {
			
			let final_filtered_anamneses: any = [
				...historicos_questionarios.historico_anamnese_tipo,
				...historicos_questionarios.historico_anamnese_avaliacao,
			];

			const filtered: any = final_filtered_anamneses.sort(compareForExecutada);

			const first_anamneses: any = final_filtered_anamneses[0];

			const filtered_anamneses = filtered.filter(
				(element: any) => element?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "S"
			);

			if (first_anamneses?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "N") {
				final_filtered_anamneses = [first_anamneses, ...filtered_anamneses];
			} else {
				final_filtered_anamneses = filtered_anamneses;
			}

			const anamnese_tipo = historicos_questionarios?.historico_anamnese_tipo


			const anamnese_tipo_avaliacao = historicos_questionarios?.historico_anamnese_avaliacao
			
			if(questionario === TypesQuestionnaire.ANAMNESE){
				if (
					!!anamnese_tipo &&
					anamnese_tipo[0]?.QUESTIONARIO_RESPOSTA_CONCLUIDO === "N"
				) {
					
					dispatch(AppActions.set_modal_continue_or_to_generate_new_anamnese(true, TypesQuestionnaire.ANAMNESE ))
					
				}else{
					dispatch(
						PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE)
					);
				}
			}
			
			if(	questionario === TypesQuestionnaire.ANAMNESE_FISICA){
				const permissionAssessmentPhysical: any = getPermission(
					config_servicos,
					TypesPermission.SERVICO_ANAMNESE_FISICA
				)
				if(permissionAssessmentPhysical){
					if(
						!!anamnese_tipo_avaliacao 
						&& 
						anamnese_tipo_avaliacao[0]?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "N"
					){
						
						dispatch(AppActions.set_modal_continue_or_to_generate_new_anamnese(true,TypesQuestionnaire.ANAMNESE_FISICA ))
					}else(
						dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_FISICA))
					)
					
				}else{
					dispatch(AppActions.set_excluir_anamnese(false));
					dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
				}
			}
		} else if (questionario === TypesQuestionnaire.SF_36) {
			dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.SF_36));
			dispatch(
				PacAvalActions.set_usu_continuar_questionario(paciente.ID_USUARIO)
			);
		}
	};

	const first_sf_36: any = !!historicos_questionarios?.historico_sf_36 && historicos_questionarios?.historico_sf_36[0];

	const filtered_sf_360 = historicos_questionarios.historico_sf_36.filter(
		(element: any) => element?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "S"
	);

	let final_filtered_sf_360: any[] = [];
	if(!!first_sf_36 && first_sf_36?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "N") {
		final_filtered_sf_360 = [first_sf_36, ...filtered_sf_360];
	} else {
		final_filtered_sf_360 = filtered_sf_360;
	}


	const interceptSF36 = ()=> {
		const isContinue = final_filtered_sf_360[0]?.QUESTIONARIO_RESPOSTA_CONCLUIDO == 'N';
		if(isContinue){
			dispatch(AppActions.set_modal_continuar_or_gerar_new_sf_36(true))
		}else{
			dispatch(AppActions.set_modal_compartilhamento(true, 10));
			dispatch(AppActions.set_continue_compartilhamento("ShereAnamnese"));
			dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.SF_36));
			dispatch(AppActions.set_modal_continuar_or_gerar_new_sf_36(false));
		}
	};

	useEffect(() => {
		if(historicos_questionarios?.historico_anamnese_avaliacao.length > 0)
			dispatch(AppActions.set_modal_warning_anamnese(true));
	}, [historicos_questionarios]);

	function cards() {
		if (Questionario === TypesMenuInfoProps.BTN_ANAMNESE) {
			return (
				<Cards>
					<Title>{current_language?.anamnese}</Title>
					<div className="cardsList">
						<BtnInformacao
							Icon={TypesBtnInfo.BTN_NEW_ANAMNESE}
							Texto={current_language?.new_anamnese}
							funcao={() => {
								continuarQuestionario(TypesQuestionnaire.ANAMNESE)
								AmplitudeLog('gerar_anamnese')
							}}
						/>
						<BtnInformacao
							Icon={TypesBtnInfo.BTN_AVALIACAO_FÍSICA}
							Texto={current_language?.realisar_avaliacao_fisica}
							funcao={() =>{
								continuarQuestionario(TypesQuestionnaire.ANAMNESE_FISICA)
								AmplitudeLog('gerar_anamnese_fisica')
							}}
						/>
					</div>
				</Cards>
			);
		}
		if (Questionario === TypesMenuInfoProps.BTN_SF) {
			return (
				<Cards>
					<Title>{current_language?.quiz_sf_36}</Title>
					<div className="cardsList">
						<BtnInformacao
							Icon={TypesBtnInfo.BTN_NEW_SF}
							Texto={"Gerar/Continuar questionário"}
							funcao={() =>  interceptSF36()}
						/>
					</div>
				</Cards>
			);
		}
	}

	function historic_list() {
		if (Questionario === TypesMenuInfoProps.BTN_ANAMNESE) {
			let final_filtered_anamneses: any = [
				...historicos_questionarios.historico_anamnese_tipo,
				...historicos_questionarios.historico_anamnese_avaliacao,
			];

			const filtered: any = final_filtered_anamneses.sort(compareForExecutada);

			const first_anamneses: any = final_filtered_anamneses[0];

			const filtered_anamneses = filtered.filter(
				(element: any) => element?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "S"
			);

			if (first_anamneses?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "N") {
				final_filtered_anamneses = [first_anamneses, ...filtered_anamneses];
			} else {
				final_filtered_anamneses = filtered_anamneses;
			}

			return (
				<Historico>
					<Title>{current_language?.historico_anamnese}</Title>
					<List>
						{final_filtered_anamneses.length > 0 ? (
							final_filtered_anamneses.map((e: any, i: number) => {
								const data = new Date(e.QUESTIONARIO_RESPOSTA_ATUALIZADO);
								const dataFormatada =
									(data.getDate() < 10
										? `0${data.getDate()}`
										: data.getDate()) +
									"/" +
									(data.getMonth() + 1 < 10
										? `0${data.getMonth() + 1}`
										: data.getMonth()) +
									"/" +
									data.getFullYear();
								return (
									<ItemList key={i}>
										{e?.QUESTIONARIO_RESPOSTA_TITULO}
										{" - "}
										{e?.QUESTIONARIO_RESPOSTA_CONCLUIDO === "N"
											? current_language?.em_andamento
											: `${current_language?.realizado_em} ${dataFormatada}`}
									</ItemList>
								);
							})
						) : (
							<ItemList>{current_language?.sem_historico_anamnese}</ItemList>
						)}
					</List>
				</Historico>
			);
		}
		if (Questionario === TypesMenuInfoProps.BTN_SF) {
			return (
				<Historico>
					<Title>{current_language?.historico_quiz_sf_36}</Title>
					<List>
						{final_filtered_sf_360.length > 0 ? (
							final_filtered_sf_360.map((e: any, i: number) => {
								const data = new Date(e?.QUESTIONARIO_RESPOSTA_ATUALIZADO);
								const dataFormatada =
									(data.getDate() < 10
										? `0${data.getDate()}`
										: data.getDate()) +
									"/" +
									(data.getMonth() + 1 < 10
										? `0${data.getMonth() + 1}`
										: data.getMonth()) +
									"/" +
									data.getFullYear();
								return (
									<ItemList key={i}>
										{current_language?.quiz_sf_36}
										{" - "}
										{e?.QUESTIONARIO_RESPOSTA_CONCLUIDO === "N"
											? current_language?.em_andamento
											: `${current_language?.realizado_em} ${dataFormatada}`}
									</ItemList>
								);
							})
						) : (
							<ItemList>{current_language?.sem_historico_anamnese}</ItemList>
						)}
					</List>
				</Historico>
			);
		}
	}

	return (
		<Container>
			{cards()}
			{historic_list()}
		</Container>
	);
};

export default memo(Questionarios);

